.global {
    @at-root #{&}-helper {
        @at-root #{&}--relative {
            position: relative;
        }
        @at-root #{&}--clear {
            clear: both;
        }
        @at-root #{&}--uppercase {
            text-transform: uppercase;
        }
        @at-root #{&}--nomarginTop {
            margin-top: 0;
        }
        @at-root #{&}--nomarginBottom {
            margin-bottom: 0;
        }
        @at-root #{&}--verticalAlign {
            vertical-align: middle!important;
        }
        @at-root #{&}--textRight {
            text-align: right !important;
        }
        @at-root #{&}--textCenter {
            text-align: center !important;
        }
        @at-root #{&}--autoMargin {
            margin: auto;
        }
        @at-root #{&}--align-bottom {
            vertical-align: bottom;
        }
        @at-root #{&}--no-list {
            list-style: none;
        }
        @at-root #{&}--inline-block {
            display: inline-block!important;
        }
        @at-root #{&}--hide-overflow {
            overflow: hidden;
        }
        @at-root #{&}--visible-overflow {
            overflow: visible;
        }
    }
    @at-root #{&}-backdrop {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
        z-index: $zindexModalBackdrop;
        @media (max-width: 767px) {
            background: rgba(0, 0, 0, 0.01);
        }
    }
}
