$base: '.carpeople-custom-fullwidth-area';
#{$base} {
	background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    >.e-content {
        background: transparent;

        .btn-white {
            background: $white;
            border-bottom-color: $white;

            &:hover, &:focus, &:active {
                background: $brandColor;
                border-bottom-color: darken($brandColor, 5%);
                color: $white;
            }
        }
    }

    &.text-shadow {
        h1,h2,h3,h4,h5,h6, p, p.lead, quote, small {
            //text-shadow: 2px 2px 0px rgba(0,0,0, 0.8);
            color: $brandColor !important;
        }
    }

    &.dark {
        h1,
        .h1,
        h2,
        .h2,
        h3,
        .h3,
        h4,
        .h4,
        h5,
        .h5,
        h6,
        .h6 {
            color: $brandColorSecondary;
        }

        p,
        p.lead,
        a:not(.btn),
        small {
            color: $white;
        }
    }

    &.light {
        ul.checklist {
            li:before {
                color: $brandColor;
            }
        }
    }

    &.white {
        background-color: $white;
    }

    &.m-t-1 {
		padding-top: 1.5em;
    }

    &.m-t-2 {
		padding-top: 3em;
    }

    &.m-t-3 {
		padding-top: 4.5em;
    }

    &.m-b-1 {
		padding-bottom: 1.5em;
    }

    &.m-b-2 {
		padding-bottom: 3em;
    }

    &.m-b-3 {
		padding-bottom: 4.5em;
    }
}


// FRONTPAGE MAP SEARCH


$baseMaps: '.carpeople-frontpage-map-search';

#{$baseMaps} {
    background: $grayLighter;

    .carpeople-dealer-search {
        border: none;
        padding: 1.5em 0;

        button {
            top: 1.6em;
            right: 0;
            min-height: 3.7em;
						background: $brandColorSecondary;
						
						&:hover {
							background: $button-hoover;
						}
        }
    }
}


// FRONTPAGE FOOTER

.carpeople-frontpage-footer {
    .e-footer {
        margin-top: 0;
    }
}


// FLEXBOX WRAPPER

.flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 4em;
    margin-bottom: 1em;
    margin-left: -1.5em;
    margin-right: -1.5em;
    @media (max-width: 768px) {
        display: block;
        margin-left: 0;
        margin-right: 0;
        margin-top: 1em;
    }




    .flex-item {
        width: 25%;

        border-right: 3px solid $brandColorSecondary;
        padding: 0 1.5em 0 1.5em;
        flex: 1 1 auto;
        text-align: center;
        @include box-sizing(border-box);
        @media (max-width: 768px) {
            width: 100%;
            border-right: none;
            padding: 0;
        }



        &:last-of-type{
            border-right-width: 0;
        }


    }

}

#{$base}.dark {
    .flex-container .flex-item {
        h3 {
            color: $white;
            margin-bottom: .75em;
            margin-top: .25em;
        }

        p {
            font-size: 0.846em;
            line-height: 1.4;
            margin-bottom: -2em;
            @media (max-width: 768px) {
                margin-bottom: 1em;
            }
        }

        img {
            max-width: 60%;
            margin-top: -2em;
            margin-bottom: 1em;
            @media (max-width: 768px) {
                margin-top: 1em;
                max-width: 40%;
            }
        }

        .btn-white {
            &:hover, &:focus, &:active {
                background: $brandColorSecondary;
                border-bottom-color: darken($brandColorSecondary, 5%);
                color: $brandColor;
            }
        }
    }
}


// custom 1st web styles

.cp-col-6  {
	h3 {
		font-size: 1.7em;
		text-align: center;

		@media screen and (min-width: 769px) {
			font-size: 2em;
		}
	}
	.e-img {
		text-align: center;
	}

	ul {
		font-family: $masterSecondaryFontFamily;
		text-align: center;
		list-style-type: none;
		li:before {
			content: '';
		}
	}
}



// custom 1st web styles

.cp-col-6  {

	h3 {
		font-size: 1.7em;
		text-align: center;

		@media screen and (min-width: 769px) {
			font-size: 2em;
		}
	}

	.e-img {
		text-align: center;
	}

	ul {
		font-family: $masterSecondaryFontFamily;
		text-align: center;
		list-style-type: none;

		li:before {
			content: '';
		}
	}
}

.btn.btn-primary.btn-cp {
	padding: 1.5em;
	width: 100%;
	border-radius: 0;
	background-color: $brandColorSecondary;
	color: $new-text-color;

	&:hover {
		background-color: $button-hoover;
	}
}