/* INCLUDES */

// MIXINS
@import "mixins/mixins";
// ROOT
@import "root";
// TYPE
@import "type/type";
// GLOBAL
@import "global/global";
// UTILITIES
@import "utilities/utilities";
// MAPS
@import "maps/maps";
//CHECKOUT
@import "ecom-checkout/webshop-checkout";
// FORMS
@import "forms/forms";
// HEADER
@import "header/header";
// FOOTER
@import "footer/footer";
// BUTTONS
@import "buttons/buttons";
// WEBSHOP
@import "webshop/webshop";
// SERVICE
@import "service/service";
// MODALS
@import "modals/modals";
// CUSTOMER CENTER / WORKSHOP ADMINISTRATION
@import "customer-center/customer-center";
// FRONTPAGE SPECIFIC
@import "frontpage-specific/frontpage-specific";
// ADVANCED SEARCH
@import "search/search-function";
// STANDARD COLORSCHEME
@import "colorscheme/colorscheme";
// CARDS
@import "cards/cards";
// USER MANAGEMENT
@import "usermanagement/usermanagement";
// VENDOR
@import "vendor/vendor";


.e-map-list {
    .e-map-list-item {
        h3 {
            padding-right: 50px;
        }
    }
}

@media (max-width: 767px) {
    .master-button--campaign, .master-button--large, .master-button--mega {
        float: left;
    }
}
.master-service-bookservice-section.section--large {
    margin-top: 20px;
}


.the-owls-are-not-what-they-seem{
    position: absolute;
    transition: all .25s ease;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    width: 100px;
    height: 40px;
    left: 50%;
    line-height: 37px;
    top: 100%;
    background: $brandColor;
    color: #fff;
    border: 1px solid #fff;
    transform: translate(-50%,0);
}

#UserManagement_Item_BookingInterval {
	width: 140px;
  height: 38px;
  border-radius: 0;
  border: 2px solid #eee;
}
