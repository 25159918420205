$eHeaderHeight: $baseLineHeight * 2.8;
.e-header {
    position: relative;
    padding-top: $baseLineHeight / 3;
    padding-bottom: 0;
    background-color: $brandColor;
    @media (max-width: 979px) {
        >.container>.row-fluid>.span4 {
            width: 50%;
            margin: 0;
            float: left;
        }
    }
    @media (max-width: 767px) {
        padding: 0;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: $zindexFixedNavbar;
    }
}

.master-header-workshop-local-logo {
    position: absolute;
    padding: 5px 10px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    width: 260px;
    max-height: 55px;
    background: transparent;
    //border-bottom: 0.3em solid darken($gray, 8%);
    @include border-radius(0 0 5px 5px);

    img {
        display: table;
        margin: 0 auto;
        max-height: 60px;
    }
}

.e-header .e-logo.local-text-logo{
    color:$white;
    font-size: 1.3em;
    text-decoration: none;
    text-shadow: rgba(0,0,0, .5) 1px 1px 1px;
    font-family: Montserrat, "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: bold;
}

.master-header-workshop-local-logo--tabletview {
    position: absolute;
    top: 0;
    left: 10px;
    padding: 10px 0;
    height: 51px;
    @include box-sizing(border-box);

    img {
        max-height: 31px;
    }
}

// MOBILE
@media (max-width: 767px) {
    .e-header {
        .span10 {
            margin: 0;
            padding: 0;
        }
    }
}

.e-header .master-fullwidth-content {
    margin-bottom: 0.75em;
    padding: 0 1.1em 0 1.5em;
    @media (min-width: 768px) and (max-width: 979px) {
        padding: 0 20px;
    }
    @media (max-width: 767px) {
        padding: 0;
        margin-bottom: 0;
    }
    @include box-sizing(border-box);
}

.e-header .container {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    @media (max-width: 767px) {
        padding: 0;
    }
    box-sizing: border-box;
}

.e-header .navbar .container {
    padding: 0;
}

.e-header .navbar .nav {
    @include transition(all 0.25s);
    margin-top: 0.75em;
    margin-right: 1em;
}

.e-header .navbar .nav > li {
    > a {
        padding-left: $gridGutterWidth * 0.60;
        padding-right: $gridGutterWidth * 0.60;
        font-weight: $eFontWeightBold;
    }
}

.e-header .navbar .nav>li:last-child>a {
    padding-right: 0;
}

.e-header {
    .e-logo {
        @include transition(all 0.50s);
        position: absolute;
        top: 0.25em;
        left: 0px;
        height: 100px;
        margin: 0;
        width: 100%;
        height: $eHeaderHeight;
        line-height: $eHeaderHeight;
        display: block;
        img {
            @include border-radius(0);
        }
    }
}

.e-header.affix .e-logo {
    @include transition(all, 0.50s);
    top: -0.3em;
    width: 100%;
    @media (min-width: 1320px) {
        width: 90%;
    }
    @media (min-width: 1640px) {
        max-width: 80%;
    }
}
