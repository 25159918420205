$base: '.module-licenseplate-info';
#{$base} {

    position: relative;
    background: $success;
    padding: 1.25em 1.25em 1.25em 6em;
    color: $white;

    &:before {
        position: absolute;
        content: '';
        background: url('/files/Templates/Designs/ftz-master/assets/img/svg-graphics/car-simple-icon-white.svg') top center no-repeat;
        left: 0;
        width: 6em;
        height: 2em;
        top: 1.6em;
    }

    small {
    color: $white;
    }

    #{$base}-item {
        float: left;
        margin-right: 0.25em;
        text-transform: capitalize;
    }

    #{$base}-model {
        font-weight: $eFontWeightBold;
        font-size: 1.25em;
    }

}
