.e-ecom-grid ul li > div:first-child > a > img,
.e-ecom-grid ul li > div:first-child > a > svg {
    position: absolute;
    display: inline-block;
    right: 0;
    left: 0;
    max-height: 100%;
    max-width: 100%;
    margin: 0 auto;
}

.e-ecom-grid ul li > div:first-child > a > svg {
    max-height: 80%;
}


