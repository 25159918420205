//
// Scaffolding
// --------------------------------------------------


// Body reset
// -------------------------

body {
  margin: 0;
  font-family: $baseFontFamily;
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  color: $textColor;
  background-color: $bodyBackground;
}


// Links
// -------------------------

a {
  color: $linkColor;
  text-decoration: none;
}
a:hover,
a:focus {
  color: $linkColorHover;
  text-decoration: underline;
}


// Images
// -------------------------

// Rounded corners
.img-rounded {
  @include border-radius(6px);
}

// Add polaroid-esque trim
.img-polaroid {
  padding: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0,0,0,.2);
  @include box-shadow(0 1px 3px rgba(0,0,0,.1));
}

// Perfect circle
.img-circle {
  @include border-radius(500px); // crank the border-radius so it works with most reasonably sized images
}
