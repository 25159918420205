$base: ".master-modal-user-login";
#{$base} {
    background: $brandColorSecondary;
    @include border-radius(0);
    border: none;
    box-shadow: none;
    @media (max-width: 767px) {
        top: 0;
        left: 0;
        right: 0;
    }
    @media (min-width: 980px) {
        .modal-body {
            max-height: 1000px !important;
        }
    }
    #{$base}-utilities-wrapper {
        margin-top: 0 !important;
        padding-top: 0;
    }
    #{$base}-logo {
        width: 60%;
        display: table;
        margin: 0 auto;
    }
    .popup-close {
        position: absolute;
        top: 10px;
        right: 10px;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        opacity: 1;
        color: $new-text-color;
        font-size: 2em;
        text-shadow: none;
        z-index: 2
    }
    label {
        margin-left: 0;
    }
    .controls >.radio:first-child,
    .controls >.checkbox:first-child {
        padding-top: 0;
    }
    .form-vertical > fieldset,
    .form-horizontal > fieldset {
        padding-right: 0;
    }
    input[type="text"],
    input[type="password"] {
        box-sizing: border-box;
        border: none;
        box-shadow: none;
        @include border-radius(0);
        padding: 0 10px;
        outline: 2px solid transparent;
        @include transition(all 0.25s);
        outline-offset: -4px;
        font-size: 1.25em;
        min-height: 3.5em;
        &:focus,
        &:active {
            @include transition(all 0.25s);
            outline: 2px solid $success;
            box-shadow: none;
            outline-offset: 0;
        }
    }
    .master-button--large {
        font-weight: $eFontWeightBold;
        font-size: 2em;
        color: $btn-cancel-color;
        background: $btn-cancel-bg-color;
				border-radius: 0;

				
				&:hover, &:active, &:focus {
					background: $btn-cancel-bg-color;
					color: $btn-cancel-color !important;
				}
    }
    .alert.alert-error {
        p {
            margin: 1em;
            padding-bottom: 0;
        }
    }
}
