$base: ".master-local-frontpage-description";
#{$base} {
    @include box-sizing(border-box);
    background: $success;
    padding: 3em 0;
    text-align: center;
    font-weight: $eFontWeightLight;
    @media (max-width: 767px) {
        padding: 2em 2em 1em 2em;
    }
    h1 {
        font-size: 2.5em;
        text-shadow: 1px 1px 1px darken($success, 20%);
        @media (max-width: 767px) {
            font-size: 1.75em;
        }
        margin-top: 0;
        margin-bottom: 1em;
    }
    p {
        font-size: $baseFontSize + 2;
        margin-bottom: 1em;
    }
    p,
    h1,
    {
        color: $white;
    }
}
