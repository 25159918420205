.master-form-validation-error {
    position: relative;
    background: $error;
    color: $white;
    padding: 1em 1em 1em 4em;
    margin-bottom: 25px;
    @include border-radius(3px);
    &:before {
        position: absolute;
        top: 0.5em;
        left: 0.5em;
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 30px;
        @extend .material-icons;
        content: "error_outline";
    }
    h5 {
        color: $white;
    }
    ul {
        padding: 0;
        margin: 0 0 0 1em;
        font-size: $baseFontSize - 3;
    }
}

input[type="text"].disabled, input[type="text"].disabled-input {
    background-color: #eee;
    cursor: not-allowed!important;
    pointer-events: none;
}

input[type="text"],
input[type="number"],
textarea {
    &.error {
        border-color: $error!important;
    }
}
