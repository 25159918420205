$base: ".master-account-edit";
#{$base} {
    .control-group label {
        padding-top: 10px;
        text-align: left;
        font-weight: $eFontWeightBold;
        @media (max-width: 979px) {
            display: block;
            width: 100%;
            font-size: 0.9em;
        }
    }
    input[type="text"],
    input[type="password"] {
        @include box-sizing(border-box);
        @include border-radius(0);
        @include box-shadow(none);
        padding: 0 15px;
        border: 3px solid $grayLighter;
        outline: 3px solid transparent;
        @include transition(all 0.25s);
        outline-offset: -6px;
        min-height: 2.75em;
        &:active,
        &:focus {
            outline: 3px solid $success;
            border: 3px solid transparent;
            outline-offset: -3px;
            @include box-shadow(none);
        }
        &.input-xlarge {
            width: 100%;
        }
        @media (max-width: 767px) {
            &.input-small {
                width: 30%;
            }
            &.input-city {
                width: 68%;
                float: right;
            }
        }
    }
    .control-group.error {
        input {
            &:active,
            &:focus {
                outline: 3px solid $error;
                @include box-shadow(none);
            }
        }
    }
    .controls p {
        min-height: 2.75em;
        line-height: 2.75em;
        margin-bottom: 0;
    }
    input[type="checkbox"] {
        margin-top: 0;
    }
    @media (max-width: 979px) {
        .form-actions {
            padding: 0;
        }
    }
    .btn-large {
        background: $success;
        color: $white;
        border-bottom: 0.25em solid darken($success, 5%);
        &:hover,
        &:focus,
        &:active {
            background: darken($success, 5%);
            border-bottom: 0.25em solid darken($success, 10%);
        }
    }
    .btn.cancel {
        background: $grayLighter;
        color: $textColorMuted;
        border-bottom: 0.25em solid darken($grayLighter, 5%);
        &:hover,
        &:focus,
        &:active {
            background: darken($grayLighter, 5%);
            border-bottom: 0.25em solid darken($grayLighter, 10%);
        }
    }
    .master-account-edit-newsletter-signup{
        margin-top: 2em;
    }
    .master-account-edit-workshop-lookup {
        fieldset.module-workshop-lookup {
            &.module-workshop-lookup--selected:before {
                margin: 9px 10px;
            }
            .module-workshop-lookup-button {
                font-size: .8em;
                right: 1.7em;
                top: 1.9em;
                padding: 0 .5em;
                @media (max-width: 979px) and (min-width: 768px) {
                    font-size: .7em;
                    padding: .3em;
                }
                @media (max-width: 767px) {
                    left: 0;
                    right: 0;
                    padding: 0;
                    margin-bottom: 1em;
                }
            }
        }
    }
}

.master-account-edit-licenseplate-lookup-container {
    background: $grayLighter;
    border: 2px solid $baseBorderColor;
    padding: 1em 1em .5em 1em;
    margin-bottom: 0.5em;
    .module-licenseplate-lookup {
        display: table;
        margin: 0 auto;
    }
}
