//cart in header
.e-cart-header {
    font-size: $baseFontSize - 1;
    padding-top: $baseLineHeight / 4;
    padding-bottom: $baseLineHeight / 4;
    padding-right: $gridGutterWidth / 2;
    padding-left: $gridGutterWidth / 2;
    white-space: nowrap;
    border: 1px solid $baseBorderColor;
    @include border-radius($boxBorderRadius);
}

.e-cart-header p {
    margin-bottom: 0;
}

.e-cart-header p:first-child {
    padding-top: $baseFontSize * 0.154;
}

.e-cart-header p + p {
    margin-top: $baseLineHeight / 4;
}

.e-cart-header p:first-child a,
.e-cart-header p:first-child a:hover {
    color: inherit;
    text-decoration: none;
}

.e-cart-header p [class*="icon-"] {
    //color:$linkColor;
    width: 1em;
    margin-right: 0;
    margin-top: -$baseFontSize * 0.154 * 2;
    line-height: $baseLineHeight * 0.846 * 2;
    font-size: ceil($baseFontSize * 2.5);
}

.e-cart-header p strong,
.e-cart-header p small {
    display: block;
    margin-left: $baseFontSize * 3;
    line-height: $baseLineHeight * 0.846 * 0.846;
}

.e-cart-header p a.btn {
    font-size: ceil($baseFontSize * 0.846);
    padding: 0.125em 1em;
}

.e-cart-header p a.btn + a.btn {
    margin-left: $gridGutterWidth / 4;
}

@media (max-width: 767px) {
    .e-cart-header {
        padding-top: $baseLineHeight / 6;
        padding-bottom: $baseLineHeight / 6;
    }
    .e-cart-header p + p {
        margin-top: 0;
    }
    .e-cart-header p strong {
        margin-left: 0;
    }
    .e-cart-header p + p a {
        float: left;
        font-size: 0.846em;
        padding: 0.125em 0.5em 0.125em 0;
    }
}

@media (max-width: 480px) {
    .e-cart-header {
        white-space: normal;
        border-right: 0;
        padding-right: $fluidGridGutterWidth;
        padding-left: $fluidGridGutterWidth * 2;
        @include border-radius($boxBorderRadius 0 0 $boxBorderRadius);
    }
    .e-cart-header p i[class*="icon-"],
    .e-cart-header p i[class*="icon-"]:before {
        display: none;
    }
}

@media (max-width: 320px) {
    .e-cart-header p + p a {
        line-height: 1;
        padding-top: 0;
    }
}
