$base: ".master-webshop-category-overview";

#{$base} {
    @media (min-width: 768px) {
        background: lighten($grayLighter, 3%);
    }
    ul {
        margin: 0;
        padding: 3em 0 2.5em 0;
        list-style-type: none;
        // MOBILE
        @media (max-width: 767px) {
            padding: 1em 0;
        }
        li#{$base}-listelement--small,
        li#{$base}-listelement--large {
            position: relative;
            float: left;
            @include transition(all, 1s);
            min-height: 6em;
            @include border-radius(5px);

            @media(min-width: 768px) and (max-width: 979px) {
                margin-bottom: 10px;
            }

            &:hover,
            &:focus,
            &:active {
                @include transition(all, 0.15s);
                background: $success;
            }
        }
        li#{$base}-listelement--small {
            width: 33%;
            @media (min-width: 980px) {
                &:nth-child(3n+1) {
                    margin: 0 0.5%;
                }
            }
            // MOBILE
            @media (max-width: 767px) {
                width: 100%;
            }
            // TABLET
            @media (min-width: 768px) and (max-width: 979px) {
                width: 49%;
                &:nth-child(odd) {
                    margin-right: 2%;
                }
            }
        }
        li#{$base}-listelement--large {
            width: 49%;
            // MOBILE
            @media (max-width: 767px) {
                width: 100%;
            }
            @media (min-width: 768px) {
                margin-bottom: 2em;
                &:nth-child(odd) {
                    margin-right: 2%;
                }
            }
        }
    }
    a#{$base}-listelement--link {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @include box-sizing(border-box);
        padding: 0.75em;
        @include transition(all, 1s);
        > img,
        svg {
            position: absolute;
            top: 0.75em;
            left: 0.75em;
            width: 50px;
            height: 50px;
        }
        > div {
            float: left;
            margin-left: 4em;
            h4 {
                margin-top: 0;
                margin-bottom: 0.15em;
                letter-spacing: 0.025em;
            }
            p {
                color: $grayLight;
                font-size: $baseFontSize - 5;
                line-height: $baseLineHeight - 8;
            }
            p,
            h4 {
                @include transition(all, 1s);
            }
        }
        &:hover,
        &:focus {
            text-decoration: none;
            p,
            h4 {
                @include transition(all, 0.15s);
                color: $brandColor;
            }
        }
    }
    li#{$base}-listelement--large {
        a#{$base}-listelement--link {
            h4 {
                font-size: $baseFontSize + 7;
                margin-bottom: 0.3em;
                @media (max-width: 979px) {
                    font-size: $baseFontSize + 3;
                }
            }
            p {
                font-size: $baseFontSize - 3;
                @media (max-width: 979px) {
                    font-size: $baseFontSize - 4;
                }
            }
        }
    }
    // CUSTOM CLASSES
    li#{$base}-listelement--large,
    li#{$base}-listelement--small {

        &.category-overview-offers {
            background: $warning;
            a#{$base}-listelement--link {
                h4 {
                    color: $warningComplimentary;
                }
                p {
                    color: $grayDark;
                }
                &:hover {
                    h4,
                    p {
                        color: $brandColor;
                    }
                }
            }
        }

        &.category-overview-campaign {
            background: $brandColor;
            a#{$base}-listelement--link {
                .svgColorPrimary,
                .svgColorSecondary {
                    fill: $white;
                }
                h4 {
                    color: $brandColorSecondary;
                }
                p {
                    color: $white;
                }
                &:hover {
                    h4,
                    p {
                        color: $brandColor;
                    }
                }
            }
        }

    }
}
