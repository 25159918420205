.carpeople-newsletter-subscribe-input {
    width: 100%;
    max-width: 605px;
    @media(max-width: 979px) {
        width: 95%;
    }
}

//TODO show standard select on devices
select.custom-select {
    visibility: hidden;
    border: 0;
    margin-bottom: 15px;
}

small.errorLabel {
    display: none;
    margin: 8px 0 15px 0;
    font-size: 14px;
    color: $white;
    padding: 1em;
    @include border-radius(3px);
    background: $error;
    text-align: center;
}

small.error {
    //display: block!important;
    margin: 8px 0 15px 0;
    font-size: 14px;
    color: $white;
    padding: 1em;
    @include border-radius(3px);
    background: $error;
    text-align: center;
}

small {
    &.control-group.error .control-label,
    &.control-group.error .help-block,
    &.control-group.error .help-inline {
        color: $white;
    }
}

label.error small,
.help-block small,
small.error {
    color: $white !important;
    text-align: center;
}

.input-prepend.master-workshop-administration-prepend {
    .add-on {
        line-height: 3em;
        text-shadow: none;
        background-color: transparent;
        border: 0;
        height: auto;
        font-size: 12px;
    }
}
