.btn.btn-primary,
.e-bottom .btn.btn-primary {

    background: $brandColor;
    color: $white;
    &:hover {
        background: $success;
        color: $white;
    }
}
