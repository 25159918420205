.e-gallery-fullscreen {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    overflow: hidden;
    background: $bodyBackground;
}
.e-gallery-fullscreen-slides {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: 50% 50%;
    > div {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        background-size: cover;
        background-position: 50% 50%;
        top: -100%;
        opacity: 0;
        &.active {
            top: 0;
            opacity: 1;
            z-index: 1;
            transition-timing-function: ease-in-out;
            -webkit-transform: translate3d(0, 0, 0);
            -webkit-backface-visibility: hidden;
            -webkit-perspective: 1000;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000;
            &.transition-slide {
                transition-duration: 0.25s;
                transition-property: top;
            }
            &.transition-fade {
                transition-duration: 0.5s;
                transition-property: opacity;
            }
        }
    }
}
.e-gallery-fullscreen-controls {
    position: absolute;
    bottom: 0;
    height: 100%;
    left: 50%;
    > div {
        position: absolute;
        overflow: hidden;
        z-index: 2;
        left: 0;
        bottom: 0;
        width: 25%;
        background-color: whitesmoke;
        background-color: rgba(whitesmoke, 0.85);
        min-height: 7.5em;
        max-height: 7.5em;
        padding: 1.4285em;
        box-sizing: border-box;
        transition: all 0.25s ease-in-out 0s;
        -webkit-transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        -webkit-perspective: 1000;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000;
        @media (max-width: 767px) {
            font-size: 0.846em;
            line-height: 1.4;
            left: 0 !important;
            width: 100% !important;
            display: none;
            min-height: 0;
        }
        &.inactive {
            min-height: 0;
            max-height: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
        &.active {
            max-height: 99%;
            transition: max-height 0.5s ease-in-out 0.25s;
            @media (max-width: 767px) {
                display: block;
            }
        }
        &:only-child {
            bottom: 10%;
            width: 40%;
        }
    }
}
