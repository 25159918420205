.e-productlist-filters {
    line-height: $baseLineHeight;
    @media (max-width: 767px) {
        display: none;
    }
    &.e-modal-fixed {
        display: block;
    }
}
.e-productlist-filters form {
    margin: $baseLineHeight 0;
}
.e-productlist-filters .divider {
    margin-left: -$gridGutterWidth / 1.5;
    margin-right: -$gridGutterWidth / 1.5;
}
.e-productlist-filters label {
    font-size: $baseFontSize * 0.846;
    line-height: 1.2;
    margin-bottom: 0;
    input[type="radio"], input[type="checkbox"] {
        margin-top: 0.125em;
    }
}
.e-productlist-filters label.disabled {
    text-decoration: line-through;
    color: lighten($textColorMuted, 20%);
    &:hover {
        cursor: default;
    }
}
