select.custom-select {
    visibility: hidden;
    border: 0;
}

ul.custom-selector {
    position: absolute;
    height: auto;
    width: 80%;
    background-color: $white;
    border: 1px solid $baseBorderColor;
    margin: 0;
    outline: 2px solid transparent;
    padding: floor($baseLineHeight / 6) 0 0 0;
    color: $eInputTextColor;
    padding: 0;
    list-style: none;
    @include box-sizing(border-box);
    max-height: 420px;
    overflow-x: auto;

    @include transition(all 0.25s);
    outline-offset: -4px;
    @media (max-width: 767px) {
        width: 92%;
    }
    &:hover,
    &.open {
        outline: 1px solid $success;
        box-shadow: none;
        outline-offset: 0px;
        border: 1px solid $success;
    }


    &.open {
        @include box-shadow(0 1px 2px rgba($black, .3));
        z-index: 99;
    }
    li {
        padding: 8px 10px;
        margin: 0;
        z-index: 2;
        @include box-sizing(border-box);
        &:not(.init) {
            border-top: 1px dashed $baseBorderColor;
            float: left;
            width: 100%;
            display: none;
            background: $white;
            &:hover {
                background: $brandColorSecondary;
                color: $brandColor;
                @include transition(all 0.1s);
                cursor: pointer;
            }
        }
        &.selected {
            background: lighten($brandColor, 75%);
            color: $black;
        }
        &.init {
            padding: 12px 10px;
            cursor: pointer;
            position: relative;
            &:after {
                font-family: 'FontAwesome';
                content: '\f078';
                position: absolute;
                right: 10px;
            }
        }
    }
}

.custom-selector-backdrop{
    position: absolute;
    top: 0;
    bottom: 0;
    left:0;
    right: 0;
    background-color: transparent;
    z-index: 98;
}