@include keyframes(swipeListHint) {
    0% {
        left: 0px;
    }

    33% {
        left: -20px;
    }

    66% {
        left: 20px;
    }

    100% {
        left: 0px;
    }
}

@include keyframes(addtocart) {
    0% {
        left: -100%;
    }
    25% {
        left: 50%;
    }

    75% {
        left: 50%;
    }

    100% {
        left: 200%;
    }
}

@include keyframes(rotate) {
    from {
        @include rotate(0deg);
    }
    to {
        @include rotate(360deg);
    }
}

@include keyframes(attention) {
    0% {
        @include box-shadow(0 0 0 0 rgba($success, 0));
    }

    50% {
        @include box-shadow(0 0 0 2px rgba($success, .5));
    }

    100% {
        @include box-shadow(0 0 0 0 rgba($success, 0));
    }
}

@include keyframes(errorTextAttention) { 
    0% {
        text-shadow:0 0 0 rgba($warningComplimentary, 0);
    }

    50% {
        text-shadow:0 0 3px rgba($warningComplimentary, .75);
    }

    100% {
        text-shadow: 0 0 0 rgba($warningComplimentary, 0);
    }
}

@include keyframes(fader) {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
    }
    100% {
        width: 34px;
        height: 34px;
        opacity: 1;
    }
}

@include keyframes(blink) {
    0% {
        @include opacity(0);
    }

    25% {
        @include opacity(100);
    }

    50% {
        @include opacity(0);
    }

    100% {
        @include opacity(100);
    }
}


@include keyframes(blooper) {
    0% {
        width: 0;
        height: 0;
        opacity: 0;
    }
    50% {
        width: 50px;
        height: 50px;
        opacity: 1;
        background: darken($success, 10%);
    }
    100% {
        width: 34px;
        height: 34px;
        opacity: 0;
    }
}

@include keyframes(pulsate) {
    0% {
        background-color: $success;
    }
    50% {
        background-color: darken($success, 10%);
    }
    100% {
        background-color: $success;
    }
}

@include keyframes(zoomPulse) {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(1.2);
    }
    50% {
        transform: scale(0.8);
    }
    75% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@include keyframes(blinker) {
    0% {
        opacity: 1;
    }
    50%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@include keyframes(panelEntry) {
    0% {
        @include opacity(0);
        top: -1em;
    }
    50% {
        @include opacity(20);
    }
    100% {
        @include opacity(100);
        top: 0;
    }
}


.blinker-animater{
    @include animate('blinker 1s infinite linear');
}
