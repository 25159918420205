

// THEME COLORS

$brandColor: 			#092a41;
$brandColorSecondary: 	#ffeb00;
$button-hoover: #fff57f;
$new-text-color: #0A2A41;
$btn-cancel-bg-color: #0A2A41;
$btn-cancel-color: #ffeb00;
$info: 					#749e4f;
$success: 				$brandColorSecondary;
$warning: 				$brandColorSecondary;
$warningComplimentary: 	$brandColor;
$error: 				#bf1210;
$green: 				#749e4f;
//----------- DONT TOUCH ----------------------------//

$brandColorLight: lighten(desaturate($brandColor, 20%), 5%);
$brandColorLighter: lighten(desaturate($brandColor, 47%), 32%);
$brandColorLightest: lighten(desaturate($brandColor, 12%), 83%);





// Typography
// -------------------------

@import url('https://fonts.googleapis.com/css?family=Exo+2:300,400,700');

$sansFontFamily: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$serifFontFamily: Georgia, "Times New Roman", Times, serif;
$defaultFontFamily: "Helvetica Neue", Helvetica, Arial, sans-serif;
$monoFontFamily: Menlo, Monaco, Consolas, "Courier New", monospace;

$masterSecondaryFontFamily: "Exo 2", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$masterFontFamily: $sansFontFamily;
$headingsFontFamily: $masterSecondaryFontFamily;



// Inverted navbar

$navbarInverseText: $white;
$navbarInverseLinkColor: $navbarInverseText;
$navbarInverseLinkColorHover: $brandColorSecondary;
$navbarInverseLinkColorActive: $brandColorSecondary;
$navbarInverseLinkBackgroundHover: transparent;
$navbarInverseLinkBackgroundActive: transparent;


// Left navigation
// -------------------------
$navLeftBackground: $white;
$navLeftBackgroundHover: $white;
$navLeftBackgroundActive: $brandColor;
$navLeftLinkColor: $textColorMuted;
$navLeftLinkColorHover: black;
$navLeftLinkColorActive: $white;
$navLeftLinkColorInpath: $brandColor;
$navLeftBorderColor: $baseBorderColor;