$eNavGlobalTopFontSize:$baseFontSize - 1;
$eNavGlobalTopFontWeight:$eFontWeightNormal;
.e-nav-global-top {
    background: $navbarInverseBackground;
    border: 0;
    position: relative;
    z-index: 2;
    @include box-shadow(inset 0 -1px 0 $navbarInverseBorder, 0 2px 2px rgba(0, 0, 0, 0.25));
}
.e-nav-global-top.affix {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $zindexFixedNavbar;
    @include box-shadow(inset 0 -1px 0 $navbarInverseBorder, 0 2px 2px rgba(0, 0, 0, 0.25));
}
.e-nav-global-top .e-search-form {
    margin: ($navbarHeight - $inputHeight - 1) / 2 0 0;
    width: 220px;
    float: right;
    @media (max-width: 980px) {
        width: 45%;
        float: right;
    }
}
.e-nav-global-top .e-search-form-expandable {
    float: right;
    font-size: 0.625em;
    height: $navbarHeight;
    .e-search-box {
        input[type="text"] {
            + button {
                color: $navbarInverseLinkColor;
            }
        }
    }
}
.e-nav-global-top .navbar-inner {
    background: none;
    padding: 0;
    margin: 0;
}
.e-nav-global-top > .container {} .e-nav-global-top > .container > .navbar > .navbar-inner > .container > .nav-collapse > .nav > li:first-child {
    margin-left: -$gridGutterWidth * 1;
}
.e-nav-global-top > .container > .navbar > .navbar-inner > .container > .nav-collapse > .nav > li > a {
    font-size: $eNavGlobalTopFontSize;
    font-weight: $eNavGlobalTopFontWeight;
    padding-left: $gridGutterWidth * 1;
    padding-right: $gridGutterWidth * 1;
    text-shadow: none;
}
.e-nav-global-top > .container > .navbar > .navbar-inner > .container > .nav-collapse > .nav > li.active > a {
    color: $navbarInverseLinkColorActive;
}
.e-nav-global-top > .container > .navbar > .navbar-inner > .container > .nav-collapse > .nav > li.dropdown > a > i.icon-caret-down {
    @include opacity(35);
    font-size: 0.846em;
    padding-left: 0.25em;
}
.e-nav-global-top > .container > .navbar > .navbar-inner > .container > .nav-collapse > .nav > li.dropdown > ul.dropdown-menu > li.dropdown > a > i.icon-caret-down {
    display: none;
}
.e-nav-global-top > .container > .navbar > .navbar-inner > .container > .nav-collapse > .nav > li.dropdown > a:hover > i.icon-caret-down,
.e-nav-global-top > .container > .navbar > .navbar-inner > .container > .nav-collapse > .nav > li.dropdown.open > a > i.icon-caret-down {
    @include opacity(100);
}
.e-nav-global-top .navbar-inverse .nav li.dropdown.open > .dropdown-toggle {
    color: $navbarInverseLinkColorHover;
    background: $navbarInverseLinkBackgroundHover;
}
.e-nav-global-top .navbar-inverse .nav li.dropdown.active > .dropdown-toggle,
.e-nav-global-top .navbar-inverse .nav li.dropdown.open.active > .dropdown-toggle {
    color: $navbarInverseLinkColorActive;
    background: $navbarInverseLinkBackgroundActive;
}
.e-nav-global-top .navbar-inverse .navbar-search {
    margin-top: 5px;
}
.e-nav-global-top .navbar-inverse .btn-navbar {
    @include buttonBackground(lighten($navbarInverseBackground, 7%), lighten($navbarInverseBackground, 3%));
    @include box-shadow(inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.075));
    margin-top: floor($navbarHeight / 2 / 7);
    padding: 5px 13px;
    float: left;
}
// Backdrop to catch body clicks on mobile, etc.
// ---------------------------
.e-nav-global-top .dropdown-backdrop {
    position: static;
    //https://github.com/twbs/bootstrap/issues/7968
}
@media (max-width: 980px) {
    .e-nav-global-top > .container > .navbar > .navbar-inner > .container > .nav-collapse > .nav > li:first-child {
        margin-left: 0;
    }
    .e-nav-global-top > .container > .navbar > .navbar-inner > .container > .nav-collapse > .nav > li a.notext:after {
        content: attr(title);
    }
}
@media (max-width: 767px) {
    .e-nav-global-top > .container {
        padding-left: $eResponsiveBodyPadding;
        padding-right: $eResponsiveBodyPadding;
    }
    .e-nav-global-top .navbar-search {
        max-width: 50%;
    }
    .e-nav-global-top .btn-navbar {
        max-width: 50%;
    }
}
