$base: '.master-searchresults';
#{$base} {
    #{$base}-search-top {
        .e-search-form {
            .e-search-box {
                height: 55px;
                @media (max-width: 767px) {
                    height: 45px;
                }
                margin-bottom: 0;
                input[type=text] {
                    @include border-radius(0);
                    @include box-sizing(border-box);
                }
                button {
                    padding: 0;
                    height: 55px;
                    @include border-radius(0);
                    @media (max-width: 767px) {
                        height: 45px;
                    }
                    top: -1px;
                    right: -1px;
                    min-width: 140px;
                    @media (max-width: 767px) {
                        min-width: 80px;
                    }
                    background: $grayDark;
                    i {
                        color: $white;
                        font-size: 2em;
                    }
                }
            }
        }
    }
    h2 {
        margin-bottom: 1em;
    }
    #{$base}-noresults-icon {
        font-size: 100px;
    }
}

// EXTENDERS
.master-webshop-menu-top-left-menu #{$base}-filters .e-productlist-filters li fieldset label.checkbox {
    line-height: 40px;
    min-height: 40px;
    input[type=checkbox]:checked + p:before {
        height: 40px;
        line-height: 40px;
    }
}
