.hide-pseudo-elements {
    &::before,
    &::after {
        display: none!important;
    }
}

.p-a-0{
    padding: 0!important;
}
.p-a-1{
    padding: 1em!important;
}
.p-a-2{
    padding: 2em!important;
}
.p-a-3{
    padding: 3em!important;
}

.p-l-0{
    padding-left: 0!important;
}
.p-l-1{
    padding-left: 1em!important;
}
.p-l-2{
    padding-left: 2em!important;
}
.p-l-3{
    padding-left: 3em!important;
}

.p-r-0{
    padding-right: 0!important;
}
.p-r-1{
    padding-right: 1em!important;
}
.p-r-2{
    padding-right: 2em!important;
}
.p-r-3{
    padding-right: 3em!important;
}

.text-muted {
    color: $grayLight;
}

.small{
    font-size: .8em;
}