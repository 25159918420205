.first-bg {
    margin-top: -98px;
}

.bg-image {
    background-size: cover;
    h1 {
        font-size: 2.5em;
        color: $brandColorSecondary;
        text-shadow: 2px 2px 0 rgba(9, 42, 65, .7);
        font-weight: 600;
    }
    .lead {
        color: $white;
        font-weight: 600;
        letter-spacing: -.025em;
    }
}

.checklist-blue {
    list-style: none;
    li {
        &:before {
            @extend .material-icons;
            content: "check";
            position: absolute;
            margin-left: -1.6em;
            color: $brandColor;
            font-weight: 600;
        }
    }
}

.margintop0 {
    margin-top: 0;
}