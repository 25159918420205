fieldset {
   &:disabled, &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        * {
            @include opacity(80);
            cursor: not-allowed!important;
        }
    }
}
