//produktlister
.e-productlist {
    clear: both;
}
.e-productlist ul {
    margin: 0;
    list-style: none;
}
.e-productlist ul li {
    padding: $baseLineHeight $gridGutterWidth $baseLineHeight / 2;
    margin: 0;
    position: relative;
    z-index: 1;
}
.e-productlist ul li:hover {
    z-index: 2;
    outline: 2px solid $linkColor !important;
    border-color: $contentBackgroundColor;
    background: $contentBackgroundColor;
}
.e-productlist ul li .row-fluid {
    margin: 0;
}
.e-productlist ul li + li {
    border-top: 1px solid $baseBorderColor;
}
.e-productlist .e-productlist-images img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    @include border-radius($imageBorderRadius);
}
.e-productlist .e-productlist-info {
    padding: 0 $fluidGridColumnWidth * 0.846 0 $fluidGridColumnWidth * 0.154;
    line-height: 1.6;
}
.e-productlist .e-productlist-info small {
    display: block;
}
.e-productlist .e-productlist-action {} .e-productlist .e-productlist-action h2.e-price {
    font-weight: $eFontWeightLight;
    letter-spacing: -0.025em;
    margin: 0 0 $baseLineHeight / 2;
}
.e-productlist .e-productlist-action p {} .e-productlist .e-productlist-action form {
    margin: 0;
}
.e-productlist .e-productlist-action .btn {
    @include box-sizing(border-box);
    font-size: 1em;
    width: 100%;
}
@media (max-width: 980px) {
    .e-productlist .e-productlist-action [class*="icon-"] {
        display: none;
    }
}
@media (max-width: 767px) {
    .e-productlist {
        margin-left: -$eResponsiveBodyPadding;
        margin-right: -$eResponsiveBodyPadding;
    }
    .e-productlist ul li {
        padding: $baseLineHeight / 3 $eResponsiveBodyPadding $baseLineHeight / 4;
    }
    .e-productlist .e-productlist-images {
        width: 20%;
        margin-right: $fluidGridGutterWidth;
        float: left;
    }
    .e-productlist .e-productlist-info {
        overflow: hidden;
        width: auto;
    }
    .e-productlist .e-productlist-action {
        display: none;
    }
}
@media (max-width: 479px) {
    .e-productlist .e-productlist-info p:last-child {
        display: none;
    }
}
