.detection-localstorage-not-supported {
    position: fixed;
    z-index: 99999;
    height: 100vh;
    width: 100vw;
    @include box-sizing(border-box);
    padding: 2em;
    background: $grayDark;
    h1,
    p {
        color: $white;
    }
    p strong {
        color: $brandColorSecondary;
    }
}
