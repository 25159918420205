// Transitions
@mixin transition($transition...) {
  -webkit-transition: $transition;
     -moz-transition: $transition;
       -o-transition: $transition;
          transition: $transition;
}
@mixin transition-delay($transition-delay) {
  -webkit-transition-delay: $transition-delay;
     -moz-transition-delay: $transition-delay;
       -o-transition-delay: $transition-delay;
          transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration) {
  -webkit-transition-duration: $transition-duration;
     -moz-transition-duration: $transition-duration;
       -o-transition-duration: $transition-duration;
          transition-duration: $transition-duration;
}
