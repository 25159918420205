.mce-panel {
    background: $white!important;
    font-size: $baseFontSize - 1;
    border: 1px solid $grayLighter;
    &.mce-first {
        border-left: 0!important;
        border-right: 0!important;
        border-top: 0!important;
        border-bottom: 1px solid $grayLighter!important;
    }
    &.mce-last {
        border-left: 0!important;
        border-right: 0!important;
        border-bottom: 0!important;
    }
    &.mce-edit-area {
        border: 0!important;
    }
    .mce-btn {
        background-color: $white!important;
        border:0;
    }
}
