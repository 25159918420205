$base: ".master-modal";
#{$base} {
    @include box-sizing(border-box);
    @include border-radius(0);
    padding: 2em;
    overflow: auto;
    @media(max-width: 767px) {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .modal-header,
    .modal-footer,
    .modal-body {
        border: none;
        background: none;
        padding: 0;
    }
    .modal-body {
        padding: 1em 0 1.5em 0;
        &.auto-height {
            height: auto;
            max-height: none;
            min-height: none;
        }
    }
    .modal-header {
        h3 {
            font-family: $masterSecondaryFontFamily;
            font-size: 2em;
            font-weight: $eFontWeightLight;
            line-height: 1;
        }
        button.close {
            position: absolute;
            top: 0.75em;
            right: 1em;
            z-index: 99;
            &:focus,
            &:active,
            &:hover {
                outline: none;
                border: none;
                box-shadow: none;
            }
        }
    }
    .btn {
        background: $grayLighter;

        &:hover,
        &:active,
        &:focus {
            background: darken($grayLighter, 5%);
            color: $textColor;
        }
    }
    .btn.btn-primary {
        font-weight: $eFontWeightBold;
				background: $brandColorSecondary;
				border-radius: 0;

        &:hover,
        &:active,
        &:focus {
            background: $button-hoover;
        }
    }
    .btn.btn-link {
        color: $textColor;
    }
    &.master-modal-fullscreen {
        width: 90vw;
        left: 5vw;
        top: 3vh;
        margin: 0;
        .modal-content {
            @include flexbox();
            @include flex-direction(column);
            @include justify-content(center);
            @include align-items(center);
            height: 100%;
            max-width: 50vw;
            margin: 0 auto;
            text-align: center;
            @media(max-width: 979px) {
                max-width: 100%;
                @include justify-content(flex-start);
            }
        }
        @media(max-width: 979px) {
            left: 0;
            top: 0;
        }
        .modal-contact-bar {
            margin-top: 1em;
            @include flexbox();
            @include justify-content(space-evenly);
        }
    }
}

// MODAL SPECIFICS
#{$base}.terms-popup {
    .modal-body {
        p {
            font-size: 0.75em;
            line-height: 1.1;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: $masterSecondaryFontFamily;
            font-size: 15px;
            margin-bottom: 5px;
            font-weight: $eFontWeightBold;
            color: $textColor;
            text-transform: none;
            letter-spacing: 0;
        }
    }
}
