$base: '.master-webshop-details-bom-configurator';
#{$base} {
    border: 1px solid $baseBorderColor;
    margin-bottom: 3em;
    #{$base}-header {
        padding: .8em;
        background: $brandColor;
        h3 {
            color: $white;
            margin: 0;
        }
    }
    #{$base}-item {
        #{$base}-item-input {
            position: absolute;
            left: -9999px;
            @include opacity(0);
            + #{$base}-item-label {
                display: block;
                padding: .8em;
                background: $contentBackgroundColor;
            }
            &:checked {
                + #{$base}-item-label {
                    color: $white;
                    background: $success;
                    &:before {
                        margin-right: .5em;
                        content: 'check_circle';
                    }
                }
            }
        }
        #{$base}-item-label {
            margin: 0;
            &:hover {
                background: $grayLighter;
            }
            &:before {
                @extend .material-icons;
                @include transition(all .25s ease);
                color: $white;
                margin-right: 0;
                content: '';
            }
            #{$base}-item-difference {
                float: right;
                &:after {
                    content: ',-';
                }
            }
        }
    }
}
