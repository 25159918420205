.cp-mobility-code-container {
    position: relative;
    @include flexbox();
    @include justify-content(center);
    border: 2px solid $grayLighter;
    form {
        margin: 0;
        padding: 3em 3em 4em;
        .form-input--large {
            position: relative;
            input[type="text"] {
                padding-right: 4em;
            }
            input[type="submit"] {
                position: absolute;
                top: 0;
                right: 0;
                border-radius: 0;
                width: 60px;
            }
        }
    }
    .cp-mobility-feedback {
        position: absolute;
        bottom: 0;
    }
}

.cp-mobility-signup-container {
    display: none;
    position: relative;
    border: 2px solid $grayLighter;
    form {
        padding: 3em;
        margin: 0;
        .input-append .add-on {
            height: 26px;
            padding: 9px 5px;
            border: 0;
            cursor: pointer;
        }
    }
    .cp-mobility-feedback-success-inner {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        text-align: center;
        background: $info;
        color: $white;
        padding: 7px;
        p {
            margin: 0;
        }
    }
}

.cp-mobility-loader {
    position: absolute;
    background: rgba($white, .9);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    @include justify-content(center);
    @include align-items(center);
    &.loading {
        @include flexbox();
    }
    .cp-mobility-loader-inner {
        p {
            margin: 0;
            text-align: center;
            font-weight: $eFontWeightBold;
        }
        .master-spinner {
            margin: auto;
        }
    }
}

.cp-mobility-feedback {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    .cp-mobility-feedback-error {
        display: none;
        padding: .8em;
        color: $white;
        background-color: $error;
    }
    .cp-mobility-feedback-success {
        display: none;
        margin: .8em;
        color: $info;
    }
}

.cp-mobility-car-extra-info {
    text-transform: capitalize;
    display: inline-block;
}

.cp-mobility-datepicker {
    position: relative;
    table {
        background: transparent;
        width: 100%;
        font-size: .9em;
        tr td {
            text-align: center;
            position: relative;
            padding: 8px 5px;
            border-bottom: 2px solid $white;
            z-index: 1;
            &.cw {
                background: $grayLighter;
            }
            &.day {
                cursor: pointer;
                &:hover::before {
                    content: "";
                    position: absolute;
                    width: 34px;
                    height: 34px;
                    border: 2px solid $success;
                    @include border-radius(50%);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                }
            }
            &.disabled.day {
                cursor: default;
                color: $baseBorderColor;
                &:hover::before {
                    border: none;
                }
            }
            &.active.day {
                color: $white;
                font-weight: $eFontWeightBold;
                background: transparent;
                border: 0;
                &:before {
                    content: "";
                    position: absolute;
                    width: 34px;
                    height: 34px;
                    background: $success;
                    @include border-radius(50%);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                    -webkit-animation: fader 0.50s ease forwards;
                    animation: fader 0.50s ease forwards;
                    -webkit-animation-iteration-count: 1;
                    animation-iteration-count: 1;
                }
                &:after {
                    content: "";
                    position: absolute;
                    opacity: 0;
                    width: 50px;
                    height: 50px;
                    background: $success;
                    @include border-radius(50%);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -2;
                    @include transition(all .5s ease-in-out);
                    -webkit-animation: blooper 0.50s ease forwards;
                    animation: blooper 0.50s ease forwards;
                    -webkit-animation-iteration-count: 1;
                    animation-iteration-count: 1;
                }
            }
        }
    }
}
