.workshop-administration-service-sort-modal{
    height: 90vh;
    .master-sortable-service-list-item{
        display:flex;
        align-items: center;
        margin: 2.5px!important;
        cursor: move;
        &.disabled{
            cursor: not-allowed;
            opacity: .6;
        }
        &:hover{
            transition: all .01s!important;
            background: #e1e1e1!important;

            .master-sortable-service-list-item-handle{
                transform: scale(1.1);
                opacity:1;
            }
        }
        .master-sortable-service-list-item-handle{

            opacity: .7;
            transition: all .03s;
        }
        .master-sortable-service-list-item-name{
            margin-left:10px;
        }
    }
    .border-bottom-dashed{
        display: block;
        border-bottom: 1px dashed #ccc;
    }
    .master-sortable-service-list-sidebar-text{
        font-size: .95em;
        .master-sortable-service-list-sidebar-text-list{
            list-style: none;
        }
    }
    .master-service-bookservice .master-service-bookservice-section{
        background: transparent;
    }
}

.workshop-administration-service-sort-modal-button{
    margin:1em 0;
    a{
        float:right;
        font-weight: $eFontWeightBold;
        background: $success;
        border-bottom: 0.3em solid darken($success, 5%);
        color: $white;
        &:hover,
        &:active,
        &:focus {
            color:$white;
            background: darken($success, 5%);
            border-bottom: 0.3em solid darken($success, 10%);
        }
    }
}
