
/* TABLES */

.table.table-striped {
    tbody {
        tr,
        td,
        {
            border-top: none;
        }
        tr:last-child {
            border-bottom: none;
        }
    }
}
