// Common slider styles shared
// -------------------------
.carousel {
    z-index: 1;
    margin-bottom: 0;
    font-size: 0.846em;
    line-height: 1.3;
    .carousel-caption {
        color: $grayLighter;
        line-height: 1.3;
        background: rgba($black, 0.75);
        padding: 0.5em;
        &.black {} &.white {
            color: $textColor;
            background: rgba($white, 0.75);
        }
        &.transparent {
            color: $textColor;
            background: none;
        }
        h1,
        h2,
        h3,
        h4,
        p {
            color: inherit;
        }
        p {
            margin-bottom: 0.5em;
            &:last-child {
                margin: 0;
            }
            a {
                white-space: nowrap;
                &:not(.btn) {
                    color: inherit;
                    text-decoration: underline;
                }
            }
        }
    }
    .carousel-control {
        font-size: 2em;
        line-height: 1;
        top: 0.5em;
        left: auto;
        right: 0.5em;
        margin: 0;
        height: 1em;
        width: 1em;
        color: $grayLighter;
        border-color: $grayLighter;
        border-width: 2px;
        @include opacity(100);
        @include border-radius(50%);
        &.right {
            i {
                margin-left: 0.0846em;
            }
        }
        &.left {
            right: 1.75em;
            i {
                margin-right: 0.0846em;
            }
        }
        i {
            margin-top: -0.525em;
            font-size: 1em;
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            line-height: 1;
            font-weight: $eFontWeightLight;
        }
    }
    .carousel-indicators {
        //display: none;
        top: 0.5em;
        left: 0.5em;
        right: auto;
    }
}
// Carousel gallery
.e-carousel-content {
    .carousel-inner {
        border-bottom: 0.375em solid $navbarInverseBackground;
        &:after {
            @media (min-width: 768px) {
                content: '';
                height: 1px;
                @include box-shadow(inset 0 1px 0 $navbarInverseBorder, 0 -2px 2px rgba(0, 0, 0, 0.25));
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
    }
    .carousel-control {
        @media (min-width: 481px) {
            display: none;
        }
    }
    .carousel-caption {
        color: $grayLighter;
        left: 7.5%;
        top: 3em;
        right: auto;
        bottom: auto;
        width: 23em;
        padding: 1em;
        overflow: hidden;
        &.transparent {
            overflow: visible;
            color: $textColor;
            @media (min-width: 481px) {
                padding-left: 0;
                padding-right: 0;
            }
        }
        &.left {
            //styles er de samme som standard
            /*left: $gridGutterWidth;
            @media (min-width: 1240px) {
                left: $gridGutterWidth * 3;
            }
            @media (min-width: 1320px) {
                left: $gridGutterWidth * 4;
            }
            @media (min-width: 1640px) {
                left: $gridGutterWidth * 5;
            }*/
        }
        &.right {
            right: 7.5%;
            left: auto;
            /*right: $gridGutterWidth;
            @media (min-width: 1240px) {
                right: $gridGutterWidth * 3;
            }
            @media (min-width: 1320px) {
                right: $gridGutterWidth * 4;
            }
            @media (min-width: 1640px) {
                right: $gridGutterWidth * 5;
            }*/
        }
        &.top {
            //styles er de samme som standard
        }
        &.bottom {
            bottom: 2em;
            top: auto;
        }
        h1,
        h2,
        h3,
        h4,
        p,
        p a:not(.btn) {
            color: inherit;
        }
    }
    .carousel-indicators {
        @media (max-width: 480px) {
            display: none;
        }
        @media (max-width: 767px) {
            margin-left: -$eResponsiveBodyPadding;
            margin-right: -$eResponsiveBodyPadding;
        }
        position: static;
        overflow: hidden;
        li {
            color: $textColorMuted;
            font-size: 1em;
            line-height: 1.3;
            width: 25%;
            cursor: pointer;
            text-indent: 0;
            height: 4.5em;
            padding: 1em;
            margin: 0;
            border: 1px solid $baseBorderColor;
            border-width: 0 1px 1px 0;
            border-radius: 0;
            background-color: $baseBackgroundColor;
            @include box-sizing(border-box);
            &:first-child {
                border-radius: 0 0 0 $boxBorderRadius;
                border-left-width: 1px;
            }
            &:last-child {
                border-radius: 0 0 $boxBorderRadius 0;
            }
            &:hover {
                border-color: darken($baseBorderColor, 5%);
                background-color: darken($baseBackgroundColor, 5%);
            }
            &.active,
            &.active:hover {
                color: $navbarInverseLinkColorActive;
                border-color: $navbarInverseBackground;
                background-color: $navbarInverseBackground;
            }
        }
    }
}
// Compact gallery with thumbs
.e-carousel-compact {
    overflow: hidden;
    .carousel-inner {
        border-left: 4px solid $contentBackgroundColor;
        border-right: 4px solid $contentBackgroundColor;
        @include box-sizing(border-box);
        > .item {
            @include transition(0.2s ease-in-out left);
        }
    }
    .e-carousel-compact-thumbs {
        overflow: hidden;
        position: relative;
        border: 4px solid $contentBackgroundColor;
        .carousel-control {
            top: 50%;
            margin-top: -0.5em;
            cursor: pointer;
            @include opacity(100);
            z-index: $zindexTooltip;
            &.left {
                left: 0.5em;
                right: auto;
            }
            &.inactive {
                cursor: inherit;
                display: none;
            }
        }
    }
    .carousel-indicators {
        position: relative;
        left: 0;
        top: 0;
        max-height: 112px;
        overflow: hidden;
        @include box-shadow(inset 5px 0 10px rgba($contentBackgroundColor, 0.5));
    }
    .carousel-indicators li {
        position: relative;
        cursor: pointer;
        text-indent: 0;
        width: 200px;
        height: 100%;
        left: 0;
        margin: 0;
        @include transition(all 0.1s ease-in-out);
        @include box-sizing(border-box);
        outline: 4px solid $contentBackgroundColor;
        &:hover img, &.active img {
            @include opacity(100);
        }
        img {
            @include opacity(50);
            @include transition(all 0.2s ease-in-out);
            @include backface-visibility(hidden);
        }
    }
}
//Nice responsiveness
.span12 > .e-carousel-content .carousel-inner {
    width: auto;
    margin-left: -$gridGutterWidth;
    margin-right: -$gridGutterWidth;
    @media (max-width: 767px) {
        margin-left: -$eResponsiveBodyPadding;
        margin-right: -$eResponsiveBodyPadding;
    }
    @media (min-width: 1240px) {
        margin-left: -$gridGutterWidth * 3;
        margin-right: -$gridGutterWidth * 3;
    }
    @media (min-width: 1320px) {
        margin-left: -$gridGutterWidth * 4;
        margin-right: -$gridGutterWidth * 4;
    }
    @media (min-width: 1640px) {
        margin-left: -$gridGutterWidth * 5;
        margin-right: -$gridGutterWidth * 5;
    }
}
.e-carousel-content .carousel-caption.left {} .e-carousel-content .carousel-caption.right {} @media (max-width: 480px) {
    .e-carousel-content {
        font-size: 0.75em;
    }
    .e-carousel-content .carousel-caption {
        background-color: $navbarInverseBackground;
        width: 100%;
    }
}
.span12 > .e-carousel-compact {
    width: auto;
    margin-left: -$gridGutterWidth;
    margin-right: -$gridGutterWidth;
    @media (min-width: 1240px) {
        margin-left: -$gridGutterWidth * 3;
        margin-right: -$gridGutterWidth * 3;
    }
    @media (min-width: 1320px) {
        margin-left: -$gridGutterWidth * 4;
        margin-right: -$gridGutterWidth * 4;
    }
    @media (min-width: 1640px) {
        margin-left: -$gridGutterWidth * 5;
        margin-right: -$gridGutterWidth * 5;
    }
}
@media (min-width: 1320px) {
    .e-carousel-compact .carousel-indicators li {
        width: 157.2px;
    }
}
@media (max-width: 1319px) {
    .e-carousel-compact .carousel-indicators li {
        width: 177px;
    }
}
@media (max-width: 1240px) {
    .e-carousel-compact .carousel-indicators li {
        width: 196px;
    }
}
@media (max-width: 979px) {
    .e-carousel-compact .carousel-indicators li {
        width: 153px;
    }
}
@media (max-width: 767px) {
    .span12>.e-carousel-compact {} .e-carousel-compact .carousel-indicators {}
}
//Nice responsiveness
.span12 > .e-carousel-image {
    width: auto;
    margin-left: -$gridGutterWidth;
    margin-right: -$gridGutterWidth;
    @media (min-width: 1240px) {
        margin-left: -$gridGutterWidth * 3;
        margin-right: -$gridGutterWidth * 3;
    }
    @media (min-width: 1320px) {
        margin-left: -$gridGutterWidth * 4;
        margin-right: -$gridGutterWidth * 4;
    }
    @media (min-width: 1640px) {
        margin-left: -$gridGutterWidth * 5;
        margin-right: -$gridGutterWidth * 5;
    }
}
.span12 > .e-carousel-image .item img {
    @include border-radius(0);
}
.span12 > .e-carousel-image .item .carousel-caption {
    @include border-radius(0);
}
@media (max-width: 767px) {
    .e-carousel-image {
        margin-left: -$eResponsiveBodyPadding;
        margin-right: -$eResponsiveBodyPadding;
    }
    .e-carousel-image .carousel-control {
        top: $baseLineHeight;
        margin-top: 0;
    }
}
