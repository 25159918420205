//
// Responsive: Utility classes
// --------------------------------------------------


// IE10 Metro responsive
// Required for Windows 8 Metro split-screen snapping with IE10
// Source: http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
@-ms-viewport{
  width: device-width;
}

// Hide from screenreaders and browsers
// Credit: HTML5 Boilerplate
.hidden {
  display: none;
  visibility: hidden;
}

// Visibility utilities

// For desktops
.visible-phone     { display: none !important; }
.visible-tablet    { display: none !important; }
.hidden-phone      { }
.hidden-tablet     { }
.hidden-desktop    { display: none !important; }
.visible-desktop   { display: inherit !important; }

// Tablets & small desktops only
@media (min-width: 768px) and (max-width: 979px) {
  // Hide everything else
  .hidden-desktop    { display: inherit !important; }
  .visible-desktop   { display: none !important ; }
  // Show
  .visible-tablet    { display: inherit !important; }
  // Hide
  .hidden-tablet     { display: none !important; }
}

// Phones only
@media (max-width: 767px) {
  // Hide everything else
  .hidden-desktop    { display: inherit !important; }
  .visible-desktop   { display: none !important; }
  // Show
  .visible-phone     { display: inherit !important; } // Use inherit to restore previous behavior
  // Hide
  .hidden-phone      { display: none !important; }
}

// Print utilities
.visible-print    { display: none !important; }
.hidden-print     { }

@media print {
  .visible-print  { display: inherit !important; }
  .hidden-print   { display: none !important; }
}

// Clearing floats
.clearfix {
  @include clearfix();
}

// Accessible yet invisible text
.hide-text {
  @include hide-text();
}

// Uses box-sizing mixin, so must be defined here
.input-block-level {
  @include input-block-level();
}
