.e-content {
    background-color: $contentBackgroundColor;
}
.e-content > .row,
.e-content > .row > .featured > .row {
    margin-bottom: $baseLineHeight;
}
.e-content > .row > .featured > .row:last-child .span12 > .e-img:last-child {
    margin-bottom: -$baseLineHeight;
}
