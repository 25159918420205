$eHeaderHeight: $baseLineHeight * 3.5;
$eHeaderBorderColor: #575757;
.e-header {
    padding-top: $baseLineHeight / 3;
    padding-bottom: $baseLineHeight / 3;
    background-color: $contentBackgroundColor;
    @media (max-width: 979px) {
        >.container>.row-fluid>.span4 {
            width: 50%;
            margin: 0;
            float: left;
        }
    }
    @media (max-width: 767px) {
        padding-top: 2%;
        padding-bottom: 2%;
    }
}
.e-header {
    .e-logo {
        margin: 0;
        width: 100%;
        height: $eHeaderHeight;
        line-height: $eHeaderHeight;
        display: block;
        img {
            @include border-radius(0);
        }
    }
}
.e-header {
    .e-search-form {
        margin: 0 auto;
        margin-top: $eHeaderHeight / 2 - $inputHeight / 2;
    }
    .e-search-form-expandable {
        position: absolute;
        right: 0;
        top: 0;
        color: $navbarInverseText;
        border-left: 1px solid $eHeaderBorderColor;
    }
}
.e-header {
    .e-nav-cta-top {
        position: relative;
        white-space: nowrap;
        text-align: right;
        margin-top: 0;
        @media (max-width: 480px) {
            width: 100%;
            padding: 0;
            margin: 0 auto;
            float: none;
            text-align: center;
        }
        a {
            font-size: 0.846em;
            line-height: $eHeaderHeight;
            color: $white;
            padding-right: 1em;
            text-transform: uppercase;
            text-decoration: none;
            @media (max-width: 767px) {
                display: block;
                line-height: 1.8;
            }
            @media (max-width: 480px) {
                font-size: 0.66em;
                display: inline;
            }
            @media (max-width: 320px) {
                display: block;
                line-height: 2.5;
            }
            i {
                color: $brandColor;
                padding: 0 0.5em;
            }
        }
    }
}
