[data-tooltip] + .tooltip {
    background: rgba($white, 1);
    color: $textColor;
    position: absolute;
    padding: 1em;
    top: 90%;
    @include opacity(0);
    width: 100%;
    left: 0;
    z-index: $zindexTooltip;
    @include box-sizing(border-box);
    @include box-shadow(5px 5px 0px rgba(0, 0, 0, 0.1));
    font-weight: $eFontWeightBold;
    font-size: $baseFontSize - 3;
    border: 1px solid $baseBorderColor;
    @include transition(all .3s ease-out);
}

.popover {
    background: rgba($white, 1);
    color: $textColor;
    position: absolute;
    padding: 1em;
    width: 400px;
    max-width: 400px;
    z-index: $zindexTooltip;
    @include box-sizing(border-box);
    @include box-shadow(5px 5px 0px rgba(0, 0, 0, 0.1));
    @include border-radius(0);
    font-weight: $eFontWeightBold;
    font-size: $baseFontSize - 3;
    border: 1px solid $baseBorderColor;
    @media(max-width: 767px) {
        left: 0!important;
        display: block;
        top: -80%!important;
        box-sizing: border-box;
        width: 100%;
        margin: 0;
        &.left{
            margin-left: 0;
        }
        .arrow{
            display: none;
        }
    }
}
