$base: ".master-account-details";
#{$base} {
    .dl-horizontal dt {
        text-align: left;
    }
    .btn-large {
        background: $success;
        color: $white;
        border-bottom: 0.25em solid darken($success, 5%);
        &:hover,
        &:focus,
        &:active {
            background: darken($success, 5%);
            border-bottom: 0.25em solid darken($success, 10%);
        }
    }
    #{$base}-username {
        margin: 0;
        color: $white;
        background: $brandColor;
        text-align: center;
        padding: 1em;
        margin-bottom: 1.5em;
    }
    #{$base}-delete-account-confirmation {
        position: absolute;
        strong {
            color: $error;
            position: absolute;
            margin-top: -24px;
        }
        #{$base}-delete-account-confirmation-inner {
            @include flexbox();
            font-size: .7em;
            margin-top: 1em;
            margin-left: -20%;
            float: left;
            padding: 1.1em 0 0;
            background: $contentBackgroundColor;
            @include transition(all .3s ease-out);
            @include opacity(0);
            line-height: 2.4em;
            a {
                text-decoration: none;
                background: $error;
                color: $white;
                padding: 0 .55em;
                @include transition(all .3s ease);
                border-right: 1px solid darken($error, 10%);
                &:hover {
                    background: lighten($error, 10%);
                }
                &:last-child {
                    border: 0;
                }
            }
            &.open {
                @include opacity(100);
                margin-left: 0%;
            }
            @media(max-width: 767px) {
                margin-top: 0;
            }
        }
    }
    a#{$base}-finishprofile {
        position: relative;
        display: block;
        background: $success;
        padding: 1.5em 3em 1.2em 3em;
        @include border-radius(5px);
        border-bottom: 0.3em solid darken($success, 5%);
        h3,
        p {
            color: $white;
            margin-top: 0;
            @media(min-width: 768px) and (max-width: 979px) {
                max-width: 70%;
            }
            @media(max-width: 767px) {
                text-align: center;
            }
        }
        @media(max-width: 979px) {
            p {
                font-size: $baseFontSize - 3;
                line-height: $baseLineHeight - 3;
            }
        }
        img {
            position: absolute;
            top: 1em;
            right: 3em;
            width: 110px;
            height: 110px;
            @media(min-width: 768px) and (max-width: 979px) {
                top: 1.25em;
                width: 100px;
                height: 100px;
            }
            @media(max-width: 767px) {
                position: relative;
                display: table;
                margin: 0 auto 1em auto;
                top: auto;
                right: auto;
            }
        }
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }
    a#{$base}-fineprint-link {
        display: inline-block;
        margin-top: 1em;
        font-size: 0.75em;
        border-bottom: 1px dotted $grayLight;
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
        &.open {
            padding-right: .6em;
        }
        @media(max-width: 767px) {
            width: 100%;
        }
    }
}

// ORDERLIST
#{$base}-orderlist {
    .master-button--large {
        background: $grayLighter;
        &:hover,
        &:focus,
        &:active {
            background: darken($success, 5%);
            border-bottom: 0.2em solid darken($success, 10%);
        }
    }
    #{$base}-orderlist-cancel-booking {
        position: relative;
        #{$base}-orderlist-cancel-booking-button {
            color: $error;
            background: transparent;
        }
        #{$base}-orderlist-cancel-booking-confirm-animation {
            position: absolute;
            right: 30px;
            width: 0;
            overflow: hidden;
            @include transition(all .8s ease);
            .material-icons {
                position: absolute;
                margin-left: -20px;
                margin-top: 4px;
            }
            &.confirmed {
                width: 100%;
            }
        }
        @media (max-width: 767px) {
            padding: 1px;
            height: 100%;
            #{$base}-orderlist-cancel-booking-button {
                display: block;
                text-align: center;
                color: $white;
                background: $error;
                height: 100%;
                i {
                    vertical-align: bottom;
                }
            }
        }
        #{$base}-orderlist-cancel-booking-confirmation {
            position: absolute;
            top: 0;
            height: 100%;
            @include box-sizing(border-box);
            @include opacity(0);
            right: -100px;
            font-size: $baseFontSize - 4;
            @include transition(all .5s ease-in-out);
            &.open {
                right: 0;
                @include opacity(100);
            }
            p {
                position: absolute;
                top: -20px;
                color: $textColor;
                font-weight: $eFontWeightBold;
            }
            #{$base}-orderlist-cancel-booking-confirmation-inner {
                @include flexbox();
                height: 100%;
                a {
                    padding: 0.56em 1em;
                    text-decoration: none;
                    color: $white;
                    height: 100%;
                    background: $error;
                    @include transition(all .3s ease);
                    @include box-sizing(border-box);
                    &:hover {
                        background: lighten($error, 10%);
                    }
                    &:first-child {
                        border-right: 1px solid lighten($error, 10%);
                    }
                }
            }
        }
    }
    table {
        font-size: $baseFontSize - 2;
        @media (max-width: 767px) {
            font-size: $baseFontSize - 5;
        }
        th {
            span {
                display: inline-block;
                margin-right: 10px;
            }
            a i {
                width: 13px;
                color: $grayLight;
            }
        }
    }
}
