.e-productdetails-configurator {
    .e-label {
        margin-bottom:0;
    }
    .control-group + hr {
    }
    .control-group .controls {
        select {
            height: $baseLineHeight;
            min-height: $baseLineHeight;
            max-height: $baseLineHeight;
            line-height: $baseLineHeight;
            padding-top:0;
            padding-bottom:0;
            font-size: 0.846em;
        }
        .radio {
            line-height:1.4;
            margin-bottom:6px;
            color: $textColor;
            font-weight: $eFontWeightNormal;
            input[type="radio"] {
                margin-top:3px;
            }
        }
    }
}
