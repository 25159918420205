$base: ".master-serviceoverview-list";
#{$base}-introduction {
    padding-top: 1em;
    margin: 0;
    h2 {
        font-weight: $eFontWeightLight;
        font-family: $masterSecondaryFontFamily;
        margin-top: 0.75em;
        color: $textColorMuted;
        margin-bottom: 0;
    }
    .master-button--large {
        background: $success;
        border-bottom: 0.2em solid darken($success, 5%);
        color: $white;
        @media (max-width: 767px) {
            @include box-sizing(border-box);
            width: 100%;
        }
        &:hover,
        &:active,
        &:focus {
            background: darken($success, 5%);
            border-bottom: 0.2em solid darken($success, 10%);
        }
    }
}

#{$base} {
    @media (min-width: 768px) {
        background: lighten($grayLighter, 3%);
    }
    ul {
        margin: 0;
        padding: 1em 0 2.5em 0;
        list-style-type: none;
        li {
            position: relative;
            float: left;
            @include transition(all, 1s);
            min-height: 8em;
            background: $white;
            @include border-radius(5px);
            margin-bottom: 4px;
            @media(min-width: 768px) and (max-width: 979px) {
                margin-bottom: 10px;
            }
            width: 33%;
            @media (min-width: 980px) {
                &:nth-child(3n+2) {
                    margin: 0 0.5%;
                }
            }
            // MOBILE
            @media (max-width: 767px) {
                width: 100%;
            }
            // TABLET
            @media (min-width: 768px) and (max-width: 979px) {
                width: 49%;
                &:nth-child(odd) {
                    margin-right: 2%;
                }
            }
            &:hover,
            &:focus,
            &:active {
                @include transition(all, 0.15s);
                background: $success;
            }
        }
        #{$base}-listelement {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            @include box-sizing(border-box);
            padding: 0.75em;
            @include transition(all, 1s);
            > span.product-image {
                svg,
                img {
                    position: absolute;
                    top: 0.75em;
                    left: 0.75em;
                    width: 50px;
                    height: 50px;
                }
            }
            span.label-campaign {
                svg,
                img {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 90px;
                    height: 90px;
                    .svgColorPrimary {
                        fill: $white;
                    }
                    .svgColorSecondary {
                        fill: $info;
                    }
                }
            }
            > div {
                float: left;
                margin-left: 4em;
                h4 {
                    margin-top: 0;
                    margin-bottom: 0.15em;
                    letter-spacing: 0.025em;
                }
                p {
                    color: $grayLight;
                    font-size: $baseFontSize - 5;
                    line-height: $baseLineHeight - 8;
                }
                p,
                h4 {
                    @include transition(all, 1s);
                }
            }
            &:hover,
            &:focus {
                text-decoration: none;
                p,
                h4 {
                    @include transition(all, 0.15s);
                    color: $brandColor;
                }
            }
        }
    }
    #{$base}-nobooking-icon {
        font-size: 100px;
    }
    a.btn.master-button--mega {
        background: $success;
        border-bottom-color: darken($success, 5%);
        &:hover,
        &:focus,
        &:active {
            background: darken($success, 5%);
            border-bottom-color: darken($success, 10%);
        }
        color: $white;
    }
}
