//
// Close icons
// --------------------------------------------------

.close {
    font-size: $baseLineHeight;
    line-height: $baseLineHeight;
}

.modal .close {
    @include opacity(80);
    &:hover {
        @include opacity(100);
    }
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
button.close {}
