body {
    background: $bodyBackground;
    overflow-x: hidden;
}
#e-grid {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: $zindexFixedNavbar - 1;
}
#e-grid [class*="span"] {
    background: rgba($brandColor, 0.1);
    height: 100%;
}
