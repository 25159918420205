@media (max-width: 767px) {
    .swipe-list-hint {
        @include animate('swipeListHint 2s 1 ease');
    }
}

.swipe-container {
    overflow: hidden;
    @media (max-width: 767px) {
        padding: 0!important;
    }
}

.swipe-list {
    position: relative;
    @include transition(all .3s cubic-bezier(.32, 1.25, .375, 1.15));
    @media (max-width: 767px) {
        padding: 5px 0;
    }
    .swipe-list-item-content {
        min-height: 80px;
    }
    .swipe-list-item {
        position: absolute;
        width: 0;
        overflow: hidden;
        right: 0;
        left: auto;
        height: 100%;
        top: 0;
        @include box-sizing(border-box);
        text-align: center;
        font-size: 1.6em;
        display: none;
        @media (max-width: 767px) {
            @include flexbox();
            @include flex-direction(column);
        }
        small {
            font-size: .6em;
        }
        .swipe-list-item-action {
            @include flex(1);
            @include flexbox();
            @include flex-direction(column);
            @include justify-content(center);
            color: $white!important;
            font-size: .75em;
        }
        span.swipe-list-item-action {
            font-weight: $eFontWeightBold;
            background: lighten($success, 10%);
            @include flex-direction(row);
            @include align-items(center);
            small {
                color: $white!important;
                margin-left: .3em;
            }
        }
        a.swipe-list-item-action {
            text-decoration: none;
            &.e-orderline-increment-link {
                border-bottom: 1px solid darken($success, 10%);
            }
            &.e-orderline-decrement-link {
                border-top: 1px solid darken($success, 10%);
            }
        }
        .swipe-list-item-action-confirmation {
            position: absolute;
            top: 0;
            bottom: 0;
            right: -100%;
            @include flexbox();
            @include flex-direction(column);
            @include align-items(center);
            @include justify-content(space-around);
            width: 100%;
            height: 100%;
            @include transition(all .3s cubic-bezier(.32, 1.25, .375, 1.15));
            a {
                text-decoration: none;
                width: 100%;
                @include flex(1);
                @include flexbox();
                @include flex-direction(column);
                @include justify-content(center);
                color: $white;
                background: $error;
                font-size: .6em;
                &.swipe-list-item-action-confirm {
                    background: lighten($error, 10%);
                }
                &.swipe-list-item-action-cancel {
                    border-top: 1px solid $error;
                    background: lighten($error, 10%);
                }
            }
            &.open {
                right: 0;
            }
        }
        &.swipe-list-item-right {
            background: $error;
            right: -100px;
            left: auto;
            width: 90px;
            @include box-shadow(-2px 0px 9px -1px rgba($black, .3));
            @media (max-width: 767px) {
                @include justify-content(center);
            }
        }
        &.swipe-list-item-left {
            background: $success;
            left: -100px;
            width: 90px;
            right: auto;
            @include box-shadow(2px 0px 9px -1px rgba($black, .3));
            @media (max-width: 767px) {
                @include justify-content(space-between);
            }
        }
    }
    .swipe-list-item-locked {
        i {
            color: $white;
            font-size: 2em;
            margin-top: 50%;
            transform: translate(0, -55%);
        }
    }
}
