$base: '.master-webshop-details-price-matrix';
#{$base} {
    #{$base}-table {
        th,
        td {
            font-size: .9em;
        }
        #{$base}-item {
            cursor: pointer;
            @include transition(all .3s ease);
            background: $contentBackgroundColor;
            &:hover {
                background: $grayLighter;
            }
            input[type="radio"] {
                position: absolute;
                left: -9999px;
                @include opacity(0);
                + label {
                    position: absolute;
                    color: $success;
                    margin: 0;
                    &:before {
                        @extend .material-icons;
                    }
                }
                &:checked {
                    +label {
                        &:before {
                            content: 'check_circle';
                        }
                    }
                }
            }
        }
    }
}
