$base: ".master-workshop-validation";
#{$base}-wrapper {
    position: relative;
    padding: 1.25em 0;
    margin-bottom: 0;
    @media (max-width: 767px) {
        margin-top: -2em;
        margin-bottom: 2em;
    }
    #{$base}-icon {
        &:before {
            position: absolute;
            top: 9px;
            left: auto;
            width: auto;
            height: auto;
            @extend .material-icons;
            content: "warning";
            font-size: 4.5em;
            /* IE 10+ START */
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                font-size: 0.85em;
            }
            /* IE 10+ END */
            color: $grayLighter;
        }
        &.attention{
            &:before{
                @include animate('zoomPulse 1s 1 ease-in-out');
            }
        }
    }
    #{$base}-button {
        width: 100%;
        @include box-sizing(border-box);
        font-size: 1em;
        font-weight: $eFontWeightBold;
				float: right;
				
        &:hover {
            background: $btn-cancel-bg-color;
        }
    }
    p {
        font-size: 1.25em;
        font-weight: $eFontWeightBold;
        margin-bottom: 0;

        small {
            font-size: $baseFontSize - 3;
            font-family: $sansFontFamily;
            font-weight: normal;
        }
    }
    a#{$base}-link {
        font-size: 0.846em;
        text-decoration: underline;
    }
}

// WARNING
#{$base}--warning {
    background: $warning;
    #{$base}-icon {
        &:before {
            text-shadow: 0px 3px 0px darken($warning, 10%);
        }
    }
    p {
        color: $warningComplimentary;
    }
    a#{$base}-link {
        color: $grayDark;
    }
}

// ERROR
#{$base}--error {
    background: $error;
    #{$base}-icon {
        &:before {
            content: "error";
            text-shadow: 0px 3px 0px darken($error, 10%);
        }
    }
    p {
        color: $white;
    }
    a#{$base}-link {
        color: $grayLighter;
    }
}

// SUCCESS
#{$base}--success {
    background: $success;
    #{$base}-icon {
        &:before {
            content: "done";
            text-shadow: 0px 3px 0px darken($success, 10%);
        }
    }
    p {
        color: $white;
    }
    a#{$base}-link {
        color: $grayLighter;
    }
}

// MOBILE
@media (max-width: 767px) {
    #{$base}-wrapper {
        padding: 2em;
        text-align: center;
        #{$base}-icon {
            &:before {
                transform: translate(-50%, 0);
            }
        }
        a#{$base}-button {
            width: auto;
            float: none;
        }
    }
    .span3 {
        margin-bottom: 0;
    }
}

.master-workshop-loggedin-wrapper {
    position: relative;
    padding: .8em 0;
    margin-bottom: 0;
    text-align: center;
    background: $success;
    color: $white;
    font-size: 1.2em;
    @media (max-width: 767px) {
        margin-top: -2em;
        margin-bottom: 2em;
    }
}
