@import "custom-forms";
@import "inputs";
@import "form-states";
@import "validation";

textarea {
    @include border-radius(0);
    @include box-sizing(border-box);
    @include box-shadow(none);
    font-size: $baseFontSize - 1;
    border: 1px solid $grayLighter;
    width: 100%;
    max-width: 100%;
    margin: 0;
    resize: none;
}
