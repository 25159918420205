.e-nav-sitemap li a {
    display: block;
    padding: $baseLineHeight / 6 0;
    color: $textColor;
}

.e-nav-sitemap li a:hover {
    color: $linkColor;
}

.e-nav-sitemap > li > a {
    font-weight: bold;
}

.e-nav-sitemap li ul {
    list-style: none;
}
