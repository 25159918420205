.e-bottom {
    font-size: 0.846em;
    line-height: 1.6;
    padding-top: 2.25em;
    padding-bottom: 1.75em;
    color: $textColorMuted;
    background: $baseBackgroundColor;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.e-bottom a {
    color: $textColorMuted;
    text-decoration: underline;
}
.e-bottom a:hover {
    color: $textColor;
    text-decoration: none;
}
.e-bottom h1,
.e-bottom h2,
.e-bottom h3,
.e-bottom h4 {
    color: $headingsColorMuted;
}
.e-bottom h1 a,
.e-bottom h2 a,
.e-bottom h3 a,
.e-bottom h4 a {
    color: $headingsColorMuted;
    text-decoration: none;
}
.e-bottom h4,
.e-bottom h4 a {
    color: lighten($textColorMuted, 20%);
}
.e-bottom h1 a:hover,
.e-bottom h2 a:hover,
.e-bottom h3 a:hover,
.e-bottom h4 a:hover {
    text-decoration: underline;
}
@media (max-width: 767px) {
    .e-bottom {
        @include border-radius(0);
    }
}
