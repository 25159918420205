/* CHECKLIST */

ul.checklist {
    list-style-type: none;
    margin-left: 0;
    font-family: $masterSecondaryFontFamily;
    font-size: $baseFontSize + 2;
    li {
        line-height: 1.4em;
        margin-left: 1.8em;
        position: relative;
        font-weight: $eFontWeightBold;
        font-size: .85em;
    }
    li:before {
        @extend .material-icons;
        content: "done";
        position: absolute;
        margin-left: -1.6em;
        color: $success;
        font-weight: $eFontWeightBold;
    }
}


