.e-newsletter-popup-teaser {
    background: darken($brandColor, 3%);
    @include border-radius(50px 0 0 0);
    box-shadow: none;
    &:hover {
        background: darken($brandColor, 6%) !important;
    }
    a {
        font-weight: $eFontWeightBold;
        font-family: $masterSecondaryFontFamily;
        padding: 5px 20px 5px 30px;
        font-size: $baseFontSize;
    }
}

.e-newsletter-popup-container {
    .e-newsletter-popup {
        padding: 1.50em 2em 2em 2em;
        p {
            font-size: 0.846em;
        }
        p.headline-h2 {
            font-size: 1.46em;
            font-weight: $eFontWeightBold;
            margin-top: 0 !important;
            color: $white !important;
        }
        form {
            margin-bottom: 0;
        }

        input[type="text"],
        input[type="email"] {
            @include box-sizing(border-box);
            @include border-radius(0);
            @include box-shadow(none);
            padding: 0 15px;
            border: 3px solid $grayLighter;
            outline: 3px solid transparent;
            @include transition(all 0.25s);
            outline-offset: -6px;
            min-height: 2.75em;
            width: 90%;
            &:active,
            &:focus {
                outline: 3px solid $success;
                border: 3px solid transparent;
                outline-offset: -3px;
                @include box-shadow(none);
            }
        }
    }
    .master-button--large {
        font-size: 1em;
    }

    .e-newsletter-popup-close {
        i {
            line-height: 1.35em;
        }
    }
}

.e-newsletter-popup-teaser {
    background-color: darken($grayDark, 5%);
    font-family: $headingsFontFamily;
    a {
        color: $white!important;
        &:hover {
            text-decoration: none;
        }
    }
    &:hover {
        background-color: darken($grayDark, 10%) !important;
    }
}
