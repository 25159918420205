$base: ".master-service-bookservice";
#{$base}-datepicker-panel,
#{$base}-timepicker-panel {
    position: absolute;
    @include box-sizing(border-box);
    top: -1px;
    right: -1px;
    z-index: 1050;
    width: 360px;
    border: 1px solid $baseBorderColor;
    line-height: 25px;
    box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.2);
    background: $white;
    padding: 1em;
    @media (max-width: 767px) {
        position: fixed;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        width: 100%;
    }
}

#{$base}-datepicker-panel {
    table {
        background: transparent;
        width: 100%;
        font-size: .9em;
        tr td {
            text-align: center;
            position: relative;
            padding: 8px 5px;
            border-bottom: 2px solid $white;
            z-index: 1;
            &.cw {
                background: $grayLighter;
            }
            &.day {
                cursor: pointer;
                &:hover::before {
                    content: "";
                    position: absolute;
                    width: 34px;
                    height: 34px;
                    border: 2px solid $success;
                    @include border-radius(50%);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                }
            }
            &.disabled.day {
                cursor: default;
                color: $baseBorderColor;
                &:hover::before {
                    border: none;
                }
            }
            &.active.day {
                color: $white;
                font-weight: $eFontWeightBold;
                &:before {
                    content: "";
                    position: absolute;
                    width: 34px;
                    height: 34px;
                    background: $success;
                    @include border-radius(50%);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                    -webkit-animation: fader 0.50s ease forwards;
                    animation: fader 0.50s ease forwards;
                    -webkit-animation-iteration-count: 1;
                    animation-iteration-count: 1;
                }
                &:after {
                    content: "";
                    position: absolute;
                    opacity: 0;
                    width: 50px;
                    height: 50px;
                    background: $success;
                    @include border-radius(50%);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -2;
                    @include transition(all .5s ease-in-out);
                    -webkit-animation: blooper 0.50s ease forwards;
                    animation: blooper 0.50s ease forwards;
                    -webkit-animation-iteration-count: 1;
                    animation-iteration-count: 1;
                }
            }
        }
    }
}

#{$base}-timepicker-panel {
    @include flexbox();
    @include flex(0 1 auto);
    @include flex-direction(row);
    @include flex-wrap(wrap);
    input[type="radio"] {
        margin-left: -9999em;
    }
    &:after {
        display: table;
        content: "";
        line-height: 0;
        clear: both;
    }
    strong {
        text-align: center;
        font-size: 0.9em;
        display: block;
        margin-bottom: 35px;
    }
    label.radio {
        @include flex(0 0 auto);
        @include flex-basis(25%);
        max-width: 25%;
        position: relative;
        text-align: center;
        padding-left: 0;
        line-height: 35px;
        min-height: 35px;
        @include box-sizing(border-box);
        @include transition(all 0.25s);
        //outline: 2px solid transparent;
        outline: none;
        box-shadow: 0px 0px 0px 2px transparent;
        outline-offset: -4px;
        margin: 1% 0;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        &:hover {
            @include transition(all 0.25s);
            font-weight: $eFontWeightBold;
            //outline: 2px solid $success;
            //outline-offset: 0px;
            outline: none;
            box-shadow: 0px 0px 0px 2px $success, 3px 3px 3px rgba(0, 0, 0, 0.3);
            z-index: 9;
        }
        &.active {
            background: $success;
            color: $white;
            font-weight: bold;
            border-top-color: $success;
            border-bottom-color: $success;
            &::before {
                content: '';
                background: $success;
                position: absolute;
                width: 5px;
                height: 100%;
                left: 0;
            }
        }
        /*
        &.spanslot {
            background: lighten($success, 40%);
            border-top-color: $success;
            border-bottom-color: $success;
            &.last-timeslot {
                &::after {
                    content: '';
                    background: $success;
                    position: absolute;
                    width: 5px;
                    height: 100%;
                    right: 0;
                }
            }
        }
        */
        &.disabled {
            color: lighten($grayLight, 15%);
            text-decoration: line-through;
            background: $grayLighter;
            &.out-of-scope {}
            &:hover {
                //outline: 2px solid $error;
                outline: none;
                box-shadow: 0px 0px 0px 2px $error, 3px 3px 3px rgba(0, 0, 0, 0.3);
                z-index: 9;
                cursor: not-allowed;
            }
            &.spanslot {
                //background: lighten($success, 50%);
            }
        }
        &.interval-15 {
            //width: 18%;
            &.timeslot-00,
            &.timeslot-30 {
                font-weight: bold;
            }
        }
        &.interval-30 {
            width: 23%;
        }
    }
}
