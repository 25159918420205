//
// Forms
// --------------------------------------------------
// GENERAL STYLES
// --------------
// Set font for forms
label,
input,
button,
select,
textarea {
    font-weight: $eFontWeightNormal
}
fieldset {
    margin-bottom: 1em;
}
legend {
    font-size: 1.5em;
    line-height: 1.15;
    font-weight: $eFontWeightNormal;
    letter-spacing: -0.025em;
    margin-top: 0;
    margin-bottom: 0.4285em;
    border-bottom-color: $baseBorderColor;
}
legend [class*="btn"] {
    position: relative;
    top: -.154em;
}
legend + .control-group {
    margin-top: $baseLineHeight / 2;
}
.control-group:last-child {
    margin-bottom: $baseLineHeight * 2;
}
// Identify controls by their labels
label {
    margin-bottom: 0.25em;
}
// Form controls
// -------------------------
input::-ms-clear {
    display: none;
}
// Shared size and type resets
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
    margin-bottom: $baseLineHeight / 3;
    padding: floor($baseLineHeight / 6) ceil($baseLineHeight / 4);
    color: $eInputTextColor;
    &.hide{
        display: none;
    }
}
// Everything else
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
    // Focus state
    &:focus {
        border-color: rgba($eInputFocusColor, 0.8);
        @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba($eInputFocusColor, 0.6));
    }
}
// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
    margin-top: 0.25em;
}
.control-group.error .checkbox,
.control-group.error .radio {
    @include box-shadow(none);
}
// INPUT SIZES
// -----------
// General classes for quick sizes
.e-input-micro {
    width: $gridColumnWidth / 2;
}
// INPUT GROUPS
// ------------
.input-append,
.input-prepend {
    .btn {
        padding: 4px 12px;
    }
}
// HORIZONTAL & VERTICAL FORMS
// ---------------------------
// Common properties
// -----------------
.form-vertical,
.form-horizontal {
    > fieldset {
        padding-right: 10%;
    }
}
.form-inline {
    input, textarea, select, .help-inline, .uneditable-input, .input-prepend, .input-append {
        margin-bottom: 0;
    }
}
.form-actions {
    padding-top: $baseLineHeight / 2;
    padding-bottom: 0;
    border-top: 0;
    background: none;
    &:last-child {
        margin-bottom: 0;
    }
}
// Vertical-specific styles
// --------------------------
.form-vertical {
    input, select, textarea {
        + .help-block {
            margin-top: -$baseLineHeight / 4;
        }
    }
}
.form-vertical label {
    margin-left: 0.125em;
    margin-bottom: 0.125em;
}
.form-vertical .control-group {
    margin-bottom: 0;
}
.form-vertical .controls label.checkbox {
    margin-bottom: 0.75em;
    line-height: 1.3;
}
.form-vertical .controls input + label.radio,
.form-vertical .controls input + label.checkbox {
    margin-top: -$baseLineHeight / 2;
    margin-bottom: 0;
}
.form-vertical .controls label.radio:first-child {
    margin-top: -$baseLineHeight / 6;
}
.form-vertical .controls label.radio:last-child {
    margin-bottom: $baseLineHeight / 1.5;
}
.form-vertical .form-actions {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
}
// Horizontal-specific styles
// --------------------------
.form-horizontal {
    .control-group {
        margin-bottom: $baseLineHeight / 3;
    }
    input,
    select,
    textarea {
        margin-bottom: 0;
        + .help-block {
            margin-top: $baseLineHeight / 6;
        }
    }
}
// DISABLED STATE
// --------------
// Disabled and read-only inputs
option[disabled],
option[readonly] {
    cursor: not-allowed;
    color: lighten( $eInputTextColor, 20%);
    text-decoration: line-through;
    background-color: transparent;
}
// FORM FIELD FEEDBACK STATES
// --------------------------
// Error
label.error {
    color: $errorText;
    @include formFieldState($errorText, $errorText, $errorBackground);
}
// HELP TEXT
// ---------
.help-block + .help-inline {
    padding-left: 0;
}
// INPUT GROUPS
// ------------
// Allow us to put symbols and text within the input field for a cleaner look
.input-append,
.input-prepend {
    .add-on {
        font-weight: $eFontWeightNormal;
    }
}
