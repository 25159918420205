.e-price {
    white-space: nowrap;
    font-weight: $eFontWeightNormal;
    color: $brandColor;
    margin-top: 0;
    margin-bottom: $baseLineHeight / 1.5;
}

.e-currency {
    font-size: .75em;
}

.e-before-price {
    display: block;
    margin-top: $baseLineHeight / 7;
    text-decoration: line-through;
}
