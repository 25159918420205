.e-map-list {
    line-height: 1.3;
    .e-map-list-item {
        margin-bottom: 1.75em;
        h3 {
            font-size: 1.25em;
            a {
                color: $linkColor;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        p {
            margin-bottom: 0.25em;
            a {
                color: inherit;
                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
.e-maps-search-marker-color {
    color: $linkColor
}
.e-maps-marker-color-1 {
    color: $brandColor
}
.e-maps-infowindow {
    padding-top: 0.33em;
}
