.flex-group {
    @include flexbox();
    &.flex-group-column {
        @include flex-direction(column);
    }
    &.flex-group-justify-space-around {
        @include justify-content(space-around);
    }
    .flex-group-item{
        margin:0;
    }
}
