// Variables
// Espresso variables to override the default look and feel of Bootstrap
// ---------------------------------------------------------------------
$eResponsiveBodyPadding: $gridGutterWidth / 2;

// GLOBAL VALUES
// -------------


@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');
$baseImagePath: '/Files/Templates/Designs/ftz-master/assets/img';


// Grays
// -------------------------
$black: black;
$white: white;
$gray: #555555;
$grayDarker: darken($gray, 20%);
$grayDark: darken($gray, 10%);
$grayLight: lighten($gray, 30%);
$grayLighter: lighten($gray, 60%);

// Accent colors
// -------------------------
$blue: #3498db;
$green: #1a9834;
$red: #c0392b;
$yellow: #ffd100;
$danger: $red;
$important: $danger;

// THEME COLORS

$brandColor: 			#333333;
$brandColorSecondary: 	#dddddd;
$info: 					#7ea4b2;
$success: 				#749e4f;
$warning: 				#ffd100;
$warningComplimentary: 	#ff7300;
$error: 				#c0392b;

//----------- DONT TOUCH ----------------------------//

$brandColorLight: lighten(desaturate($brandColor, 20%), 5%);
$brandColorLighter: lighten(desaturate($brandColor, 47%), 32%);
$brandColorLightest: lighten(desaturate($brandColor, 12%), 83%);

// METALS
//------------------------

$goldBg: #e5e2c7;
$goldBorder: #c9c190;

$silverBg: #f3f3f4;
$silverBorder: #ddd;

$bronzeBg: #f7dbcd;
$bronzeBorder: #edcfc0;

//------------------------


// CVI
// -------------------------
$brandColor: $brandColor;
$baseBorderRadius: 3px;
$boxBorderRadius: 3px;
$imageBorderRadius: 1px;
$btnBorderRadius: 2px;
$baseBorderColor: #e2e2e2;
$baseBackgroundColor: #f1f1f1;
$contentBackgroundColor: $white;

// SOCIAL MEDIA
//--------------------------
$facebook: #4367b1;


$workshop-badges: (
    webshop: #7a6750,
    booking: #50727a,
    both: #79a633,
    selected: $grayDark
);

// Scaffolding
// -------------------------
$textColor: #474747;
$textColorMuted: lighten($textColor, 20%);
$bodyBackground: $grayDark;

// Links
// -------------------------
$linkColor: $grayDark;
$linkColorHover: $linkColor;
$linkColorVisited: darken($linkColorHover, 5%);

// Typography
// -------------------------


$sansFontFamily: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$serifFontFamily: Georgia, "Times New Roman", Times, serif;
$defaultFontFamily: "Helvetica Neue", Helvetica, Arial, sans-serif;
$monoFontFamily: Menlo, Monaco, Consolas, "Courier New", monospace;
$masterFontFamily: $sansFontFamily;
$headingsFontFamily: $masterFontFamily;
$masterSecondaryFontFamily: "Roboto", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;


$baseFontSize: 16px;
$baseFontFamily: $sansFontFamily;
$baseLineHeight: 24px;

$eWebFontURL: "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600";
$eFontWeightLight: 300;
$eFontWeightNormal: 400;
$eFontWeightBold: 600;

// empty to use BS default, $baseFontFamily
$headingsColor: lighten($black,15%);

// empty to use BS default, $textColor
$headingsColorMuted: $textColor;

// Font Awesome
// -------------------------

// Tables
// -------------------------
// table and cell border
$tableBorder: $baseBorderColor;
// overall background-color
$tableBackground: $contentBackgroundColor;
// for hover
$tableBackgroundHover: darken($tableBackground, 3%);
// for striping
$tableBackgroundAccent: $tableBackgroundHover;
// for striping and hover
$eTableBackgroundAccentHover: darken($tableBackgroundAccent, 7%);

// Buttons
// -------------------------
$btnBackground: $white;
$btnBackgroundHighlight: darken($white, 10%);
$btnBorder: $grayLight;

$btnPrimaryBackground: $linkColor;
$btnPrimaryBackgroundHighlight: darken($btnPrimaryBackground, 10%);

$btnInfoBackground: lighten($info, 15%);
$btnInfoBackgroundHighlight: $info;

$btnSuccessBackground: lighten($success, 15%);
$btnSuccessBackgroundHighlight: $success;

$btnWarningBackground: lighten($warning, 15%);
$btnWarningBackgroundHighlight: $warning;

$btnDangerBackground: lighten($danger, 15%);
$btnDangerBackgroundHighlight: $danger;

$btnInverseBackground: $gray;
$btnInverseBackgroundHighlight: $grayDarker;

// Forms
// -------------------------
$inputBackground: $white;
$inputBorder: darken($baseBorderColor, 10%);
$inputBorderRadius: $baseBorderRadius + 1;
$formActionsBackground: whitesmoke;
$inputHeight: $baseLineHeight + 10px;

// base line-height + 8px vertical padding + 2px top/bottom border
$eInputTextColor: $textColor;
$eInputFocusColor: $linkColor;
$eFormActionsBorder: $baseBorderColor;

// Dropdowns
// -------------------------
$dropdownBackground: $white;
$dropdownBorder: darken($baseBorderColor, 20%);
$dropdownDividerTop: $grayLighter;
$dropdownDividerBottom: $white;

$dropdownLinkColor: $textColor;
$dropdownLinkColorHover: $textColor;
$dropdownLinkColorActive: white;

$dropdownLinkBackgroundActive: $brandColor;
$dropdownLinkBackgroundHover: $baseBackgroundColor;

// COMPONENT VARIABLES
// --------------------------------------------------

// Hr border color
// -------------------------
$hrBorder: $baseBorderColor;

// Horizontal forms & lists
// -------------------------
$horizontalComponentOffset: $gridColumnWidth * 2 + $gridGutterWidth * 2;

// Wells
// -------------------------
$wellBorderColor: $baseBorderColor;
$wellBackgroundColor: $baseBackgroundColor;

// Navbar
// -------------------------
$navbarCollapseWidth: 979px;
$navbarCollapseDesktopWidth: $navbarCollapseWidth + 1;

$navbarHeight: 44px;
$navbarBackgroundHighlight: $baseBackgroundColor;
$navbarBackground: $baseBackgroundColor;
$navbarBorder: $baseBorderColor;

$navbarText: $textColor;
$navbarLinkColor: $textColorMuted;
$navbarLinkColorHover: black;
$navbarLinkColorActive: $brandColor;
$navbarLinkBackgroundHover: transparent;
$navbarLinkBackgroundActive: transparent;

$navbarBrandColor: $navbarLinkColor;

// Inverted navbar
$navbarInverseBackground: $brandColor;
$navbarInverseBackgroundHighlight: $bodyBackground;
$navbarInverseBorder: black;

$navbarInverseText: $white;
$navbarInverseLinkColor: $navbarInverseText;
$navbarInverseLinkColorHover: $brandColorSecondary;
$navbarInverseLinkColorActive: $brandColorSecondary;
$navbarInverseLinkBackgroundHover: transparent;
$navbarInverseLinkBackgroundActive: transparent;

$navbarInverseSearchBackground: lighten($navbarInverseBackground, 25%);
$navbarInverseSearchBackgroundFocus: $white;
$navbarInverseSearchBorder: $navbarInverseBackground;
$navbarInverseSearchPlaceholderColor: #cccccc;

$navbarInverseBrandColor: $navbarInverseLinkColor;

// Pagination
// -------------------------
$paginationBackground: $contentBackgroundColor;
$paginationBorder: $baseBorderColor;
$paginationActiveBackground: $baseBackgroundColor;

// Utility navigation
// -------------------------
$navUtilityBackground: $baseBackgroundColor;
$navUtilityBorder: $baseBorderColor;
$navUtilityLinkColor: lighten($textColor, 15%);

// Top navigation
// -------------------------
$navTopBackground: $baseBackgroundColor;
$navTopLinkColor: $textColor;

// Left navigation
// -------------------------
$navLeftBackground: $white;
$navLeftBackgroundHover: $white;
$navLeftBackgroundActive: $brandColor;
$navLeftLinkColor: $textColorMuted;
$navLeftLinkColorHover: black;
$navLeftLinkColorActive: $white;
$navLeftLinkColorInpath: $brandColor;
$navLeftBorderColor: $baseBorderColor;

// Form states and alerts
// -------------------------
$warningText: $warning;
$warningBackground: desaturate(lighten($warning, 40), 7%);
$warningBorder: darken(adjust-hue($warningBackground, -10), 15%);

$errorText: $error;
$errorBackground: desaturate(lighten($errorText, 40), 7%);
$errorBorder: darken(adjust-hue($errorBackground, -10), 15%);

$successText: $success;
$successBackground: desaturate(lighten($successText, 40), 10%);
$successBorder: darken(adjust-hue($successBackground, -10), 15%);

$infoText: $info;
$infoBackground: desaturate(lighten($infoText, 40), 7%);
$infoBorder: darken(adjust-hue($infoBackground, -10), 15%);

$brandedText: $brandColor;
$brandedBackground: desaturate(lighten($brandedText, 45), 5%);
$brandedBorder: darken(adjust-hue($brandedBackground, -10), 15%);

// Tooltips and popovers
// -------------------------
$tooltipColor: white;
$tooltipBackground: black;
$tooltipArrowWidth: 5px;
$tooltipArrowColor: $tooltipBackground;

$popoverBackground: white;
$popoverArrowWidth: 10px;
$popoverArrowColor: white;
$popoverTitleBackground: darken($popoverBackground, 3%);

// Special enhancement for popovers
$popoverArrowOuterWidth: $popoverArrowWidth + 1;
$popoverArrowOuterColor: rgba(0, 0, 0, 0.25);
