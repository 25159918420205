$base: '.carpeople-dealer-search';
#{$base} {
    @include box-sizing(border-box);
    padding: 1.5em;
    position: relative;
    border: 2px solid $baseBorderColor;
    background: $grayLighter;
    input {
        @include border-radius(0);
        @include box-shadow(none);
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
        padding-left: 1em;
        @include transition(all, 0.15s);
        width: 100%;
    }
    &:before {
        margin-left: -50px;
    }
    button {
        position: absolute;
        border: 0;
        display: block;
        font-size: 15px;
        text-align: center;
        line-height: 3.5em;
        min-height: 3.5em;
        right: 1.5em;
        top: 1.5em;
        background: $success;
        color: $new-text-color;
        font-weight: $eFontWeightBold;
        i {
            font-size: 30px;
            vertical-align: -10px;
        }
        &:hover {
            @include transition(all, 0.15s);
            text-decoration: none;
            background: darken($success, 5%);
        }
        // RESPONSIVE
        @media (max-width: 767px) {
            position: relative;
            top: auto;
            right: auto;
        }
        @media (min-width: 768px) and (max-width: 979px) {
            width: 30%;
        }
        @media (min-width: 980px) {
            width: 25%;
        }
        +a {
            margin-top: 5px;
        }
    }
    &.form-input--large {
        input:focus:invalid:focus {
            border-color: transparent;
            @include box-shadow(none);
        }
        // TYPEAHEAD
        ul.typeahead.nav.dropdown-menu {
            padding: 0;
            @include box-shadow(none);
            @include border-radius(0);
            border-top-width: 0;
            li.active a {
                background-color: $gray;
            }
            li a:hover,
            li.active a:hover {
                background-color: $success;
                color: $white;
                @include transition(all, 0.15s);
            }
        }
    }
}

#{$base}-close-button {
    color: $white;
    position: absolute;
    top: 10px;
    right: 10px;
    .material-icons {
        font-size: 1.5em;
        font-weight: $eFontWeightBold;
    }
    &:hover {
        color: $white;
    }
}
