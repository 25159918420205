.e-ecom-grid {
    line-height: 1.2;
}
.e-ecom-grid ul {
    margin: 0;
    padding: 0;
    list-style: none;
    @include clearfix;
}
.e-ecom-grid ul li {
    text-align: center;
    width: 25%;
    height: 210px;
    float: left;
    padding: $baseLineHeight / 1.5 $gridGutterWidth / 1.5 $baseLineHeight / 2;
    margin: 0 0 -1px 0;
    border-width: 0 1px 1px 0;
    border-color: $baseBorderColor;
    border-style: solid;
    @include box-sizing(border-box);
    position: relative;
    z-index: 1;
}
.e-ecom-grid ul li:hover {
    z-index: 3;
    outline: 2px solid $linkColor;
    outline-offset: -1px;
    border-color: $contentBackgroundColor;
    background: $contentBackgroundColor;
}
/* use uncle dave's old padding box to create intrinsic ratio on image containers preventing them from jumping around if images have different heights */

.e-ecom-grid ul li > div:first-child {
    position: relative;
    padding-top: 75%;
    height: 0;
    margin-bottom: 0.66em;
}
.e-ecom-grid ul li > div:first-child > a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    text-align: center;
}
.e-ecom-grid ul li > div:first-child > a > img {
    position: absolute;
    display: inline-block;
    right: 0;
    left: 0;
    max-height: 100%;
    max-width: 100%;
    margin: 0 auto;
}
.e-ecom-grid ul li h1,
.e-ecom-grid ul li h2,
.e-ecom-grid ul li h3,
.e-ecom-grid ul li h4 {
    font-size: inherit;
    line-height: inherit;
    font-weight: $eFontWeightBold;
    margin-top: 0;
    margin-bottom: 0.125em;
}
.e-ecom-grid ul li p {
    margin-bottom: 0.125em;
}
.e-ecom-grid ul li a {
    color: inherit;
    text-decoration: none;
}
.e-ecom-grid ul li .e-price {
    font-size: 1.25em;
    display: block;
    margin: $baseLineHeight / 5 0 0;
}
/* all containers */

@media (min-width: 980px) {
    .e-ecom-grid ul li {} .e-ecom-grid ul li:nth-child(4n) {
        border-right-width: 0;
    }
}
@media (min-width: 769px) and (max-width: 979px) {
    .e-ecom-grid ul li {
        width: 33.33%;
    }
    .e-ecom-grid ul li:nth-child(3n) {
        border-right-width: 0;
    }
}
@media (min-width: 640px) and (max-width: 768px) {
    .e-ecom-grid ul li {
        width: 25%;
    }
    .e-ecom-grid ul li:nth-child(4n) {
        border-right-width: 0;
    }
}
@media (min-width: 481px) and (max-width: 640px) {
    .e-ecom-grid ul li {
        height: 220px;
        width: 33.33%;
    }
    .e-ecom-grid ul li:nth-child(3n) {
        border-right-width: 0;
    }
}
@media (min-width: 320px) and (max-width: 480px) {
    .e-ecom-grid ul li {
        height: 215px;
        width: 50%;
    }
    .e-ecom-grid ul li:nth-child(2n) {
        border-right-width: 0;
    }
}
@media (max-width: 319px) {
    .e-ecom-grid ul li {
        width: 100%;
        height: 120%;
        border-right-width: 0;
    }
}
/* all containers - end */
/* small containers: span3 - span1 and span2 not applicable */

@media (min-width: 768px) {
    .span3 {
        > .e-ecom-grid ul li {
            width: 100%;
            height: 220px;
            border-right-width: 0;
        }
    }
}
/* small containers - end */
/* medium containers: span4 */

@media (min-width: 768px) {
    .span4,
    .span5 {
        > .e-ecom-grid ul li {
            height: 215px;
            width: 50%;
            border-right-width: 1px;
        }
        > .e-ecom-grid ul li:nth-child(2n) {
            border-right-width: 0;
        }
    }
}
/* medium containers - end */
/* large containers: span12 */

@media (min-width: 981px) {
    .span12 {
        > .e-ecom-grid ul li {
            width: 20%;
            height: 220px;
            border-right-width: 1px;
        }
        > .e-ecom-grid ul li:nth-child(5n) {
            border-right-width: 0;
        }
    }
}
@media (min-width: 769px) and (max-width: 980px) {
    .span12 {
        > .e-ecom-grid ul li {
            width: 25%;
            height: 210px;
            border-right-width: 1px;
        }
        > .e-ecom-grid ul li:nth-child(4n) {
            border-right-width: 0;
        }
    }
}
/* large containers - end */
