.master-utility-scroll-to-top {
    position: fixed;
    bottom: -5em;
    right: 1.6em;
    z-index: 11;
    padding: 0.75em;
    width: 1.5em;
    height: 1.5em;
    text-align: center;
    line-height: 1.5em;
    font-size: 1.5em;
    @include border-radius(50%);
    @include transition(all .3s ease);
    color: $white;
    background: rgba($grayDark, .5);
    cursor: pointer;
    i {
        font-size: 1.5em;
    }
    &.is-visible {
        bottom: 2em;
    }
    &:hover {
        //bottom: 1.7em;
    }
}
