.nav,
.nav ol,
.nav ul,
.nav li {
    list-style: none;
    margin: 0;
}
.nav li a {
    display: block;
}
.nav > li > a:hover {
    background: none;
}
.nav-tabs > li > a,
.nav-pills > li > a {
    padding-left: $gridGutterWidth / 1.5;
    padding-right: $gridGutterWidth / 1.5;
}
// NAV LIST
// --------
.nav-list {
    padding-left: $gridGutterWidth / 1.5;
    padding-right: $gridGutterWidth / 1.5;
}
.nav-list > li > a,
.nav-list .nav-header {
    padding: $baseLineHeight / 6 $gridGutterWidth / 1.5;
}
.nav li + .nav-header {
    margin-top: $baseLineHeight / 2;
    margin-bottom: $baseLineHeight / 6;
}
// DROPDOWNS
// ---------
.nav-tabs .dropdown-menu {
    @include border-radius(0 0 $boxBorderRadius $boxBorderRadius); // remove the top rounded corners here since there is a hard edge above the menu
}
.nav-pills .dropdown-menu {
    @include border-radius($boxBorderRadius); // make rounded corners match the pills
}
// TABS
// ----
.nav-tabs {
    border-bottom-color: $baseBorderColor;
}
.nav-tabs > li {
    margin-bottom: -1px;
}
// Actual tabs (as links)
.nav-tabs > li > a {
    font-size: 0.846em;
    color: $linkColor;
    padding: .5em 1.25em;
    margin-top: 0.32em;
    background-color: $baseBackgroundColor;
    border-color: $baseBorderColor $baseBorderColor $baseBorderColor;
    @include border-radius($boxBorderRadius $boxBorderRadius 0 0);
    &:hover, &:focus {
        padding: .66em 1.25em;
        margin-top: 0;
        color: $linkColor;
        background-color: $baseBackgroundColor;
        border-color: $baseBorderColor;
        i {
            color: $textColor;
        }
    }
    i {
        color: $textColorMuted;
    }
}
// Active state, and it's :hover/:focus to override normal :hover/:focus
.nav-tabs > .active > a,
.nav-tabs > .active > a:hover,
.nav-tabs > .active > a:focus {
    margin-top: 0;
    padding: .66em 1.25em;
    font-weight: $eFontWeightBold;
    color: $headingsColor;
    background-color: $contentBackgroundColor;
    border-color: $baseBorderColor;
    border-bottom-color: transparent;
    text-decoration: none;
    i {
        color: inherit;
    }
}
.nav-tabs.nav-stacked > li > a {
    border-color: $baseBorderColor;
}
.nav-tabs.nav-stacked > li > a:hover {
    border-color: $baseBorderColor;
}
.nav-tabs.nav-stacked > li:first-child > a {
    @include border-top-radius($boxBorderRadius);
}
.nav-tabs.nav-stacked > li:last-child > a {
    @include border-bottom-radius($boxBorderRadius);
}
.tabs-below > .nav-tabs {
    border-top-color: $baseBorderColor;
}
.tabs-below > .nav-tabs > li > a {
    &:hover {
        border-top-color: $baseBorderColor;
    }
}
.tabs-below > .nav-tabs > .active > a,
.tabs-below > .nav-tabs > .active > a:hover {
    border-color: transparent $baseBorderColor $baseBorderColor $baseBorderColor;
}
.tabs-left > .nav-tabs {
    border-right-color: $baseBorderColor;
}
.tabs-left > .nav-tabs > li > a:hover {
    border-color: $baseBorderColor $baseBorderColor $baseBorderColor $baseBorderColor;
}
.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover {
    border-color: $baseBorderColor transparent $baseBorderColor $baseBorderColor;
}
.tabs-right > .nav-tabs {
    border-left-color: $baseBorderColor;
}
.tabs-right > .nav-tabs > li > a:hover {
    border-color: $baseBorderColor $baseBorderColor $baseBorderColor $baseBorderColor;
}
.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover {
    border-color: $baseBorderColor $baseBorderColor $baseBorderColor transparent;
}
.tab-content {
    border: 1px solid $baseBorderColor;
    border-top: 0;
    @include border-radius(0 $boxBorderRadius $boxBorderRadius $boxBorderRadius);
    margin-bottom: 1.5em;
    padding: 1.154em 0.846em;
}
