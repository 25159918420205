/* WEBKIT SCROLLBAR */

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    &:hover {
        background: rgba(0, 0, 0, 0.45);
    }
    &:active {
        background: rgba(0, 0, 0, 0.7);
    }
}