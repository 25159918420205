/*
//
// Wells
// --------------------------------------------------

// Base class
*/

.well {
    min-height: $baseLineHeight;
    padding: $gridGutterWidth;
    margin-bottom: $baseLineHeight;
    background-color: $wellBackgroundColor;
    border-color: $wellBorderColor;
    @include border-radius($boxBorderRadius);
    @include box-shadow(none);
    .bleed, .e-panel-cap {
        margin-left: -$gridGutterWidth;
        margin-right: -$gridGutterWidth;
    }
    .e-panel-cap {
        margin-top: -$gridGutterWidth;
        margin-bottom: $gridGutterWidth * 1.154;
        padding-left: $gridGutterWidth;
        padding-right: $gridGutterWidth;
    }
}
/*
// Sizes
*/

.well-large {
    padding: $gridGutterWidth * 1.5;
    @include border-radius($boxBorderRadius * 1.5);
    .bleed, .e-panel-cap {
        margin-left: -$gridGutterWidth * 1.5;
        margin-right: -$gridGutterWidth * 1.5;
    }
    .e-panel-cap {
        margin-top: -$gridGutterWidth * 1.5;
        margin-bottom: $gridGutterWidth * 1.5 * 1.154;
        padding-left: $gridGutterWidth* 1.5;
        padding-right: $gridGutterWidth* 1.5;
    }
}
.well-small {
    padding: $gridGutterWidth * 0.5;
    @include border-radius($boxBorderRadius * 0.5);
    .bleed, .e-panel-cap {
        margin-left: -$gridGutterWidth * 0.5;
        margin-right: -$gridGutterWidth * 0.5;
    }
    .e-panel-cap {
        margin-top: -$gridGutterWidth * 0.5;
        margin-bottom: $gridGutterWidth * 0.5 * 1.154;
        padding-left: $gridGutterWidth* 0.5;
        padding-right: $gridGutterWidth* 0.5;
    }
}
.well-transparent {
    background-color: transparent;
}
