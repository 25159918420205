.e-top {
    background-color: $contentBackgroundColor;
}
.e-top .row,
.e-top .row-fluid {
    margin-bottom: $baseLineHeight / 2;
}
.e-top > .row:last-child {
    margin-bottom: 0;
}
.e-top > .row:last-child [class*="span"] > .e-img:last-child .large,
.e-top > .row:last-child [class*="span"] > .e-img:last-child .mega {
    margin-bottom: $baseLineHeight * 2;
}
@media (max-width: 767px) {
    .e-top {}
}
