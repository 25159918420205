
:root {
    --webshop-badges-both: map-get($workshop-badges, 'both');
    --webshop-badges-booking: map-get($workshop-badges, 'booking');
    --webshop-badges-webshop: map-get($workshop-badges, 'webshop');
    --webshop-badges-selected: map-get($workshop-badges, 'selected');
    --brand-primary: $brandColor;
    --brand-secondary: $brandColorSecondary;
    --brand-info: $info;
    --brand-success: $success;
    --brand-warning: $warning;
    --brand-warning-secondary: $warningComplimentary;
    --brand-error: $error;
    --booking-selected-timeslots: 0;
}
