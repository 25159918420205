.e-newsletter-popup-container {
    position: fixed;
    display: none;
    background: $brandColor;
    right: 0;
    border-bottom-width: 0;
    margin-top: $baseLineHeight / 2;
    margin-left: $baseLineHeight / 2;
    min-width: 100px;
    font-weight: $eFontWeightNormal;
    z-index: $zindexDropdown - 1;
    @include border-radius($baseBorderRadius 0 0 0);
    @include transition(all .3s cubic-bezier(0.455, 0.030, 0.515, 0.955));
    @include box-shadow(-1px -1px 5px 0px rgba($black, .3));
    div[class*="span"]{
        width: 100%;
        float: none;
        margin-left: 0;
    }
    .e-newsletter-popup {
        padding: 20px 20px 20px 30px;
        float: left;
        max-width: 375px;
        .headline-h2 {
            @extend h2;
            color: $white;
            margin-top: 5px;
        }
        p {
            color: $white;
        }
        img {
            position: absolute;
            right: 0;
            bottom: 0;
        }
        .e-newsletter-comfirm-msg {
            a {
                display: block;
                text-decoration: underline;
                color: $white;
            }
        }
    }
    form {
        margin: 20px 0 10px;
        #e-newsletter-popup-form-submit {
            display: block;
            margin-top: 10px;
        }
        input[type="text"], input[type="email"]{
            width: 60%;
            &.text-error{
                background-color: $errorBackground;
            }
        }
        span.text-error{
            position: absolute;
            font-size: .7em;
            text-shadow: 1px 1px 1px rgba($black, .3);
            margin: 5px;
            color: $white;
            z-index: 2;
            vertical-align: top;
            &:before{
                font-family: FontAwesome;
                content: '\f06a';
                padding: 0 6px 0 3px;
                font-size: 1.3em;
            }
        }
    }
    .e-newsletter-popup-close {
        text-decoration: none;
        position: absolute;
        font-size: 20px;
        display: block;
        line-height: 28px;
        text-align: center;
        width: 30px;
        height: 30px;
        top: -15px;
        left: -15px;
        color: $white;
        border: 2px solid $white;
        background: $brandColor;
        @include border-radius(50%);
        @include box-shadow(0px 0px 5px 0px rgba($black, .3));
        @include transition(all .2s ease-in-out);
        &:hover {
            background: lighten($brandColor, 5%);
        }
    }
}
.e-newsletter-popup-teaser {
    position: fixed;
    display: none;
    background: $brandColor;
    border-bottom-width: 0;
    right: 0;
    bottom: 0;
    z-index: $zindexDropdown - 2;
    @include border-radius($baseBorderRadius 0 0 0);
    @include transition(all .2s cubic-bezier(0.455, 0.030, 0.515, 0.955));
    @include box-shadow(-1px -1px 5px 0px rgba($black, .3));
    &:hover {
        background: lighten($brandColor, 5%);
        padding-bottom: 5px;
        img {
            bottom: 0px;
        }
    }
    a {
        color: $white;
        text-decoration: none;
        padding: 10px 30px;
        float: left;
        margin: 0;
    }
    img {
        position: absolute;
        bottom: -5px;
        right: 0;
        @include transition(all .2s cubic-bezier(0.455, 0.030, 0.515, 0.955));
    }
}
.e-newsletter-popup-loader {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($black, .4);
    z-index: 5;
    span {
        @include e-loading-spinner();
        position: absolute;
        top: 50%;
        left: 50%;
    }
}
@media (max-width: 480px) {
    .e-newsletter-popup-container {
        width: 100%;
        padding-right: 0 !important;
        @include border-radius(0);
        .e-newsletter-popup-close {
            left: auto;
            right: 15px;
        }
        img {
            display: none;
        }
        form #e-newsletter-popup-form-submit {
            margin: 0 auto;
        }
        .e-newsletter-popup {
            max-width: 100%;
            text-align: center;
        }
    }
    .e-newsletter-popup-teaser {
        width: auto;
        right: 5px;
        padding-right: 0 !important;
        @include border-radius(20px 20px 0 0);
        a {
            padding: 5px 20px;
        }
        img {
            display: none;
        }
    }
}
