.pager,
.pagination {
    margin: 0;
}
.pagination ul {
    margin: 0 0 $baseLineHeight / 3;
    @include box-shadow(none);
}
//Overskriv DW's news list paging
.pagination b {
    font-weight: inherit;
}
// Small and mini
.pagination-mini,
.pagination-small {
    ul > li:first-child > a, ul > li:first-child > span {
        @include border-left-radius($boxBorderRadius);
    }
    ul > li:last-child > a,
    ul > li:last-child > span {
        @include border-right-radius($boxBorderRadius);
    }
}
// Small
.pagination-small {
    ul > li > a, ul > li > span {
        font-size: 0.846em;
    }
}
