/* Espresso images */
$eImagePolaroidBackgroundColor:#f7f8f0;
[class*="span"] > .e-img {
    display: block;
    @include clearfix;
}
[class*="span"] > h1 + .e-img > img,
[class*="span"] > h2 + .e-img > img,
[class*="span"] > h3 + .e-img > img,
[class*="span"] > h4 + .e-img > img,
[class*="span"] > h5 + .e-img > img,
[class*="span"] > h6 + .e-img > img {
    margin-top: $baseLineHeight / 3;
}
[class*="span"] > .e-img > img.left {
    float: left;
}
[class*="span"] > .e-img > img.right {
    float: right;
}
[class*="span"] > .e-img > img.center {
    float: none;
    display: block;
    margin: 0 auto;
}
.e-rounded,
[class*="span"] > .e-img > img,
[class*="span"] > .e-img.rounded > img {
    @include border-radius($imageBorderRadius);
}
.e-donotround,
[class*="span"] > .e-img > img.donotround {
    @include border-radius(0);
}
[class*="span"] > .e-img > img.zoom {
    @include transition(all 0.1s ease-in-out 0s);
}
[class*="span"] > .e-img > img.zoom:hover {
    @include scale(1.075);
}
.e-shadow,
[class*="span"] > .e-img > img.shadow {
    @include box-shadow(0 1px 3px rgba(0, 0, 0, 0.35));
}
.e-border,
[class*="span"] > .e-img > img.border {
    display: block;
    background-color: $white;
    @include border-radius($imageBorderRadius);
    padding: floor($baseLineHeight / 4);
    border: 1px solid $baseBorderColor;
    @include box-sizing(border-box);
}
.e-shadow.e-border,
[class*="span"] > img.shadow.border,
[class*="span"] > .e-img > img.shadow.border {
    @include box-shadow(0 1px 3px rgba(0, 0, 0, 0.15));
}
.e-circle,
[class*="span"] > .e-img > img.circle {
    @include border-radius(50%);
}
.e-circle.e-border,
[class*="span"] > .e-img > img.circle.border {
    border: 0;
    @include box-shadow(0 0 2px rgba(0, 0, 0, 0.3) inset);
}
.e-polaroid,
[class*="span"] > .e-img > img.polaroid {
    margin: 0 auto;
    display: block;
    width: 90%;
    padding: 3% 3% 16%;
    @include box-sizing(border-box);
    @include gradient-vertical($eImagePolaroidBackgroundColor, desaturate(darken($eImagePolaroidBackgroundColor, 2%), 8%));
    @include box-shadow(0px 2px 5px rgba(0, 0, 0, 0.2), 0px -2px 3px -2px rgba(0, 0, 0, 0.1));
}
[class*="span12"] > .row > [class*="span"] > .e-img > img.bleedright {
    margin-right: $eResponsiveBodyPadding * 1;
    margin-left: -$eResponsiveBodyPadding * 1;
    @media (min-width: 768px) {
        margin-right: $gridGutterWidth * 1;
        margin-left: -$gridGutterWidth * 1;
    }
    @media (min-width: 1240px) {
        margin-right: $gridGutterWidth * 3;
        margin-left: -$gridGutterWidth * 3;
    }
    @media (min-width: 1320px) {
        margin-right: $gridGutterWidth * 4;
        margin-left: -$gridGutterWidth * 4;
    }
    @media (min-width: 1640px) {
        margin-right: $gridGutterWidth * 5;
        margin-left: -$gridGutterWidth * 5;
    }
}

@include images-bleed(8, 12);
@include images-bleed-full(8, 12);
@include images-bleed-full(12, 12);

.e-img + .table tbody tr:first-child td {
    border-top: 0;
}
