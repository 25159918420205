$ePanelFocusBackground: #ffffcc;
.e-panel {
    font-size: 0.846em;
    line-height: 1.6;
    border: 1px solid $baseBorderColor;
    @include border-radius($boxBorderRadius);
    margin-bottom: 1.5em;
    hr {
        //margin-bottom: 0.5em;
    }
    li, dt, dd {
        line-height: inherit;
    }
    &.e-panel-focus {
        font-size: 1em;
    }
}
/*
udlign afstand i toppen af content p├Ñ e-panel hvis det ligger som f├©rst element i en container med klassen e-column der tilf├©jer padding og border i toppen til at lave t├©rresnoren
*/

.e-column > .row:first-child >[class*="span"]:first-child > .pidlink + .e-panel {
    margin-top: -(floor($baseFontSize * 0.846) + 1);
    @include border-radius(0 0 $boxBorderRadius $boxBorderRadius);
}
.e-panel-focus {
    border-color: rgba(0, 0, 0, 0.1);
    background: $ePanelFocusBackground;
    hr {
        border-color: rgba(0, 0, 0, 0.1);
        margin-bottom: $baseLineHeight / 1.5;
        margin-top: $baseLineHeight / 1.5;
    }
}
.e-label {
    color: $headingsColor;
    font-size: ceil($baseFontSize * 0.846);
    font-weight: $eFontWeightBold;
    margin-bottom: 0;
    input[type="radio"], input[type="checkbox"] {
        margin-top: ceil($baseLineHeight / 3.846) - 1.5;
    }
}
.e-panel-cap {
    min-height: 1.2em;
    font-size: ceil($baseFontSize * 0.846);
    border-bottom: 1px solid $baseBorderColor;
    background: $baseBackgroundColor;
    padding: $baseLineHeight / 2 $gridGutterWidth / 2;
    @include border-radius($boxBorderRadius $boxBorderRadius 0 0);
}
.e-panel-cap h1,
.e-panel-cap h2,
.e-panel-cap h3,
.e-panel-cap h4,
.e-panel-cap p {
    @extend .e-label
}
.e-panel-cap h1,
.e-panel-cap h2,
.e-panel-cap h3,
.e-panel-cap h4,
.e-panel-cap p,
.e-panel-cap form,
.e-panel-cap form label {
    margin: 0;
    padding: 0;
    line-height: 1.2;
}
.e-panel-cap form {
    height: 1.2em;
}
.e-panel-cap form select {
    font-size: ceil($baseFontSize * 0.846);
    margin-top: -0.25em;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    line-height: 1.6;
    height: 1.6em;
    min-height: 1.6em;
    width: 100%;
    display: inline-block;
}
.e-panel-cap .row-fluid [class*="span"] {
    min-height: 0;
}
.e-panel-cap {
    &.e-panel-cap-focus {
        text-align: center;
        color: $white;
        background: $brandColor;
        position: relative;
        border: 1px solid darken($brandColor, 10%);
        margin: -1px;
        @include border-radius($boxBorderRadius $boxBorderRadius 0 0);
        &:before {
            content: '';
            display: inline-block;
            border-left: ceil($baseLineHeight / 2) + 2 solid transparent;
            border-right: ceil($baseLineHeight / 2) + 2 solid transparent;
            border-top: ceil($baseLineHeight / 2) + 2 solid darken($brandColor, 10%);
            border-top-color: darken($brandColor, 10%);
            position: absolute;
            bottom: -(ceil($baseLineHeight / 2 + 2));
            left: 50%;
            margin-left: -(ceil($baseLineHeight / 4 + 2));
        }
        &:after {
            content: '';
            display: inline-block;
            border-left: ceil($baseLineHeight / 2) solid transparent;
            border-right: ceil($baseLineHeight / 2) solid transparent;
            border-top: ceil($baseLineHeight / 2) solid $brandColor;
            position: absolute;
            bottom: -(ceil($baseLineHeight / 2));
            left: 50%;
            margin-left: -(ceil($baseLineHeight / 4));
        }
        .e-label {
            color: inherit;
        }
    }
}
