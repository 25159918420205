.master-modal-product-added {
    position: fixed;
    top: 50%;
    left: -100%;
    width: 200px;
    height: 195px;
    transform: translate(-50%, -50%);
    background: $success;
    border-bottom: 5px solid darken($success, 5%);
    @include border-radius(10px);
    @include box-sizing(border-box);
    text-align: center;
    padding: 0.5em 2em 0 2em;
    color: $brandColor;
    font-weight: $eFontWeightBold;
    @include animate('addtocart 3s ease-in-out');
    i {
        display: block;
        font-size: 7em;
    }
}