$base: ".master-webshop-menu-top-left";
#{$base}-menu {
    // GENERAL
    .row-fluid [class*="span9"],
    .row-fluid [class*="span3"],
    .row-fluid [class*="span12"] {
        margin-left: 0;
    }
    .row-fluid [class*="span3"] {
        width: 25%;
    }
    // MOBILE
    @media (min-width: 768px) {
        .row-fluid [class*="span9"] {
            width: 75%;
        }
    }
    .e-panel {
        @include border-radius(0);
        border: none;
    }
    .tab-content {
        @include border-radius(0);
        padding: 0;
        border: none;
        overflow: hidden;
    }
    .e-nav-filter-tabs {
        border-bottom: 1px solid $brandColor;
        li {
            width: 50%;
            a {
                @include border-radius(0);
                border: none;
                margin-right: 0;
                margin-top: 0;
                padding: 9px 10px 8px;
                color: $brandColor;
                font-weight: $eFontWeightBold;
                background: $grayLighter;
                i {
                    font-size: 2em;
                }
                &:hover {
                    @include transition(all, 0.15s);
                    background: $success;
                    color: $white;
                }
            }
            &.active a {
                background: $brandColor;
                color: $white;
                position: relative;
                &:after {
                    top: 100%;
                    left: 50%;
                    border: solid transparent;
                    content: " ";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-color: transparent;
                    border-top-color: $brandColor;
                    border-width: 10px;
                    margin-left: -10px;
                }
                &:hover {
                    border: none;
                    padding: 9px 10px 8px;
                }
            }
        }
    }
    // LEFT MENU
    .e-nav-local-left,
    .accordion-inner {
        > .nav > li {
            border-width: 1px 0;
            &:first-child {
                border-top: none;
            }
            a {
                font-size: $eNavLocalLeftFontSize * 1;
                color: $textColor;
                line-height: 1em;
                padding-top: 1em;
                padding-bottom: 1em;
                padding-right: 1em;
                @include border-radius(0px);
            }
            > a {
                padding-left: 1em;
                &:hover {
                    @include transition(all 0.15s);
                    background: $grayLighter;
                }
            }
            &.active > a {
                position: relative;
                color: $white;
                background-color: $brandColor;
            }
        }
        a img,
        a svg {
            display: block;
            float: left;
            margin-right: 1em;
            height: 26px;
            width: 26px;
            margin-top: -6px;
        }
    }
    // FILTERS
    .e-productlist-filters {
        li fieldset {
            margin: 0 -1em 0;
            label.checkbox {
                padding-left: 0;
                margin: 0;
                min-height: 26px;
                line-height: 26px;
                border-bottom: 1px solid $baseBorderColor;
                &:first-of-type {
                    border-top: 1px solid $grayLighter;
                }
                input {
                    display: none;
                }
                p {
                    position: relative;
                    margin-bottom: 0;
                    padding-left: 2em;
                    padding-right: 55px;
                    small {
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        width: 50px;
                        background: $grayLighter;
                        min-height: 26px;
                        line-height: 26px;
                        @include flexbox();
                        @include justify-content(center);
                        @include align-items(center);
                    }
                    &:hover,
                    &:focus {
                        background: lighten($grayLighter, 4%);
                    }
                }
                input[type="checkbox"]:checked {
                    + p {
                        background: $grayLighter;
                        font-weight: $eFontWeightBold;
                        &:before {
                            position: absolute;
                            width: 26px;
                            height: 26px;
                            line-height: 26px;
                            @extend .material-icons;
                            content: "done";
                            margin-left: -22px;
                            color: $success;
                            padding-right: 5px;
                        }
                    }
                    + p small {
                        background: $success;
                        color: $white;
                    }
                }
            }
        }
        h3 {
            margin-bottom: 1em;
        }
        li.nav-header {
            margin-top: 2em;
            text-shadow: none;
        }
        div.divider {
            margin-top: 1.5em;
            margin-bottom: 1.5em;
        }
        .btn-small {
            width: 90%;
            margin-left: 5%;
        }
    }
}

#{$base}-utility-bar {
    background: $grayLighter;
    margin-bottom: 0;
    height: 41px;
    line-height: 41px;
    ul {
        margin: 0;
        list-style-type: none;
        float: right;
        @include box-sizing(border-box);
        height: 41px;
        padding: 12px 10px 0 0;
        li {
            float: left;
            margin-left: 15px;
            a {
                .grid-view-box,
                .list-view-line {
                    fill: $grayLight;
                }
                &:hover,
                &:focus,
                &:active,
                &.active {
                    .grid-view-box,
                    .list-view-line {
                        @include transition(all, 0.15s);
                        fill: $success;
                    }
                }
                &[rel="all"]{
                    //font-weight: bold;
                }
            }
            &.label {
                font-size: $baseFontSize - 3;
                line-height: $baseLineHeight - 5;
            }
        }
    }
    + .e-ecom-grid > ul {
    }
}

// MOBILE
#{$base}-menu-mobile {
    #{$base}-filters-mobile--wrapper {
        float: right;
        width: 60%;
        background: $grayLighter;
        @include box-sizing(border-box);
        .nav.nav-list {
            padding: 0;
            a {
                float: right;
                width: 50%;
                height: 40px;
                line-height: 40px;
                padding: 0;
                font-size: $baseFontSize - 2;
                text-transform: uppercase;
                font-weight: $eFontWeightBold;
                @include box-sizing(border-box);
                @include border-radius(0);
                border: none;
                &.reset-all-filters {
                    text-align: right;
                    i {
                        font-size: 30px;
                        margin-top: 5px;
                        margin-right: 4px;
                        color: $textColor;
                    }
                    &:hover,
                    &:focus {
                        background: $grayLighter;
                        color: $textColor;
                    }
                }
            }
        }
        .e-modal {
            @include box-sizing(border-box);
            padding: 10px;
            form {
                margin: 0;
            }
        }
        .e-panel {
            margin-bottom: 0;
        }
    }
    #{$base}-modal-buttons--wrapper {
        width: 90%;
        padding-left: 5%;
        .btn {
            float: left;
            width: 50%;
            height: 40px;
            line-height: 40px;
            border: none;
            padding: 0;
            font-weight: $eFontWeightBold;
            font-size: $baseFontSize - 2;
            text-transform: uppercase;
            border-bottom: 0.3em solid darken($brandColor, 5%);
            &:first-of-type {
                background: $grayLighter;
                @include border-radius(50px 0 0 50px);
                border-bottom: 0.3em solid darken($grayLighter, 5%);
            }
            &:last-of-type {
                @include border-radius(0 50px 50px 0);
                &:active,
                &:focus {
                    border-bottom-color: darken($success, 5%);
                }
            }
        }
    }
    #{$base}-menu-mobile--accordion {
        margin-bottom: 0;
        .accordion-group {
            margin-bottom: 0;
            border: none;
        }
        .accordion-heading a {
            position: relative;
            float: left;
            width: 40%;
            height: 40px;
            line-height: 40px;
            text-align: center;
            font-size: $baseFontSize - 2;
            text-transform: uppercase;
            background: $brandColor;
            color: $white;
            @include box-sizing(border-box);
            &:after {
                top: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: transparent;
                border-top-color: $brandColor;
                border-width: 10px;
                margin-left: -10px;
            }
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
        .accordion-toggle,
        .accordion-inner {
            padding: 0;
            border: none;
        }
        .accordion-body {
            width: 100%;
        }
    }
}

#{$base}-back-button {
    position: absolute;
    display: block;
    @include box-sizing(border-box);
    padding: 0 0.6em;
    margin-top: -118px;
    margin-left: -10px;
    height: 40px;
    min-width: 40px;
    background: $brandColor;
    line-height: 40px;
    color: $white;
    font-size: 1.5em;
    text-align: center;
    span.small-screens {
        display: none;
    }
    &:after {
        position: absolute;
        content: "";
        bottom: -10px;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 0;
        border-color: transparent lighten($brandColor, 10%) transparent transparent;
    }
    @media (min-width: 768px) and (max-width: 1239px) {
        margin-left: 10px;
        margin-top: -160px;
        font-size: 0.846em;
        @include border-radius(50px);
        height: 30px;
        line-height: 30px;
        padding: 0 1em;
        background: lighten($brandColor, 25%);
        &:after {
            display: none;
        }
        span.small-screens {
            display: inline-block;
        }
    }
    @media (max-width: 767px) {
        display: none;
    }
}
