@media (max-width: 767px) {
    .e-maps-full-page {
        width: 100vw;
        height: 70vh;

        position: absolute!important;
        top: 20vh;
        bottom: 20vh;
        left: 0;
        padding: 0!important;
    }
    .e-maps-full-page-list {
        position: fixed;
        bottom: 0;
        height: 60px;
        width: 100vw;
        left: 0;
        background: $white;
        box-shadow: 0 0 8px rgba($black, .5);
        right: 0;
        transition: all .3s ease;
        .e-maps-full-page-list-header {
            position: sticky;
            top: 0;
            border-bottom: 1px solid darken($white, 15%);
            background: $white;
            .e-maps-full-page-list-toggle-closed {
                display: inline-block;
            }
            .e-maps-full-page-list-toggle-opened {
                display: none;
            }
            a {
                font-weight: $eFontWeightBold;
                display: block;
                padding: 1em;
                text-decoration: none;
                i {
                    transition: all .3s ease;
                    float: right;
                }
            }
        }
        &.open {
            height: 50vh;
            overflow: auto;
            .e-maps-full-page-list-header {
                .e-maps-full-page-list-toggle-closed {
                    display: none;
                }
                .e-maps-full-page-list-toggle-opened {
                    display: inline-block;
                }
                a {
                    i {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}

.e-map-list {
    .e-map-list-item {
        margin-bottom: 2em;
    }
}

.e-embed-container.e-google-map .gm-style .gm-style-iw div {
    overflow: hidden !important;
}

.e-map-list .e-map-list-item {
    font-size: 0.846em;
    padding: 0 1em;
    margin-bottom: 0;
}

.e-map-list .e-map-list-item p a.plain {
    text-decoration: none;
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
}

ul.e-map-list {
    list-style-type: none;
    margin-left: 0;
    li {
        margin-bottom: 0;
    }
    li a.choice {
        display: block;
        text-align: center;
        padding: 0.75em 1em;
        background: $brandColor;
        @include border-radius(100px);
        color: $white;
        font-style: normal;
        font-weight: $eFontWeightBold;
        &:hover {
            text-decoration: none;
            background: $success;
            @include transition(all, 0.25s);
        }
    }
    .e-map-list-item {
        margin-bottom: 0.75em;
        float: left;
        @include box-sizing(border-box);
        width: 33.3333333%;
        @media (max-width: 979px) {
            width: 50%;
        }
        @media (max-width: 767px) {
            width: 100%;
        }
    }
}

.e-embed-container.e-google-map {
    @include border-radius(0);
}

.maps-map-set-workshop-button {
    width: 70%;
    font-size: $baseFontSize - 2;
    background: $brandColor;
    @include border-radius(3px);
    padding: 5px 10px 3px 10px;
    border-bottom: 0.2em solid darken($brandColor, 5%);
    color: $white;
    font-weight: $eFontWeightBold;
    height: 40px;
    line-height: 40px;
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
        color: $brandColorSecondary;
        background: $brandColor;
        border-bottom: 0.2em solid darken($brandColor, 5%);
    }
}

.webshop-map-search-dealer {
    font-size: 0.9em;
    border-bottom: 1px solid $baseBorderColor;
    padding-bottom: 10px !important;
    line-height: 1.6em;
    min-height: 560px;
    p.address {
        font-weight: $eFontWeightBold;
    }
    table tr.closed {
        background: #f4f4f3;
    }
    .maps-set-workshop-button {
        font-size: 1.5em;
        font-weight: normal;
        text-decoration: none;
        background: $grayLighter;
        padding: 0.5em 0.5em 0.35em 0.5em;
        color: $textColor;
        border-bottom: 0.15em solid darken($grayLighter, 5%);
        @include border-radius(50px);
        vertical-align: middle;
        float: right;
        margin-top: -1.5em;
        &:hover,
        &:active,
        &:focus {
            @include transition(all .25s);
            color: $white;
            background: $success;
            border-bottom-color: darken($success, 5%);
        }
    }
    &.user-workshop-selected {
        .maps-set-workshop-button.button-selected {
            color: $white;
            background: $success;
            border-bottom-color: darken($success, 5%);
        }
    }
}

.e-maps-infowindow {
    p {
        line-height: 1.4em !important;
    }
    a.plain {
        color: $black;
        text-decoration: none;
    }
    a.maps-infowindow-set-workshop-button {
        color: $black;
        text-decoration: none;
        margin-left: 1.6em;
        text-align: center;
        display: block;
        padding-top: .4em;
        border-top: 1px solid darken($grayLighter, 5%);
    }
}

.master-workshop-map-permissions {
    @include flexbox();
    margin-bottom: 1em;
    padding: 0.5em 1em;
    margin-bottom: 0;
    @include box-sizing(border-box);
    @media (max-width: 767px) {
        display: block;
        border: 1px solid $grayLight;
        margin-bottom: 10px;
    }
    .master-workshop-map-permissions-badge {
        margin-right: 2em;
        @media (max-width: 767px) {
            margin-right: 0;
            margin-bottom: 2px;
        }
        input[type="radio"] {
            position: absolute;
            @include opacity(0);
            +label {
                @include flexbox();
                @include align-items(center);
                margin-bottom: 0;
                padding: 5px 10px;
                font-size: 14px;
                font-weight: $eFontWeightBold;
                @include border-radius(3px);
                &:before {
                    content: '';
                    margin-right: 1em;
                    @include border-radius(100%);
                    background: map-get($workshop-badges, 'both');
                    width: 2em;
                    height: 2em;
                    display: inline-block;
                }
                &:hover {
                    background: $grayLighter;
                }
            }
            &:checked + label {
                position: relative;
                background: $grayLighter;
                &:after {
                    color: $white;
                    @extend .material-icons;
                    content: 'done';
                    position: absolute;
                    padding: .33em;
                    left: 10px;
                    top: 5px;
                }
            }
        }
        &.master-workshop-map-permissions-badge-webshop {
            input[type="radio"]+label:before {
                background: map-get($workshop-badges, 'webshop');
            }
        }
        &.master-workshop-map-permissions-badge-booking {
            input[type="radio"]+label:before {
                background: map-get($workshop-badges, 'booking');
            }
        }
        &.first {
            line-height: 42px;
        }
        &.last {
            flex-grow: 1;
            text-align: right;
            margin: 0;
            line-height: 42px;
            a {
                text-decoration: none;
                i {
                    @include transition(all .3s ease);
                    vertical-align: middle;
                }
                &:hover {
                    i {
                        @include animate('rotate 1s infinite linear');
                    }
                }
            }
        }
    }
}
