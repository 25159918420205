.e-cookie-message {
    position: fixed;
        width: 100%;
        @include box-sizing(border-box);
        padding: 1.5em;
        @include opacity(90);
        bottom: 0;
        z-index: 1075;
        background: $success;

        p, a {
            color: $brandColor;
        }
}