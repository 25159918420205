//@extend .material-icons;
//content: 'keyboard_arrow_right';

.material-icons {
    direction: ltr;
    display: inline-block;
    font-family: 'Material Icons';
    font-size: 1.2em;
    vertical-align: -.2em;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.material-icon-large{
    font-size: 2em;
}


small i.material-icons {
    vertical-align: -.35em;
}