.workshop-administration-media-library-modal{
    height: auto;
    max-height: 90vh;
}

.master-media-library-grid {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    .master-media-library-grid-item {
        padding: 0 8px 8px 0;
        width: 20%;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        img {
            margin-top: 8px;
            vertical-align: middle;
            opacity: .75;
            transition: all .3s linear;
        }
        .master-media-library-grid-item-caption{
            position: absolute;
            bottom: 10px;
            margin-right: 10px;
            background: rgba($white, .9);
            font-size: .75em;
            padding: .2em 1em;
        }
        .master-media-library-grid-item-isselected{
            position: absolute;
            top: 5px;
            left: 5px;
            z-index: 9;
            background: rgba($white, .9);
            box-shadow: 0 2px 3px rgba($black,.3);
        }
        &:hover{
            img{
                opacity: 1;
                box-shadow: 0 2px 3px rgba($black, .3);
            }
        }
    }
    @media screen and (max-width: 800px) {
        .master-media-library-grid-item {
            width: 33.333%;
        }
    }

    @media screen and (max-width: 600px) {
        .master-media-library-grid-item {
            width: 50%;
        }
    }
}
