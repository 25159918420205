@import "custom-helpers";
@import "header-user-info-and-search";
@import "universal-banner";
@import "dealersearch";
@import "webshop-category-overview";
@import "frontpage-specific-usp-area";
@import "type-custom";
@import "e-footer";
@import "carpeople-frontpage-fullwidth-area";
@import "custom-buttons";
@import "header-workshop-lookup";
@import "webshop-item-grid-standard";
@import "master-webshop-add-to-cart";
@import "colorscheme";
@import "service-serviceoverview-list";
@import "webshop-productlist-top-menu-and-filters";
@import "service-bookservice-receipt";
@import "service-bookservice";
@import "service-bookservice-stepguide";
@import "master-modal";
@import "workshop-lookup";
@import "e-maps";
@import "e-cookie-message";
@import "webshop-addtocart";
@import "inputs";
@import "mobility";
@import "e-content";
@import "cp-topbanner"; 
.master-workshop-administration .master-workshop-administration-tabs li.active a,
.master-workshop-administration .master-workshop-administration-tabs li:hover a,
.master-workshop-administration .master-workshop-administration-actions .btn,
.master-workshop-administration .master-workshop-administration-servicelist .master-workshop-administration-servicelist-free-service input[type=checkbox]:checked+label,
.master-workshop-administration .master-workshop-administration-submit-data .master-workshop-administration-servicelist-free-service input[type=checkbox]:checked+label {
    color: $brandColor;
}

.btn.btn-primary {
    border-color: darken($brandColor, 5%);
}

small:only-child {
    display: block;
}


// Carousel quick fix
.e-carousel-content .carousel-caption {
	width: 40%;
	padding: 3em;

	@media (max-width: 979px) {
		width: 60%;
		padding: 1.5em;
		font-size: 0.8em;
	}

	@media (max-width: 460px) {
		width: 100%;
		font-size: 1em;
		padding: 2em;
		@include box-sizing(border-box);
	}
}

.sleeknote {
    z-index: 10!important;
}

.call-me-workshop-form {
	input[type=text] {
		height:50px;
		margin-bottom:20px;
	}
	.form_field--select {
		height:50px;
	}
}
.call-me-workshop-form-confirmation {
	.dl-horizontal {
		dt {
			text-align: left;
		}
	}
}

.call-me-workshop-form {
    input[type=text] {
        height:50px;
        margin-bottom:20px;
    }
    .form_field--select {
        height:50px;
    }
}
.call-me-workshop-form-confirmation {
    .dl-horizontal {
        dt {
            text-align: left;
        }
    }
}
.blue {
    color: #092a41 !important;
}
@media (max-width: 767px) {
  .master-button--campaign, .master-button--large, .master-button--mega {
    float: left;
  }
}
.master-service-bookservice-section.section--large {
  margin-top: 20px;
}
.master-service-bookservice-toggle-append.js-booking-car-deposit-box {
	color: #092a41 !important;
}

.master-service-bookservice .master-service-bookservice-toggle-append:before {
	background: #092a41 !important;
}


.mb-0 {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.big-checkbox {
  height: 24px !important;
  width: 24px !important;
}

.fs-0 {
  font-size: 14px;
}

.accordion-section {
  box-sizing: border-box;
  padding: 0.35em 1em;
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }
}

// COOKIEBOT POPUP FIX - ALTERNATIVE FIX, UPGRADE TO NEWER BOOTSTRAP
#cookiebanner {
  @media (min-width:576px) {
    .flex-sm-column {
      flex-direction: column !important
    }
    .mt-sm-3, .my-sm-3 {
      margin-top: 2.25em!important;
    }
    .mb-sm-0{
      margin-bottom:0!important;
    }
  }

  @media (min-width:768px) {
    .flex-sm-column {
      flex-direction: column !important
    }
  }

  @media (min-width:1024px) {
    .flex-md-row {
      flex-direction: row !important;
    }
  }

  .CybotCookiebotDialogBodyLevelButtons {
    padding-left: 2%;
  }

  .flex-column-reverse {
    flex-direction: column-reverse !important;
  }

  .mt-2, .my-2 {
    margin-top: 1.5em !important;
  }

  .mt-lg-0, .mx-0 {
    margin-top: 0!important;
  }

  .ml-lg-0, .mx-0 {
    margin-left: 0!important;
  }

  .mb-1{
    margin-bottom:.25rem!important
  }

  .ml-0{
    margin-left:0!important
  }

  .btn {
    background-color: $grayLight !important;
    color: #ffffff !important;

    &:hover {
      background-color: $grayDark !important;
    }
  }

  input:checked+.slider {
    background-color: $brandColor !important;
  }

  .btn-primary {
    background-color: $brandColor !important;
    color: $brandColorSecondary !important;

    &:hover {
      background-color: $brandColorSecondary !important;
      color: $brandColor !important;
    }
  }
}

.modal {
  &.fade {
    &.in {
      visibility: visible;
    }
  }
}