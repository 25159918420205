$base: '.module-licenseplate-lookup';
$prefixWidth: 40px;

$licenseplatePrepend: #2275b3;
$licenseplateBorder: #e21212;
$licenseplateBusiness: #f5e200;

#{$base} {

    #{$base}-prepend {
        &:before {
        @include box-sizing(border-box);
        @include border-radius(5px 0 0 5px);
        border: 3px solid $licenseplateBorder;
        border-right-width: 0px;
        content: '';
        position: absolute;
        height: 70px;
        width: $prefixWidth;
        background: $licenseplatePrepend url(#{$baseImagePath}/svg-graphics/licenseplate-region-denmark.svg) center left no-repeat;
        background-size: cover;
        }
    }

    #{$base}-input[type="text"] {
        @include box-sizing(border-box);
        @include border-radius(0 5px 5px 0);
        @include box-shadow(none);
        border: 3px solid $licenseplateBorder;
        border-left-width: 0;
        height: 70px;
        line-height: 1;
        min-height: 70px !important;
        @include calc( width, 200px, "300px - #{$prefixWidth}");
        margin-left: $prefixWidth;
        text-align: center;
        font-weight: $eFontWeightBold;
        font-size: $baseFontSize + 24;
        text-transform: uppercase;

        &:focus, &:active, &:hover {
            outline: none !important;
        }

        &:focus:invalid:focus {
            @include box-shadow(none);
        }
    }


    #{$base}--business {
        input,
        span.addon {
            border-color: $black;
        }
        input {
            background: $licenseplateBusiness;
        }
    }
}
