//
// Carousel
// --------------------------------------------------


.carousel {
  position: relative;
  margin-bottom: $baseLineHeight;
  line-height: 1;
}

.carousel-inner {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.carousel-inner {

  > .item {
    display: none;
    position: relative;
    @include transition(.6s ease-in-out left);

  // Account for jankitude on images
    > img,
    > a > img {
      display: block;
      line-height: 1;
    }
  }

  > .active,
  > .next,
  > .prev { display: block; }

  > .active {
    left: 0;
  }

  > .next,
  > .prev {
    position: absolute;
    top: 0;
    width: 100%;
  }

  > .next {
    left: 100%;
  }
  > .prev {
    left: -100%;
  }
  > .next.left,
  > .prev.right {
    left: 0;
  }

  > .active.left {
    left: -100%;
  }
  > .active.right {
    left: 100%;
  }

}

// Left/right controls for nav
// ---------------------------

.carousel-control {
  position: absolute;
  top: 40%;
  left: 15px;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  font-size: 60px;
  font-weight: 100;
  line-height: 30px;
  color: $white;
  text-align: center;
  background: $grayDarker;
  border: 3px solid $white;
  @include border-radius(23px);
  @include opacity(50);

  // we can't have this transition here
  // because webkit cancels the carousel
  // animation if you trip this while
  // in the middle of another animation
  // ;_;
  // .transition(opacity .2s linear);

  // Reposition the right one
  &.right {
    left: auto;
    right: 15px;
  }

  // Hover/focus state
  &:hover,
  &:focus {
    color: $white;
    text-decoration: none;
    @include opacity(90);
  }
}

// Carousel indicator pips
// -----------------------------
.carousel-indicators {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 5;
  margin: 0;
  list-style: none;

  li {
    display: block;
    float: left;
    width: 10px;
    height: 10px;
    margin-left: 5px;
    text-indent: -999px;
    background-color: #ccc;
    background-color: rgba(255,255,255,.25);
    border-radius: 5px;
  }
  .active {
    background-color: #fff;
  }
}

// Caption for text below images
// -----------------------------

.carousel-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  background: $grayDark;
  background: rgba(0,0,0,.75);
}
.carousel-caption h4,
.carousel-caption p {
  color: $white;
  line-height: $baseLineHeight;
}
.carousel-caption h4 {
  margin: 0 0 5px;
}
.carousel-caption p {
  margin-bottom: 0;
}
