$eNavGlobalTopFontSize:$baseFontSize - 1;
$eNavGlobalTopFontWeight:$eFontWeightBold;
.e-nav-global-top {
    background: $navbarInverseBackground;
    border: 0;
    position: relative;
    z-index: 2;
    @include box-shadow(none);
    font-family: $masterSecondaryFontFamily;
}
.e-nav-global-top.affix {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: $zindexFixedNavbar;
    @include box-shadow(none);
}

.e-nav-global-top a.e-logo img {
    max-width: 100%;
    max-height: 70px;
    @media (min-width: 1320px) {
    max-width: 80%;
    }
    @media (min-width: 1640px) {
        max-width: 65%;
            /* IE 10+ START */
            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                max-width: 80%;
            }
            /* IE 10+ END */
    }
}

// IPAD
@media (min-width: 768px) and (max-width: 979px) {
    .e-nav-global-top .navbar .nav>li:first-child {
        margin-left: 0;
    }

    .e-nav-global-top.e-header .navbar .nav>li>a {
        padding-left: 0;
    }
}



// MOBILE
@media (max-width: 767px) {
    .e-nav-global-top .navbar .nav>li:first-child {
        margin-left: 0;
    }
}
