$base: '.module-workshop-lookup';
#{$base}-disclaimer {
    color: $grayDark;
    font-size: .8em;
    background: $warning;
    display: inline-block;
    padding: 4px 10px;
    @include border-radius(3px);
    i {
        color: $error;
    }
    + h1 {
        display: none;
    }
}

fieldset#{$base} {
    padding: 1.5em;
    position: relative;
    border: 2px solid $baseBorderColor;
    background: $grayLighter;
    input.input-xlarge {
        @include border-radius(0);
        @include box-shadow(none);
        border: none;
        margin-bottom: 0;
        padding-bottom: 0;
        padding-left: 1em;
        @include transition(all, 0.15s);
        width: 100%;
    }
    &:before {
        margin-left: -50px;
    }
    // SELECTED
    &--selected {
        input.input-xlarge {
            font-weight: bold;
            padding-left: 50px;
            @include transition(all, 0.15s);
        }
        &:before {
            @extend .material-icons;
            content: 'check';
            position: absolute;
            color: $success;
            border: 2px solid $success;
            @include border-radius(50%);
            padding: 2px;
            margin: 15px 10px;
        }
    }
    #{$base}-button {
        position: absolute;
        display: block;
        font-size: 16px;
        text-align: center;
        line-height: 3.5em;
        min-height: 3.5em;
        right: 1.5em;
        top: 1.5em;
        background: $success;
        color: $white;
        font-weight: $eFontWeightBold;
        i {
            font-size: 30px;
            vertical-align: -10px;
        }
        &:hover {
            @include transition(all, 0.15s);
            text-decoration: none;
            background: darken($success, 5%);
        }
        // RESPONSIVE
        @media (max-width: 767px) {
            position: relative;
            top: auto;
            right: auto;
        }
        @media (min-width: 768px) and (max-width: 979px) {
            width: 30%;
        }
        @media (min-width: 980px) {
            width: 25%;
        }
    }
    &.form-input--large {
        input:focus:invalid:focus {
            border-color: transparent;
            @include box-shadow(none);
        }
        // TYPEAHEAD
        .typeahead.nav.dropdown-menu {
            padding: 0;
            @include box-shadow(none);
            @include border-radius(0);
            border-top-width: 0;
            li.active a,
            .dropdown-item.active{
                color: $white;
                background-color: $gray;
            }
            li a:hover,
            li.active a:hover,
            .dropdown-item:hover,
            .dropdown-item.active:hover{
                background-color: $success;
                color: $white;
                @include transition(all, 0.15s);
            }
        }
    }
}

.typeahead.dropdown-menu {
    right: 24px;
    //@media (max-width: 767px) {
    //    &:after {
    //        right: 0;
    //    }
    //}
    li {
        .module-workshop-lookup-workshop-distance {
            color: $grayLight;
            margin-left: 1em;
            display: inline-block;
        }
        #{$base}-workshop-permissions {
            float: right;
            margin-right: 1em;
            &:after {
                content: '';
                position: absolute;
                width: 1em;
                height: 1em;
                @include border-radius(100%);
                margin-left: 0.75em;
            }
            &#{$base}-workshop-permissions-badge-webshop-booking:after {
                background: map-get($workshop-badges, 'both');
            }
            &#{$base}-workshop-permissions-badge-booking:after {
                background: map-get($workshop-badges, 'booking');
            }
            &#{$base}-workshop-permissions-badge-webshop:after {
                background: map-get($workshop-badges, 'webshop');
            }
            @media (max-width: 767px) {
                float: none;
                display: block;
            }
        }
        a {
            //@media (max-width: 767px) {
            //    display: inline-block;
            //}
        }
    }
}

#{$base}-close-button {
    color: $white;
    position: absolute;
    top: 10px;
    right: 10px;
    .material-icons {
        font-size: 1.5em;
        font-weight: $eFontWeightBold;
    }
    &:hover {
        color: $white;
    }
}

#{$base}-reset {
    a {
        color: $white;
        i.material-icons {
            vertical-align: -.2em;
        }
    }
}
