$base: '.master-checkout-step';
#{$base} {
    width: 12%;
    float: left;
    font-weight: bold;
    @include opacity(100);
    font-family: $masterSecondaryFontFamily;
    #{$base}-count {
        width: $baseLineHeight * 2.5;
        height: $baseLineHeight * 2.5;
        @include border-radius($baseLineHeight * 2.5);
        line-height: $baseLineHeight * 2.5;
        font-size: $baseFontSize * 1.5;
        border: none;
        border-color: rgba(0, 0, 0, 0.25);
        @include box-sizing(border-box);
        margin: 0 auto $baseLineHeight / 3;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        background-color: $baseBackgroundColor;
        color: $textColor;
        @media (max-width: 767px) {
            float: left;
            width: auto;
            height: auto;
            line-height: 24px;
            font-size: 16px;
            border: 0;
            margin: 0!important;
            text-shadow: none;
            padding: 0 8px;
        }
    }
    &#{$base}-done {
        color: $success;
        #{$base}-count {
            background-color: $success;
            color: $white;
        }
        #{$base}-title {
            color: $success;
        }
    }
    @media (max-width: 767px) {

        display: none;
        &#{$base}-active {
            display: block;
            width: 100%;
            padding: 4px 0 !important;


        }
    }
}

#{$base}-title {
    white-space: nowrap;
    margin-left: -$baseLineHeight * 2;
    margin-right: -$baseLineHeight * 2;
    margin-bottom: 0;
    color: $grayLight;
}

#{$base}-active {
    @include opacity(100);
    #{$base}-count {
        background-color: $success;
        color: $white;
    }
    &#{$base}-done {
        color: $success;
    }
}

#{$base}-active + #{$base}-divider {
    color: $brandColor;
    #{$base}-title {
        color: $gray;
    }
}

#{$base}-divider {
    width: 10%;
    font-size: $baseFontSize * 2;
    line-height: $baseLineHeight * 2;
    float: left;
    color: $baseBorderColor;
}





    @media (max-width: 767px) {
#{$base}-title, #{$base}-count {
    font-weight: $eFontWeightLight !important;

}

}


.e-checkout-progress {
    @media (max-width: 767px) {
        margin: -2em -10px 0 -10px !important;
    }
}