$base: ".master-account-forgot-password";
#{$base} {
    .master-button--large {
        background: $success;
        color: $white;
        border-bottom: 0.25em solid darken($success, 5%);
        &:hover,
        &:focus,
        &:active {
            background: darken($success, 5%);
            border-bottom: 0.25em solid darken($success, 10%);
        }
    }
    input[type="text"], input[type="password"] {
        @include box-sizing(border-box);
        @include border-radius(0);
        @include box-shadow(none);
        padding: 0 15px;
        border: 3px solid $grayLighter;
        outline: 3px solid transparent;
        @include transition(all 0.25s);
        outline-offset: -6px;
        min-height: 2.75em;
        &:active,
        &:focus {
            outline: 3px solid $success;
            border: 3px solid transparent;
            outline-offset: -3px;
            @include box-shadow(none);
        }
        &.input-xlarge {
            width: 100%;
            margin-bottom: 2em;
        }
    }
    @media (max-width: 767px) {
        h1 {
            margin-top: 0;
        }
    }
}
