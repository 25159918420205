.cp-top-banner {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  background: $brandColorSecondary;
  z-index: 9999;
  display:flex;
  align-items:center;
  .row {
    display:flex;
    align-items:center;
  }
  h1, h2, h3, h4, h5, h6, p, a {
    color:$brandColor;
    margin-top:0;
    margin-bottom:0;
  }
  .btn-primary {
    &:hover {
      background:$brandColor;
      color:$brandColorSecondary;
    }
  }
  .cp-top-banner-close {
    cursor:pointer;
  }
}

@media(max-width:767px) {
  .cp-top-banner {
    display:block;
    padding-top:30px;
    padding-left:10px;
    padding-right:10px;
    text-align:center;
    .row {
      display:block;
    }
    .cp-top-banner-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    .span1 {
      margin-bottom:0;
    }
  }
}
