/* Espresso navigation > local > bottom/footer (visible-phone)*/
.e-bottom + .e-nav-local-bottom .span12 {
    margin-top: $baseLineHeight / 1.5;
}

.e-nav-local-bottom .e-panel {
    margin: $contentBackgroundColor;
}

.e-nav-local-bottom .e-panel {
    background: $contentBackgroundColor;
    margin: 0;
}

.e-nav-local-bottom .e-panel .e-panel-cap {
    background: $navbarInverseBackground;
    margin: -1px -1px 0;
}

.e-nav-local-bottom .e-panel .e-panel-cap h4 small {
    color: $navbarInverseText;
}

// Alle niveauer
.e-nav-local-bottom .e-panel .nav {}

.e-nav-local-bottom .e-panel .nav li {}

.e-nav-local-bottom .e-panel .nav li a {
    font-size: $eNavLocalLeftFontSize;
    color: $navLeftLinkColor;
    line-height: 1.2;
    padding-top: 0.8em;
    padding-bottom: 0.8em;
    padding-right: $gridGutterWidth * 0.25;
    @include border-radius(0);
}

.e-nav-local-bottom .e-panel .nav li a:hover {
    color: $navLeftLinkColorHover;
    background-color: $navLeftBackgroundHover;
}

.e-nav-local-bottom .e-panel .nav li.inpath > a {
    font-weight: bold;
    color: $navLeftLinkColorInpath;
}

.e-nav-local-bottom .e-panel .nav li.inpath:not(.active) > a:hover {
    color: darken($navLeftLinkColorInpath, 15%);
}

.e-nav-local-bottom .e-panel .nav li.active > a {
    color: $navLeftLinkColorActive;
    background-color: $navLeftBackgroundActive;
}

.e-nav-local-bottom .e-panel .nav .divider {
    height: 1px;
    overflow: hidden;
    margin: 0.5em 0;
    background-color: darken($navLeftBorderColor, 10%);
}

// Niveau #1
.e-nav-local-bottom .e-panel > .nav {}

.e-nav-local-bottom .e-panel > .nav > li {
    border-top: 1px solid $navLeftBorderColor;
}

.e-nav-local-bottom .e-panel > .nav > li:first-child {
    border-top: 0;
}

.e-nav-local-bottom .e-panel > .nav > li:last-child {
    border-bottom: 0;
}

.e-nav-local-bottom .e-panel > .nav > li.active {
    border-top-color: transparent;
}

.e-nav-local-bottom .e-panel > .nav > li.active:first-child {
    border-top: 0;
}

.e-nav-local-bottom .e-panel > .nav > li.active:last-child {
    border-bottom: 0;
}

.e-nav-local-bottom .e-panel > .nav > li.active:not(.has-children) + li {
    border-top-color: transparent;
}

.e-nav-local-bottom .e-panel > .nav > li > a {
    padding-left: $gridGutterWidth * 0.5;
}

// Niveau #2
.e-nav-local-bottom .e-panel > .nav > li > .nav {
    margin-left: $gridGutterWidth;
    padding-bottom: 0.4em;
    margin-bottom: 1px;
}

.e-nav-local-bottom .e-panel > .nav > li > .nav > li > a {
    margin-left: -$gridGutterWidth;
    padding-left: $gridGutterWidth * 1.5;
}

// Niveau #3
.e-nav-local-bottom .e-panel > .nav > li > .nav > li > .nav {
    margin-left: $gridGutterWidth;
    padding-bottom: 0.4em;
    margin-bottom: 1px;
}

.e-nav-local-bottom .e-panel > .nav > li > .nav > li > .nav > li > a {
    margin-left: -$gridGutterWidth * 2;
    padding-left: $gridGutterWidth * 2.5;
}

// Niveau #3
.e-nav-local-bottom .e-panel > .nav > li > .nav > li > .nav > li > .nav {
    margin-left: $gridGutterWidth;
    padding-bottom: 0.4em;
    margin-bottom: 1px;
}

.e-nav-local-bottom .e-panel > .nav > li > .nav > li > .nav > li > .nav > li > a {
    font-size: 0.846em;
    margin-left: -$gridGutterWidth * 3;
    padding-left: $gridGutterWidth * 3.5;
}
