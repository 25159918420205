.btn + .btn,
.btn:visible + a[href],
.btn:visible + .btn {
    margin-left: $gridGutterWidth;
}

// Buttons
// --------------------------------------------------
// Base styles
// --------------------------------------------------
// Core
.btn,
.e-bottom .btn {
    padding: 0.55em 1.75em 0.55em 1.75em;
    border: none;
    background: $brandColorSecondary;
    color: $btn-cancel-color;
    font-family: $headingsFontFamily;
    text-shadow: none;
    box-shadow: none;
    text-decoration: none;
    font-weight: $eFontWeightBold;
}

.btn:hover,
.e-bottom .btn:hover {
    transition: none;
}

.btn {
    &:hover {
        text-decoration: none;
        &:hover,
        &:focus {
            color: $brandColor;
        }
    }
}

.btn-error {
    background: $error;
    color: $white;
    &:hover {
        background: darken($error, 10%);
    }
}

.btn-small {
		background: $btn-cancel-bg-color;
		&:hover,
		&:focus {
				color: $btn-cancel-color;
		}
}

.btn.btn-small.master-service-bookservice-reset-booking {
	border-radius: 0;

		&:focus,
		&:hover {
			color: $btn-cancel-color;
		}
}

.btn.btn-small.master-service-bookservice-reset-booking {
	border-radius: 0;

	&:hover,
		&:focus {
			color: $btn-cancel-color;
		}
}

// BUTTON MEGA/LARGE
.master-button--mega,
.master-button--large,
.master-button--campaign {
    font-size: 2em;
    font-weight: $eFontWeightBold;
    padding: .95em 1.75em .85em 1.75em;
		background: $white;
		
    @media (max-width: 767px) {
        font-size: 1.5em;
    }
    &:hover,
    &:focus,
    &:active {
        color: $brandColor;
        background: $success;
    }
}

.master-button--large {
    font-size: 1.5em;
    padding: .75em 2em .65em 2em;
    &.master-button--large--added {
        background: $success;
        color: $brandColor;
    }
}

.master-button--campaign {
    background: $brandColor;
    border-bottom-color: darken($brandColor, 5%);
    color: $white;
}

// BUTTON ECOM GRID
.master-button--grid {
    position: absolute;
    @include box-sizing(border-box);
    left: 0;
    bottom: 1em;
    margin: 0;
    text-align: left;
    width: 100%;
    padding: 1em 1em 0;
    .btn {
        @include border-radius(15px);
        @include box-sizing(border-box);
        width: 100%;
        font-size: 1em;
        border: none;
        &.master-button--grid--inbasket {
            background: $success;
            color: $brandColor;
        }
        &.master-button--grid--seemore {
            background: $grayLighter;
						color: $textColor;
						
            &:hover,
            &:active,
            &:focus {
                @include transition(all .25s);
                color: $white;
                background: $success;
            }
        }
    }
}

ul.list-view li .master-button--grid {
    @media (min-width: 768px) {
        width: 200px;
        left: auto;
        right: 0;
    }
}


// Custom 1stweb styles
.span6.hidden-phone .btn.pull-left {
	background-color: $btn-cancel-bg-color;
	color: $btn-cancel-color;
	font-size: 1.5em;
	padding: .75em 2em .65em;
	border-radius: 0;

	&:hover {
		background-color: $btn-cancel-bg-color;
		color: $btn-cancel-color;
	}
}

.master-service-bookservice-orderlines-edit-container .btn.btn-small,
.span4 .btn.btn-small {
	border-radius: 0;
	&:hover {
		color: $btn-cancel-color;
	}
}

.form-inline button.btn.btn-primary {
	background-color: $btn-cancel-bg-color;
	color: $btn-cancel-color;

	&:hover {
		background-color: $btn-cancel-bg-color;
		color: $btn-cancel-color;
	}
}

.btn.master-service-details-sidebar-submit {
	border-radius: 0;
	color: $new-text-color;
}