$base: ".master-webshop-grid";
#{$base} {
    form {
        position: absolute;
        bottom: 1em;
        margin: 0;
        text-align: left;
        width: 100%;
        padding: 1em 1em 0 1em;
        @include box-sizing(border-box);
        border: none;
        left: 0;
        @media (min-width: 768px) and (max-width: 979px) {
            bottom: .6em;
        }
    }
    ul li {
        h2 {
            height: 40px;
            overflow: hidden;
            font-size: $baseFontSize - 1;
            line-height: $baseLineHeight - 4;
            font-weight: $eFontWeightNormal;
        }
        .e-price {
            position: relative;
            margin: 0.5em 0 0.4em 0;
            font-weight: $eFontWeightBold;
            font-size: 1.5em;
            color: $textColor;
        }
        &:hover,
        &:active {
            outline-color: $grayLight;
        }
        #{$base}-before-price {
            position: absolute;
            width: 100%;
            top: -10px;
            left: 0;
            font-size: 11px;
            font-weight: $eFontWeightNormal;
            text-align: center;
            display: block;
            color: $error;
        }
    }
}

// WEBSHOP SERVICE ADD TO CART BUTTON
#{$base}-service-add-to-cart {
    button[type="submit"] {
        width: 100%;
        padding: 0.25em 0.25em 0 0.25em;
        font-size: 1em;
        border: none;
        background: $brandColor;
        color: $white;
        @include border-radius(50px);
        @include box-sizing(border-box);
        height: 40px;
        border-bottom: 0.25em solid darken($brandColor, 5%);
        &:hover {
            @include transition(all 0.25s);
            background-color: $success;
            border-bottom: 0.25em solid darken($success, 5%);
        }
    }
}

// OVERLAYS AND BADGES
#{$base} ul li {
    div a img.watermark {
        top: 0;
        margin: 0;
        max-width: 25%;
    }
    div.badge svg {
        position: absolute;
        bottom: 0;
        right: 0;
        max-height: 65px;
        max-width: 65px;
        z-index: 1;
        @include animate('zoomPulse 1s 1 ease-in-out');
        .svgColorPrimary {
            fill: $brandColor;
        }
        .svgColorSecondary {
            fill: $brandColorSecondary !important;
        }
        .svgColorTertiary {
            fill: lighten(desaturate($brandColor, 20%), 10%);
        }
    }
    span.label-campaign {
        pointer-events: none;
        svg,
        img {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 90px;
            height: 90px;
            z-index: 1;
            .svgColorPrimary {
                fill: $white;
            }
            .svgColorSecondary {
                fill: $info;
            }
        }
    }
}

// GRID BREAKPOINTS
// GENERAL
#{$base}.e-ecom-grid ul li:nth-child(5n) {
    border-right-width: 1px;
}

@media (min-width: 980px) {
    #{$base}.e-ecom-grid ul li {
        height: 350px;
        width: 25%;
    }
    #{$base}.e-ecom-grid ul li:nth-child(4n) {
        border-right-width: 0;
    }
}

@media (min-width: 768px) and (max-width: 979px) {
    #{$base}.e-ecom-grid ul li {
        height: 350px;
        width: 33.3333%;
    }
    #{$base}.e-ecom-grid ul li:nth-child(4n) {
        border-right-width: 1px;
    }
    #{$base}.e-ecom-grid ul li:nth-child(3n) {
        border-right-width: 0;
    }
}

@media (max-width: 767px) {
    #{$base}.e-ecom-grid ul li {
        height: 400px;
        width: 50%;
        >div:first-child {
            padding-top: 60%;
        }
    }
}

@media (max-width: 439px) {
    #{$base}.e-ecom-grid ul li {
        height: auto;
        width: 100%;
        padding-bottom: 5em;
        >div:first-child {
            padding-top: 50%;
        }
    }
}

// PRODUCTLIST GRID BREAKPOINTS
@media (min-width: 980px) {
    #{$base}.e-ecom-grid#{$base}-productlist ul li:nth-child(4n+1) {
        border-left-width: 1px;
    }
    #{$base}.e-ecom-grid#{$base}-productlist ul li {
        height: 330px;
    }
}

@media (min-width: 768px) and (max-width: 979px) {
    #{$base}.e-ecom-grid#{$base}-productlist ul li:nth-child(3n+1) {
        border-left-width: 1px;
    }
    #{$base}.e-ecom-grid#{$base}-productlist ul li {
        height: 320px;
    }
}

// PRODUCTLIST LIST VIEW
#{$base}.e-ecom-grid#{$base}-productlist {
    ul.list-view {
        li {
            @media (min-width: 768px) {
                border-left: 1px solid $baseBorderColor;
            }
            @media (max-width: 767px) {
                border-left: none;
            }
            width: 100%;
            height: 7em;
            border-right-width: 0;
            text-align: left;
            >div:first-child {
                position: relative;
                float: left;
                width: 5em;
                height: 5em;
                padding-top: 0;
                margin-bottom: 0;
                margin-right: 1em;
            }
            @media (min-width: 768px) {
                h2 {
                    height: auto;
                }
            }
            p {
                position: absolute;
                top: $baseLineHeight / 3;
                right: $gridGutterWidth / 1.5;
                text-align: right;
                width: 400px;
                height: 20px;
                small:last-child {
                    margin-left: 1em;
                }
                @media (max-width: 979px) {
                    width: 190px;
                    //small {
                    //    display: block;
                    //}
                }
            }
            form {
                left: auto;
                right: 0;
                width: 200px;
            }
            #{$base}-product-see-more-button {
                left: auto;
                right: 1em;
            }
            #{$base}-before-price {
                top: -5px;
                position: relative;
                text-align: left;
            }
            div.badge svg {
                width: 60%;
            }
        }
        // MOBILE
        @media (max-width: 767px) {
            li {
                height: 12em;
                p {
                    top: $baseLineHeight * 2.60;
                }
                form {
                    width: 100%;
                }
            }
        }
    }
}

.master-spinner-stock-list-check {
    position: absolute;
    right: 17px;
    width: 25px;
    height: 25px;
    top: 7px;
}
