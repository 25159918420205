.pager a {
    background-color: $white;
    border-color: $baseBorderColor;
}

.pager a:hover {
    background-color: $baseBorderColor;
}

.pager .disabled a,
.pager .disabled a:hover {
    background-color: $white;
}
