//
// Component animations
// --------------------------------------------------


.fade {
  opacity: 0;
  @include transition(opacity .15s linear);
  &.in {
    opacity: 1;
  }
}

.collapse {
  position: relative;
  height: 0;
  overflow: hidden;
  @include transition(height .35s ease);
  &.in {
    height: auto;
  }
}
