a.arrow:after,
a.arrow-left:before,
a.arrow-right:after,
a.arrow-up:before,
a.arrow-down:after {
    font-family: 'FontAwesome';
    position: relative;
    content: "\f105";
    display: inline-block;
    top: 0.05em;
    margin-left: 0.35em;
    line-height: inherit;
}
a.arrow-left:before {
    content: "\f104";
    margin-right: 0.40em;
    margin-left: 0;
    top: 0;
}
a.arrow-up:before {
    content: "\f106";
    margin-right: 0.40em;
    font-size: 1.20em;
    margin-left: 0;
}
a.arrow-down:after {
    content: "\f107";
    font-size: 1.20em;
}
.e-embed-container {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
}
.e-embed-container[data-ratio="auto"] {
    //padding-bottom: 0;
    //height: auto;
}
.e-embed-container[data-ratio="3:1"] {
    padding-bottom: 33.33%;
}
.e-embed-container[data-ratio="4:3"] {
    padding-bottom: 75%;
}
.e-embed-container[data-ratio="16:9"] {
    padding-bottom: 56.25%;
}
.e-embed-container[data-ratio="1:1"] {
    padding-bottom: 100%;
}
.e-embed-container[data-ratio="1:2"] {
    padding-bottom: 200%;
}

.e-embed-container.shadow {
    @include box-shadow(0 1px 3px rgba(0, 0, 0, 0.35));
}
.e-embed-container.e-video {
    padding-top: 30px;
}
.e-embed-container.e-google-map {
    padding-bottom: 33.33%;
    border: 1px solid $baseBorderColor;
    background: #e5e3df;
    @include border-radius($boxBorderRadius);
}
.e-embed-container.e-google-map[data-ratio="3:1"] {
    padding-bottom: 33.33%;
}
.e-embed-container.e-google-map[data-ratio="4:3"] {
    padding-bottom: 75%;
}
.e-embed-container.e-google-map[data-ratio="16:9"] {
    padding-bottom: 56.25%;
}
.e-embed-container.e-google-map .e-google-map-static {
    width: 100%;
}
.e-embed-container.e-google-map img {
    min-width: 0;
    max-width: none;
    vertical-align: inherit;
}
.e-embed-container.e-google-map {
    .gm-style, .gm-style-iw, .gm-style .gm-style-iw p, .gm-style .gm-style-iw p a, .gm-style .gm-style-iw span, .gm-style .gm-style-iw label, .gm-style .gm-style-iw div {
        font-family: $baseFontFamily;
        font-size: floor($baseFontSize * 0.846);
        font-weight: $eFontWeightNormal;
        line-height: 1.2;
        white-space: nowrap;
    }
    .gm-style .gm-style-iw p {
        color: $textColor;
        margin-bottom: floor($baseFontSize / 3);
    }
    .gm-style .gm-style-iw h1,
    .gm-style .gm-style-iw h2,
    .gm-style .gm-style-iw h3,
    .gm-style .gm-style-iw h4,
    .gm-style .gm-style-iw h1 a,
    .gm-style .gm-style-iw h2 a,
    .gm-style .gm-style-iw h3 a,
    .gm-style .gm-style-iw h4 a {
        color: $headingsColor;
        font-family: $baseFontFamily;
        font-size: floor($baseFontSize * 0.846) + 1;
        font-weight: $eFontWeightBold;
        line-height: 1.2;
        margin: 0 0 floor($baseFontSize / 4);
    }
}
.e-embed-container iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    @include border-radius($boxBorderRadius);
}
//align share ikoner med på linje med brødtekst
.addthis_toolbox {
    margin-top: 4px;
}
.addthis_default_style .at300b,
.addthis_default_style .at300bo,
.addthis_default_style .at300m {
    float: none;
    display: inline-block;
}
.addthis_toolbox span span {
    visibility: hidden;
}
//add option to manually add topmargin to any element
@media (min-width: 980px) {
    .topmargin025 {
        margin-top: 0.25em !important;
    }
    .topmargin05 {
        margin-top: 0.5em !important;
    }
    .topmargin1 {
        margin-top: 1em !important;
    }
    .topmargin2 {
        margin-top: 2em !important;
    }
    .topmargin3 {
        margin-top: 3em !important;
    }
    .topmargin4 {
        margin-top: 4em !important;
    }
    .topmargin5 {
        margin-top: 5em !important;
    }
    .topmargin6 {
        margin-top: 6em !important;
    }
}
//add option to manually add bottommargin to any element
@media (min-width: 980px) {
    .bottommargin1 {
        margin-bottom: $baseLineHeight !important;
    }
    .bottommargin2 {
        margin-bottom: $baseLineHeight * 2 !important;
    }
    .bottommargin3 {
        margin-bottom: $baseLineHeight * 3 !important;
    }
    .bottommargin4 {
        margin-bottom: $baseLineHeight * 4 !important;
    }
    .bottommargin5 {
        margin-bottom: $baseLineHeight * 5 !important;
    }
    .bottommargin6 {
        margin-bottom: $baseLineHeight * 6 !important;
    }
}
//change bacground color in follwing section
.e-section {
    padding-top: $baseLineHeight * 1.5;
}
.e-section hr {
    border-bottom: 0;
}
.e-section > .row:last-child,
.e-section > .row > .featured > .row:last-child {
    margin-bottom: 0;
}
//background-sections($sectionName, $backgroundColor, $hrColor, $textColor, $headingsColor, $linkColor)
@include background-section("transparent",
transparent);
@include background-section("gray");
@include background-section("black",
#2d2d2d,
black,
$grayLight,
$grayLighter);
// create css based loading spinner
.e-loading-spinner {
    @include e-loading-spinner;
}
.e-mega {
    @include box-sizing(border-box);
}
// add animation entry effect
.animate,
.fade {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    @extend .animate-hide;
}
.animate-hide {
    visibility: hidden;
}
.animate-show {
    visibility: visible;
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-timing-function: ease-in-out-quart; // cubic-bezier(0.77, 0.0, 0.175, 1.000);
    animation-timing-function: ease-in-out-quart; //cubic-bezier(0.77, 0.0, 0.175, 1.000);
    @extend .animate-hide;
}
