//
// Modals
// --------------------------------------------------

// Background
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindexModalBackdrop;
  background-color: $black;
  // Fade for backdrop
  &.fade { opacity: 0; }
}

.modal-backdrop,
.modal-backdrop.fade.in {
  @include opacity(80);
}

// Base modal
.modal {
  position: fixed;
  top: 10%;
  left: 50%;
  z-index: $zindexModal;
  width: 560px;
  margin-left: -280px;
  background-color: $white;
  border: 1px solid #999;
  border: 1px solid rgba(0,0,0,.3);
  *border: 1px solid #999; /* IE6-7 */
  @include border-radius(6px);
  @include box-shadow(0 3px 7px rgba(0,0,0,0.3));
  @include background-clip(padding-box);
  // Remove focus outline from opened modal
  outline: none;

  &.fade {
    @include transition(opacity .3s linear, top .3s ease-out);
    top: -25%;
  }
  &.fade.in { top: 10%; }
}
.modal-header {
  padding: 9px 15px;
  border-bottom: 1px solid #eee;
  // Close icon
  .close { margin-top: 2px; }
  // Heading
  h3 {
    margin: 0;
    line-height: 30px;
  }
}

// Body (where all modal content resides)
.modal-body {
  position: relative;
  overflow-y: auto;
  max-height: 400px;
  padding: 15px;
}
// Remove bottom margin if need be
.modal-form {
  margin-bottom: 0;
}

// Footer (for actions)
.modal-footer {
  padding: 14px 15px 15px;
  margin-bottom: 0;
  text-align: right; // right align buttons
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  @include border-radius(0 0 6px 6px);
  @include box-shadow(inset 0 1px 0 $white);
  @include clearfix(); // clear it in case folks use .pull-* classes on buttons

  // Properly space out buttons
  .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }
  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }
}
