$eChromaticGutterWidth:4px;
$eChromaticCaptionTextColor:darken($white, 12%);
$eChromaticCaptionHeadingsColor:darken($white, 7%);
$eChromaticCaptionLinkColor:$eChromaticCaptionTextColor;
$eChromaticCaptionBackgroundColor:$black;
$eChromaticGalleryControlsSize:$baseFontSize;
$eChromaticGalleryControlsColor:$eChromaticCaptionHeadingsColor;
 .e-chromatic > .e-chromatic-item {
    opacity: 0.01;
    float: left;
    > div {
        display: none;
    }
}
.chromatic-gallery {
    overflow: hidden;
    padding: $eChromaticGutterWidth 0 0 $eChromaticGutterWidth;
    margin-bottom: $baseLineHeight * -2;
    > div {
        height: 280px;
    }
}
.chromatic-gallery-full {
    padding: $eChromaticGutterWidth * 2 $eChromaticGutterWidth 0 $eChromaticGutterWidth * 2;
}
.chromatic-gallery-photo {
    cursor: pointer;
    background-color: $bodyBackground;
    margin: 0 $eChromaticGutterWidth $eChromaticGutterWidth 0;
    float: left;
}
.chromatic-zoom {
    z-index: $zindexModal;
    background-color: $bodyBackground;
}
.chromatic-zoom-close,
.chromatic-zoom-arrow-left,
.chromatic-zoom-arrow-right {
    font-size: $eChromaticGalleryControlsSize * 3;
    margin-top: - $eChromaticGalleryControlsSize * 2;
    height: $eChromaticGalleryControlsSize * 4;
    width: $eChromaticGalleryControlsSize * 4;
    line-height: $eChromaticGalleryControlsSize * 4;
    text-align: center;
    top: 50%;
    z-index: 1;
    background: none;
    cursor: pointer;
    color: $eChromaticGalleryControlsColor;
}
.chromatic-zoom-close {
    font-size: $eChromaticGalleryControlsSize * 3.5;
    position: absolute;
    top: 0;
    right: 0;
    margin:0;
}
.chromatic-zoom-arrow-left:before {
    font-family: 'FontAwesome';
    content: '\f104'; //icon-angel-left
}
.chromatic-zoom-arrow-right:after {
    font-family: 'FontAwesome';
    content: '\f105'; //icon-angel-right
}
.chromatic-zoom-photo {
    background-color: $bodyBackground;
}
.chromatic-zoom-photo-caption {
    @extend .hidden-phone;
    font-size: $baseFontSize - 2;
    position: absolute;
    right: $eChromaticGutterWidth * 5;
    bottom: $eChromaticGutterWidth * 5;
    padding: 1.75em;
    padding-top: 2em;
    padding-right: 8em;
    color: $eChromaticCaptionTextColor;
    background-color: rgba($eChromaticCaptionBackgroundColor, 0.75);
    @include border-radius($boxBorderRadius);
    &.landscape {
        left: 58%;
    }
    &.portrait {
        left: $eChromaticGutterWidth * 5;
    }
    h1,
    h2,
    h3,
    h4 {
        color: $eChromaticCaptionHeadingsColor;
    }
    h1 {
        font-size: 3em;
        margin-top: 1.5em;
        margin-bottom: 1em;
    }
    h2 {
        font-size: 1.75em;
        margin-top: 1.5em;
        margin-bottom: 0.75em;
    }
    h3 {
        font-size: 1.25em;
        margin-top: 0.846em;
        margin-bottom: 0.25em;
    }
    h4 {
        font-size: 1.154em;
        margin-top: 1em;
        margin-bottom: 0.25em;
    }
    p,
    ul,
    ol {
        line-height: 1.5;
        margin-bottom: 0.75em;
    }
    ul > li,
    ol > li {
        margin-top: 0.25em;
        margin-bottom: 0.25em;
    }
    .lead {
        font-size: 1.154em;
        color: $eChromaticCaptionTextColor;
    }
    a:not(.btn) {
        color: $eChromaticCaptionLinkColor;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .btn {
        //@extend .btn-small;
        @extend .btn-inverse;
    }
    *:first-child {
        margin-top: 0;
    }
    *:last-child {
        margin-bottom: 0;
    }
}
.chromatic-zoom-photo-caption {
    @include transition(right 0.2s ease-out, bottom 0.2s ease-out);
    .e-chromatic-caption-control {
        font-size: $eChromaticGalleryControlsSize - 2;
        line-height: $eChromaticGalleryControlsSize * 2.5;
        height: $eChromaticGalleryControlsSize * 2.5;
        width: $eChromaticGalleryControlsSize * 2.5;
        cursor: pointer;
        text-decoration: none !important;
        text-align:center;
    }
    .e-chromatic-caption-control-hide {
        font-size: $eChromaticGalleryControlsSize * 2;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        display: block;
    }
    .e-chromatic-caption-control-show {
        display: none;
    }
}
.e-chromatic-caption-hide .chromatic-zoom-photo-caption {
    @include transition(padding 0.2s ease-in, right 0.2s ease-out, bottom 0.2s ease-out);
    left: auto;
    right: $eChromaticGutterWidth * 2;
    bottom: $eChromaticGutterWidth * 2;
    padding: 0;
    >* {
        display: none;
    }
    .e-chromatic-caption-control-show {
        display: block;
    }
}
.chromatic-zoom-background {
    background-color: $bodyBackground;
}
