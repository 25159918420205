$base: ".master-universal-banner-wrapper";
#{$base} {
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    font-size: 1.25em;
    @media (max-width: 767px) {
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            left: 0;
            right: 0;
            background: rgba($brandColor, 0.5);
        }
    }
    img {
        max-width: 100%;
        width: 100%;
        position: relative;
        visibility: hidden;
    }
    #{$base}-content-placeholder {
        position: absolute;
        top: 45%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 10;
        @media (max-width: 767px) {
        	padding: 0 1.5em;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $brandColorSecondary;
            text-shadow: 2px 2px 0px rgba($brandColor, 0.7);
            font-weight: $eFontWeightBold;
        }
        p,
        p.lead {
            color: $brandColor;
            //text-shadow: 2px 2px 0px rgba($white, 0.4);
            font-weight: $eFontWeightBold;
            letter-spacing: -0.025em;
        }

        p.lead {
            font-size: 1.5em;
        }
        .btn, .btn.btn-primary {
        	font-size: 0.9em;
        	text-transform: uppercase;
        	font-weight: $eFontWeightBold;
        	background: $brandColor;
            border-bottom: .15em solid darken($brandColor, 5%);
            padding: 0.65em 1em 0.75em 1em;

            color: $white;

        	&:hover, &:focus, &:active {
        		background: $brandColorSecondary;
        		border-bottom: .15em solid darken($brandColorSecondary, 5%);
                color: $brandColor;
        	}
        }
        h1 {
            @media (min-width: 1200px) {
                font-size: 2.5em;
            }
        }
        p {
            @media (min-width: 1200px) {
                font-size: 1.25em;
                margin-bottom: 1.5em;
            }
        }
    }
}
