.e-search-form {
    .e-search-box {
        position: relative;
        height: $inputHeight - 1;
        @include border-radius($baseBorderRadius);
        background: $inputBackground;
        margin-bottom: 0.25em;
        input[type="text"] {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            margin: 0;
            border: 1px solid $inputBorder;
            background: none;
            @include border-radius($baseBorderRadius);
            @include box-sizing(border-box);
            @include box-shadow(none);
        }
        button.pull-left + input[type="text"] {
            padding-left: 11%;
        }
        button {
            margin: 1px;
            padding-top: 3px;
            padding-bottom: 4px;
            padding-left: 3%;
            padding-right: 3%;
            position: absolute;
            right: 0;
            z-index: 3;
            border: 0;
            @include border-radius(0 $baseBorderRadius $baseBorderRadius 0);
            border-left: 1px solid $inputBorder;
        }
        button.pull-left {
            right: auto;
            left: 0;
            @include border-radius($baseBorderRadius 0 0 $baseBorderRadius);
            border-right: 1px solid $inputBorder;
        }
        button.icon {
            border: 0;
            background: none;
            @include box-shadow(none);
        }
        button.icon.pull-left {
            padding-right: 0;
            z-index: 1;
        }
    }
}
.e-search-form-expandable {
    margin: 0;
    font-size: 1em;
    position: relative;
    .e-search-box {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -1.35em;
        height: 2.7em;
        width: 3em;
        input[type="text"] {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            font-size: 1.25em;
            height: 1.5em;
            min-height: 1.5em;
            line-height: 1.5em;
            max-width: 2em;
            padding: 0.25em 3.25em 0.25em 1em;
            border-radius: 2em;
            margin: 0;
            border-color: $navbarInverseBackground;
            background: $navbarInverseBackground;
            opacity: 0.01;
            cursor: pointer;
            transition: all 0.25s ease-in-out;
            + button {
                color: inherit;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
                font-size: 2em;
                height: 1.375em;
                min-height: 1.375em;
                line-height: 1em;
                padding: 0 0.75em;
                border: 0;
                background: transparent;
                @include box-shadow(none);
                transition: all 0.25s ease-in-out;
                &:active {
                    z-index: 3;
                    outline: 0;
                }
            }
            &:focus {
                max-width: 200px;
                color: $textColor;
                border-color: $navbarInverseBackground;
                background: $navbarInverseSearchBackgroundFocus;
                cursor: text;
                opacity: 1;
                + button {
                    z-index: 3;
                    color: $navbarInverseText;
                    //font-size: 1.5em;
                }
            }
        }
    }
}
