/*
// // Typography // -------------------------------------------------- //
Body text // -------------------------
*/
html {
    -webkit-font-smoothing: antialiased;
}
body {
    font-weight: $eFontWeightNormal;
}




h3,h4,h5,h6 {
    font-family: $masterSecondaryFontFamily;
}

h1,
.h1 {
    font-size: 2em;
    line-height: 1.1;
    letter-spacing: normal;
    margin-top: 1.5em;
    margin-bottom: 0.35em;
    @media (max-width: 767px) {
        font-size: 1.5em;
    }
}


h2,
.h2 {
    font-size: 1.50em;
    line-height: 1.15;
    font-weight: $eFontWeightNormal;
    letter-spacing: normal;
    margin-top: 1.285em;
    margin-bottom: 0.4285em;
}
h2:first-child,
.pidlink:first-child + h2,
.h2:first-child,
.pidlink:first-child + .h2 {
    margin-top: 0.14285em;
}
.e-img {
    + h2, +.h2 {
        margin-top: 1em;
    }
}
h3,
.h3 {
    font-size: 1.07385em;
    line-height: 1.25;
    font-weight: $eFontWeightBold;
    letter-spacing: normal;
    margin-top: 1.45em;
    margin-bottom: 0.2em;
}
h3:first-child,
.pidlink:first-child + h3,
.h3:first-child,
.pidlink:first-child + .h3 {
    margin-top: 0.235em;
}
h3,
.h3 {
    &:last-child {
        margin-bottom: 0.846em;
    }
}
.e-img {
    + h3, +.h3 {
        margin-top: 0.75em;
    }
}
h4 {
    font-size: 1em;
    line-height: 1.2;
    font-weight: $eFontWeightBold;
    letter-spacing: 0.0846em;
    text-transform: uppercase;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    color: $textColorMuted;
}
h4:first-child,
.pidlink:first-child + h4 {
    margin-top: 0.25em;
}
h4 + h1,
h4 + h2,
h4 + h3,
h4 + h4,
h4 + .h1,
h4 + .h2 {
    margin-top: 0;
}
h1 + h4,
h2 + h4,
h3 + h4,
h4 + h4,
.h1 + h4,
.h2 + h4 {
    margin-top: -1em;
}
h1 a,
h2 a,
h3 a,
h4 a {
    color: inherit;
    text-decoration: none;
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover {
    color: inherit;
}
h1 small,
h2 small,
h3 small,
h4 small {
    @extend small;
    &:first-child {
        display: block;
    }
}

small + h2{
    margin-top: .5em;
}
h5,
h6 {
    font-size: inherit;
    font-weight: $eFontWeightBold;
    margin: 0;
}
h1 [class*='icon-'],
h2 [class*='icon-'],
h3 [class*='icon-'],
h4 [class*='icon-'] {
    text-align: left;
    padding-right: 0.2857142857142857em; //inherited form font-awesome styling
}
.e-img {
    + p {
        margin-top: 1em;
    }
}
@media (min-width: 768px) {
    div[id^="dwcontent"] {
        &.span12 .row .span12 {
            h1, p {
                &[style*="center"] {
                    padding-left: $fluidGridColumnWidth * 2;
                    padding-right: $fluidGridColumnWidth * 2;
                }
            }
        }
        &.span9 .row .span9 {
            h1, h2, p {
                &[style*="center"] {
                    padding-left: $fluidGridColumnWidth * 1;
                    padding-right: $fluidGridColumnWidth * 1;
                }
            }
        }
        &.span8 .row .span8 {
            h2 {
                &[style*="center"] {
                    padding-left: $fluidGridColumnWidth * 1;
                    padding-right: $fluidGridColumnWidth * 1;
                }
            }
        }
    }
}
.lead {
    font-size: 1.5em;
    font-family: $masterSecondaryFontFamily;
    line-height: 1.5;
    font-weight: $eFontWeightLight;
    color: $textColorMuted;
    @media (max-width: 767px) {
        font-size: 1.25em;
        line-height: 1.25em;
    }
}
.muted {
    color: $textColorMuted;
}
a:hover {
    text-decoration: underline;
}
a small {
    color: inherit;
}
a i[class*="icon-"],
a:hover i[class*="icon-"] {
    text-decoration: none;
}
a i.icon-fixed-width[class*="icon-"],
a:hover i.icon-fixed-width[class*="icon-"] {
    //text-align: left;
}
/*
// Emphasis & misc
// -------------------------
*/

th {
    color: $headingsColor;
}
.table th,
strong {
    font-weight: $eFontWeightBold;
}
small {
    font-size: 0.846em;
    line-height: 1.5;
    color: $textColorMuted;
    font-weight: inherit;
}
small:only-child {
    display: inline-block;
}
/*
// Utility classes
*/

.text-branded {
    color: $brandColor;
}
.text-link {
    color: $linkColor;
}
.text-small {
    font-size: 0.846em;
    line-height: 1.5;
}
/*
// Lists
// --------------------------------------------------
// Unordered and Ordered lists
*/

ul,
ol {
    margin: 1.5em 0 1.5em 2em;
}
h1 + ul,
h1 + ol,
h2 + ul,
h2 + ol,
h3 + ul,
h3 + ol,
h4 + ul,
h4 + ol,
h5 + ul,
h5 + ol,
h6 + ul,
h6 + ol {
    margin-top: 0.5em;
}
ul > li,
ol > li {
    margin: 0 0 0.5em 0;
}
ul ul,
ul ol,
ol ol,
ol ul {
    margin-top: 0;
    margin-bottom: 0.75em;
}
ul.unstyled,
ul.unstyled > li,
ol.unstyled,
ol.unstyled > li {
    margin: 0;
}
/*
// Description Lists
*/

dl {
    margin-top: 0;
}
/*
// Horizontal layout (like forms)
*/

.dl-horizontal dt,
.dl-horizontal dd {
    min-height: 1.5em;
}
/*
// MISC
// ----

// Horizontal rules
*/

hr {
    margin-top: 0;
    margin-bottom: 1.5em;
    border-top: 1px solid $hrBorder;
    border-bottom: 0;
}
.row-fluid + hr {
    margin-top: 0.75em;
}
.span8 > hr.large {
    margin-right: -$eResponsiveBodyPadding * 1;
    margin-left: -$eResponsiveBodyPadding * 1;
    @media (min-width: 768px) {
        width: $gridColumnWidth768 * 12 + $gridGutterWidth768 * 13;
        margin-right: ($gridColumnWidth768 * 2 + $gridGutterWidth768 * 2) * -1;
        margin-left: ($gridColumnWidth768 * 2 + $gridGutterWidth768 * 2) * -1;
    }
    @media (min-width: 980px) {
        width: $gridColumnWidth * 12 + $gridGutterWidth * 11;
        margin-right: ($gridColumnWidth * 2 + $gridGutterWidth * 2) * -1;
        margin-left: ($gridColumnWidth * 2 + $gridGutterWidth * 2) * -1;
    }
}
.span8 > hr.mega {
    margin-right: -$eResponsiveBodyPadding * 1;
    margin-left: -$eResponsiveBodyPadding * 1;
    @media (min-width: 768px) {
        width: $gridColumnWidth768 * 12 + $gridGutterWidth768 * 13;
        margin-right: ($gridGutterWidth * 1 + $gridColumnWidth768 * 2 + $gridGutterWidth768 * 2) * -1;
        margin-left: ($gridGutterWidth * 1 + $gridColumnWidth768 * 2 + $gridGutterWidth768 * 2) * -1;
    }
    @media (min-width: 980px) {
        width: $gridColumnWidth * 12 + $gridGutterWidth * 13;
        margin-right: ($gridGutterWidth * 1 + $gridColumnWidth * 2 + $gridGutterWidth * 2) * -1;
        margin-left: ($gridGutterWidth * 1 + $gridColumnWidth * 2 + $gridGutterWidth * 2) * -1;
    }
    @media (min-width: 1240px) {
        width: $gridColumnWidth * 12 + $gridGutterWidth * 17;
        margin-right: ($gridGutterWidth * 3 + $gridColumnWidth * 2 + $gridGutterWidth * 2) * -1;
        margin-left: ($gridGutterWidth * 3 + $gridColumnWidth * 2 + $gridGutterWidth * 2) * -1;
    }
    @media (min-width: 1320px) {
        width: $gridColumnWidth * 12 + $gridGutterWidth * 19;
        margin-right: ($gridGutterWidth * 4 + $gridColumnWidth * 2 + $gridGutterWidth * 2) * -1;
        margin-left: ($gridGutterWidth * 4 + $gridColumnWidth * 2 + $gridGutterWidth * 2) * -1;
    }
    @media (min-width: 1640px) {
        width: $gridColumnWidth * 12 + $gridGutterWidth * 21;
        margin-right: ($gridGutterWidth * 5 + $gridColumnWidth * 2 + $gridGutterWidth * 2) * -1;
        margin-left: ($gridGutterWidth * 5 + $gridColumnWidth * 2 + $gridGutterWidth * 2) * -1;
    }
}
.span12 > hr.mega {
    margin-right: -$eResponsiveBodyPadding * 1;
    margin-left: -$eResponsiveBodyPadding * 1;
    @media (min-width: 768px) {
        width: $gridColumnWidth768 * 12 + $gridGutterWidth768 * 13;
        margin-right: -$gridGutterWidth * 1;
        margin-left: -$gridGutterWidth * 1;
    }
    @media (min-width: 980px) {
        width: $gridColumnWidth * 12 + $gridGutterWidth * 13;
        margin-right: -$gridGutterWidth * 1;
        margin-left: -$gridGutterWidth * 1;
    }
    @media (min-width: 1240px) {
        width: $gridColumnWidth * 12 + $gridGutterWidth * 17;
        margin-right: -$gridGutterWidth * 3;
        margin-left: -$gridGutterWidth * 3;
    }
    @media (min-width: 1320px) {
        width: $gridColumnWidth * 12 + $gridGutterWidth * 19;
        margin-right: -$gridGutterWidth * 4;
        margin-left: -$gridGutterWidth * 4;
    }
    @media (min-width: 1640px) {
        width: $gridColumnWidth * 12 + $gridGutterWidth * 21;
        margin-right: -$gridGutterWidth * 5;
        margin-left: -$gridGutterWidth * 5;
    }
}
/*
// Blockquotes
*/

blockquote {
    margin-top: 0;
    margin-bottom: 0.75em;
    padding-left: 0;
    padding-right: 0;
    border: 0;
}
blockquote p {
    font-weight: 200;
    font-style: italic;
    font-family: $serifFontFamily;
    font-size: 1.5em;
    line-height: 1.5;
    color: $textColorMuted;
}
blockquote small {
    display: inline-block;
    font-family: $sansFontFamily;
    margin-top: 1.5em;
    font-style: normal;
}
