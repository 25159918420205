// LOADING SPINNER
// ----------
@-webkit-keyframes e-loading-spinner {
    to {
        -webkit-transform: rotate(1turn);
    }
}


@-moz-keyframes e-loading-spinner {
    to {
        -moz-transform: rotate(1turn);
    }
}


@-ms-keyframes e-loading-spinner {
    to {
        -ms-transform: rotate(1turn);
    }
}


@-o-keyframes e-loading-spinner {
    to {
        -o-transform: rotate(1turn);
    }
}


@keyframes e-loading-spinner {
    to {
        transform: rotate(1turn);
    }
}


@mixin e-loading-spinner($width: $baseLineHeight, $height: $baseLineHeight, $bar_length: $baseFontSize / 2, $bar_thickness: 2px, $font-size: $baseFontSize, $color: $gray) {
    position: relative;
    display: inline-block;
    width: $width;
    height: $height;
    margin: 0 .5em;
    font-size: $font-size;
    text-indent: 999em;
    overflow: hidden;
    -webkit-animation: e-loading-spinner 0.8s infinite steps(8);
    -moz-animation: e-loading-spinner 0.8s infinite steps(8);
    -ms-animation: e-loading-spinner 0.8s infinite steps(8);
    -o-animation: e-loading-spinner 0.8s infinite steps(8);
    animation: e-loading-spinner 0.8s infinite steps(8);
    &:before,
    &:after,
    & > span:before,
    & > span:after {
        content: '';
        position: absolute;
        top: 0;
        left: ($width - $bar_thickness) / 2;
        /* (container width - part width)/2  */
        width: $bar_thickness;
        height: $bar_length;
        border-radius: .1em;
        background: lighten($color, 50%);
        box-shadow: 0 $height - $bar_length lighten($color, 60%);
        /* container   height - part height */
        -webkit-transform-origin: 50% $height / 2;
        -moz-transform-origin: 50% $height / 2;
        -ms-transform-origin: 50% $height / 2;
        -o-transform-origin: 50% $height / 2;
        transform-origin: 50% $height / 2;
        /* container height / 2 */
    }
    &:before {
        background: $color;
    }
    &:after {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        background: lighten($color, 20%);
    }
    & > span:before {
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg);
        background: lighten($color, 30%);
    }
    & > span:after {
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
        background: lighten($color, 40%);
    }
}

// BACKGROUND SECTIONS
// ----------
@mixin background-section($sectionName, $backgroundColor: $baseBackgroundColor, $hrColor: $baseBorderColor, $textColor: $textColor, $headingsColor: $headingsColor, $linkColor: $linkColor) {
    .e-section-#{$sectionName} {
        @if $backgroundColor != transparent {
            @include gradient-vertical($backgroundColor, darken($backgroundColor, 3%));
        }
        @else {
            background-color: transparent;
        }
    }
    .e-section-#{$sectionName} hr {
        border-top-color: $hrColor;
    }
    .e-section-#{$sectionName} p {
        color: $textColor;
    }
    .e-section-#{$sectionName} p a:not(.btn) {
        color: $linkColor;
    }
    .e-section-#{$sectionName} h1,
    .e-section-#{$sectionName} h2,
    .e-section-#{$sectionName} h3,
    .e-section-#{$sectionName} h4 {
        color: $headingsColor;
    }
}

// IMAGES left, right and full bleed
// ----------
@mixin images-bleed($columnsContent, $columnsBleed) {
    .container[class*="e-"] .row .span#{$columnsContent} img.large {
        @media (min-width: 768px) {
            max-width: none;
        }
        @media (min-width: 768px) and (max-width: 979px) {
            width: $gridColumnWidth768 * $columnsBleed + $gridGutterWidth768 * ($columnsBleed - 1);
            margin-left: -($gridColumnWidth768 * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth768 * ($columnsBleed - $columnsContent) / 2);
            margin-right: -($gridColumnWidth768 * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth768 * ($columnsBleed - $columnsContent) / 2);
        }
        @media (min-width: 980px) {
            width: $gridColumnWidth * $columnsBleed + $gridGutterWidth * ($columnsBleed - 1);
            margin-left: -($gridColumnWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * ($columnsBleed - $columnsContent) / 2);
            margin-right: -($gridColumnWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * ($columnsBleed - $columnsContent) / 2);
        }
    }
}

@mixin images-bleed-full($columnsContent, $columnsBleed) {
    .container[class*="e-"] .row  .span#{$columnsContent} .mega {
        @include border-radius(0);
        @media (min-width: 768px) {
            max-width: none;
        }
        @media (min-width: 768px) and (max-width: 979px) {
            width: $gridColumnWidth768 * $columnsBleed + $gridGutterWidth768 * ($columnsBleed + 1);
            margin-left: -($gridColumnWidth768 * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth768 * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth768 * 1);
            margin-right: -($gridColumnWidth768 * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth768 * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth768 * 1);
        }
        @media (min-width: 980px) {
            width: $gridColumnWidth * $columnsBleed + $gridGutterWidth * ($columnsBleed + 1);
            margin-left: -($gridColumnWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * 1);
            margin-right: -($gridColumnWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * 1);
        }
        @media (min-width: 1240px) {
            width: $gridColumnWidth * $columnsBleed + $gridGutterWidth * ($columnsBleed + 5);
            margin-left: -($gridColumnWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * 3);
            margin-right: -($gridColumnWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * 3);
        }
        @media (min-width: 1320px) {
            width: $gridColumnWidth * $columnsBleed + $gridGutterWidth * ($columnsBleed + 7);
            margin-left: -($gridColumnWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * 4);
            margin-right: -($gridColumnWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * 4);
        }
        @media (min-width: 1640px) {
            width: $gridColumnWidth * $columnsBleed + $gridGutterWidth * ($columnsBleed + 9);
            margin-left: -($gridColumnWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * 5);
            margin-right: -($gridColumnWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * ($columnsBleed - $columnsContent) / 2 + $gridGutterWidth * 5);
        }
    }
}
