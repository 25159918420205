//Fjern topmargin p├Ñ f├©rste overskrifts-element p├Ñ alle sider s├Ñ de bliver h├ªngende p├Ñ t├©rresnoren
.e-top > .row:first-child > [class*="span"] > .pidlink,
.e-content > .row > [class*="span"] > .row:first-child > [class*="span"] > .pidlink {
    + h1,
    + h2,
    + h3,
    + h4 {
        margin-top: 0;
    }
}

//linjer og padding i toppen af alle contentareas p├Ñ sider med mere end ├®t contentarea
.e-column {
    border-top: 1px solid $baseBorderColor;
    padding-top: floor($baseFontSize * 0.846);
}

//fjern top margin p├Ñ f├©rste overskrift i disse contentareas de to kolonner holder overkant
.e-column > .row:first-child > [class*="span"] > .pidlink + h1,
.e-column > .row:first-child > [class*="span"] > .pidlink + h2,
.e-column > .row:first-child > [class*="span"] > .pidlink + h3,
.e-column > .row:first-child > [class*="span"] > .pidlink + h4 {
    margin-top: 0;
}

/* temp til fk for at f├Ñ en special forside*/
.e-wrapper .container.e-transparent {
    background: none;
}

.e-wrapper .container.e-transparent .featured {
    margin-left: 0;
}

.e-wrapper .container.e-transparent .featured > .row > [class*="span"] {
    background: rgba($contentBackgroundColor, 0.85);
    padding: 20px;
    @include box-sizing(border-box);
    @include border-radius($boxBorderRadius);
}

.e-wrapper .container.e-transparent .featured > .row > [class*="span"] .e-img {
    margin: -12px -12px 0;
}

@media (max-width: 767px) {
    .e-wrapper .container.e-transparent {
        padding: 0;
    }
    .e-wrapper .container.e-transparent .featured {
        margin-top: 0;
        padding: 0;
        @include border-radius(0);
    }
    .e-wrapper .container.e-transparent .row {
        margin: $fluidGridGutterWidth;
    }
    .e-wrapper .container.e-transparent .featured > .row > [class*="span"] {
        background: rgba($contentBackgroundColor, 0.45);
    }
}
