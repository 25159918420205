/* Espresso navigation > utilities-top */

$eNavUtilitiesTopFontSize: 0.75em;
$eNavUtilitiesTopBackgroundColor: $baseBackgroundColor;
$eNavUtilitiesTopBorderColor: $baseBorderColor;
$eNavUtilitiesTopLinkColor: $textColorMuted;
$eNavUtilitiesTopLinkColorHover: $textColor;
.e-nav-utilities-top {
    font-size: $eNavUtilitiesTopFontSize;
    margin: 0;
    background-color: $eNavUtilitiesTopBackgroundColor;
    border-bottom: 1px solid $eNavUtilitiesTopBorderColor;
    @include border-radius(0);
}
.e-nav-utilities-top .nav {
    margin-bottom: 0;
    @include clearfix;
}
.e-nav-utilities-top .nav > li:last-child {
    margin-right: -$gridGutterWidth / 1.5;
}
.e-nav-utilities-top .nav > li > a,
.e-nav-utilities-top .nav > li.dropdown > a.dropdown-toggle,
.e-nav-utilities-top .nav > li.dropdown.open > a.dropdown-toggle {
    color: $eNavUtilitiesTopLinkColor;
    margin: 0;
    padding: 0.5em 1em;
    background: none;
    @include border-radius(0);
    &:hover {
        color: $eNavUtilitiesTopLinkColorHover;
    }
    > i {
        display: inline-block;
        width: 1.1428571428571428em;
        text-align: center;
        padding-right: 0.375em;
        vertical-align: -10%;
        font-size: 1.3333333333333333em;
    }
}
.e-nav-utilities-top .nav > li.dropdown.open a.dropdown-toggle {
    color: $eNavUtilitiesTopLinkColorHover;
}
.e-nav-utilities-top .nav > li:last-child > a > i {
    padding-right: 0;
}
.e-nav-utilities-top .nav > li > ul.dropdown-menu {
    &:before {
        content: '';
        display: inline-block;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid #ccc;
        border-bottom-color: $dropdownBorder;
        position: absolute;
        top: -7px;
        left: ceil($baseFontSize * 0.75);
    }
    &:after {
        content: '';
        display: inline-block;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid $dropdownBackground;
        position: absolute;
        top: -6px;
        left: ceil($baseFontSize * 0.75) + 1;
    }
    a {
        font-size: 1em;
        padding: .66em 1em;
        &:hover {
            //background: $dropdownLinkBackgroundHover;
        }
        i {
            display: inline-block;
            width: 1.1428571428571428em;
            text-align: center;
            padding-right: 0.375em;
            vertical-align: -10%;
            font-size: 1.3333333333333333em;
        }
    }
}
@media (max-width: 767px) {
    .e-nav-utilities-top .nav {
        font-size: 0.846em;
        margin-left: -$gridGutterWidth / 3;
        margin-right: -$gridGutterWidth / 3;
    }
    .e-nav-utilities-top .nav > li:last-child {
        margin-right: 0;
    }
    .e-nav-utilities-top .nav > li > a {
        padding-left: 0.5em;
        padding-right: 0.5em;
    }
}
