.upload-btn-wrapper {
    position: relative;
    cursor: pointer;
    .upload-btn {
        cursor: pointer;
        @include transition(all 0.25s ease);
        @include border-radius(50px);
        @include box-sizing(border-box);
        text-align: center;
        background: $brandColorSecondary;
        color: $new-text-color;
				border-radius: 0;
				
				&:hover {
					background-color: $button-hoover;
				}

        &.hide{
            display:none!important;
        }
    }

    input[type=file] {
        width: 0.1px;
        height: 0.1px;
        left: 0;
        @include opacity(0);
        overflow: hidden;
        position: absolute;
        z-index: -1;

    }
    img{
        opacity: 0;
        &.uploaded {
            margin-top:1em;
            max-height: 100%;
            @include opacity(100);
        }
    }
}

.btn.master-workshop-administration-logo-upload-remove{
    cursor: pointer;
    @include transition(all 0.25s ease);
    @include border-radius(50px);
    @include box-sizing(border-box);
    text-align: center;
    background: $brandColorSecondary;
		color: $new-text-color;
		
    &:hover{
        background: $button-hoover;
    }
}

