    .fullcalendar-container {
        .fullcalendar-sidebar {
            .fullcalendar-sidebar-list {
                ul {
                    li {
                        padding: 10px 0;
                        border-top: 1px solid $grayLight;
                        @include flexbox();
                        @include align-items(center);
                        .fullcalender-event-icon {
                            margin-right: 1em;
                            color: #3a87ad;
                        }
                        .fullcalender-event-delete {
                            @include flex-grow(1);
                            text-align: right;
                            color: $error;
                            cursor: pointer;
                        }
                        &[data-type="isOpenDay"] {
                            .fullcalender-event-icon {
                                color: $success;
                            }
                        }
                    }
                }
            }
            .fc-event {
                padding: .5em;
                margin-bottom: .2em;
                cursor: move;
                &:after {
                    @extend .material-icons;
                    content: 'drag_handle';
                    float: right;
                }
            }
        }
        .fullcalendar-wrap {
            table {
                background: transparent!important;
            }
        }
    }

    .fc-row .fc-content-skeleton td,
    .fc-row .fc-helper-skeleton td {
        border-color: inherit!important;
    }

    .fc-unthemed td.fc-today {
        background: rgba($success, .2)!important;
    }

    .fc-today {
        border: none;
        background: transparent;
        .fc-day-number {
            font-weight: $eFontWeightBold;
        }
    }

    .fc-day-grid-event {
        height: 35px;
        /* Styling for each event from Schedule */
    }

    .fc-time-grid-event.fc-v-event.fc-event {
        border-radius: 4px;
        border: none;
        padding: 5px;
        opacity: .65;
        left: 5% !important;
        right: 5% !important;
    }
    /* Bolds the name of the event and inherits the font size */

    .fc-event {
        font-size: inherit !important;
        font-weight: $eFontWeightBold !important;
        &.event-item-handlers {
            position: relative;
            @include box-shadow(0 0 5px rgba($black, .4));
        }
        &.isOpenDay {
            background: $success;
            border-color: darken($success, 5%)
        }
    }
    /* Remove the header border from Schedule */

    .fc-day-grid-event .fc-content {
        @include flexbox();
        @include flex-direction(column);
        text-align: center;
        @include justify-content(center);
        height: 100%;
    }

    span.fc-title {
        overflow: hidden;
        font-size: .8em;
        padding: .5em;
        text-overflow: ellipsis;
    }
