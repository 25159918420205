.workshop-administration-service-sort-modal {
    .master-service-bookservice-content-wrapper {
        .master-service-bookservice-servicelist {
            display: flex;
            flex-direction: column;

            li {
                position: relative;
                box-sizing: border-box;
                float: none;
                width: auto;
                padding: 0 10px;
                height: 4em;
                margin-bottom: 10px;
                background: #f1f1f1;
            }

            &.master-service-bookservice-servicelist-sortable-highlight {
                margin: 0 0 .5em 2em;
                padding: 1em 0;

                li {
                    box-shadow: 0px 0px 0px 1px #ef7d00, 3px 3px 3px rgba(0, 0, 0, 0.3);
                }

                &.error {
                    position: relative;

                    &::before {
                        content: '';
                        background: rgba($warning, .5);
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }

                    li {
                        opacity: .95;
                    }

                    &::after {
                        @extend .material-icons;
                        content: "error_outline";
                        position: absolute;
                        top: -1.2em;
                        right: 2.5em;
                        color: $warningComplimentary;
                        font-weight: $eFontWeightBold;
                    }
                }
            }

            &.master-service-bookservice-servicelist-sortable-services {
                padding-top: 1em;
                margin-top: 1em;
                flex-direction: row;
                flex-wrap: wrap;
                border-top: 1px dashed $grayLight;

                li {
                    width: 49%;
                    box-shadow: none !important;
                }
            }
        }
    }

    .sortable-list-featured-services-counter {
        font-weight: bold;
        margin-bottom: 3px;
        display: block;
        text-align: right;

        &.error {
            color: $warningComplimentary;

            animation: errorTextAttention 1s 2 forwards;
        }
    }
}


.workshop-administration-service-sort-modal .master-service-bookservice-content-wrapper ul li {
    &.master-sortable-service-list-no-drop {
        background: $error !important;
        color: #fff !important;
    }
}

