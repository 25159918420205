$base: ".master-modal";
#{$base} {
    @include box-sizing(border-box);
    @include border-radius(0);
    padding: 2em;
    overflow: auto;
    @media(max-width: 767px) {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .modal-header,
    .modal-footer,
    .modal-body {
        border: none;
        background: none;
        padding: 0;

    }
    .modal-body {
        padding: 1em 0 1.5em 0;
        &.auto-height {
            height: auto;
            max-height: none;
            min-height: none;
        }
    }
    .modal-header {
        h3 {
            font-family: $masterSecondaryFontFamily;
            font-size: 2em;
            font-weight: $eFontWeightLight;
            line-height: 1;
        }
        button.close {
            position: absolute;
            top: 0.75em;
            right: 1em;

            z-index: 99;
            &:focus,
            &:active,
            &:hover {
                outline: none;
                border: none;
                box-shadow: none;
            }
        }
    }
    .btn {
        background: $grayLighter;
        &:hover,
        &:active,
        &:focus {
            background: darken($grayLighter, 5%);
            color: $textColor;
        }
    }
    .btn.btn-primary {
        font-weight: $eFontWeightBold;
				background: $brandColorSecondary;
				color: $new-text-color;
       
        color: $grayDark;
        &:hover,
        &:active,
        &:focus {
            background: $button-hoover;
        }
    }
    .btn.btn-link {
        color: $textColor;
    }
}

// MODAL SPECIFICS
#{$base}.terms-popup {
    .modal-body {
        p {
            font-size: 0.75em;
            line-height: 1.1;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: $masterSecondaryFontFamily;
            font-size: 15px;
            margin-bottom: 5px;
            font-weight: $eFontWeightBold;
            color: $textColor;
            text-transform: none;
            letter-spacing: 0;
        }
    }
}
