/* ALERTS */

.alert {
    border: none;
    @include box-sizing(border-box);
    @include border-radius(3px);
    text-shadow: none;
    font-size: $baseFontSize - 2;
    padding: 20px 35px 20px 20px;
    ul {
        padding-bottom: 0;
        margin-bottom: 0;
        list-style-type: none;
        position: relative;
        li {
            font-weight: bold;
        }
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 15px;
            height: 15px;
            content: "\f05c";
            font-family: "FontAwesome";
            margin-left: -20px;
        }
    }
}

.alert .close {
    position: relative;
    top: -2px;
    right: -17px;
    line-height: 24px;
    text-shadow: none;
    color: $white;
}

.alert-error {
    background: $red;
    color: $white;
    h1,
    h2,
    h3,
    h4,
    p,
    a {
        color: $white;
    }
}

.alert-success {
    background: $success;
    color: $white;
    h1,
    h2,
    h3,
    h4,
    p,
    a {
        color: $white;
    }
}

.alert-clean {
    background: #f1f1f1;
    border-color: #e1e1e1;
    @include border-radius(0 0 3px 3px);
    border-top: 0;
    color: $gray;
    font-size: .9em;
    a {
        color: $gray;
        text-decoration: underline;
        font-weight: bold;
        &:hover {
            color: $black;
        }
    }
}

.alert-outline-brand{
    background:rgba($brandColor, .1);
    border: 2px solid $brandColor;
    color: $brandColor;
}
