//
// Dropdown menus
// --------------------------------------------------

// The dropdown menu (ul)
// ----------------------
.dropdown-menu {
    @include border-radius($boxBorderRadius);
    // List items
    > li {
        margin: 0;
    }
    // Links within the dropdown menu
    .dropdown-item,
    > li > a {
        font-size: 0.846em;
        font-weight: $eFontWeightNormal;
        line-height: 1;
        padding-top: 0.8em;
        padding-bottom: 0.8em;
    }
}

// Hover/Focus state
// -----------
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
    background: $dropdownLinkBackgroundHover;
}

// Active state
// ------------
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    background: $dropdownLinkBackgroundActive;
}
