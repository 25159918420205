$base: ".master-service-details-validation";
#{$base} {
    position: relative;
    margin-top: -1em;
    margin-bottom: 2em;
    padding: 1em;
    min-height: 2.3em;
    p {
        font-size: $baseFontSize + 1;
        font-weight: $eFontWeightBold;
        margin-bottom: 0;
        margin-left: 3em;
        padding-top: 0.35em;
        &:before {
            @extend .material-icons;
            position: absolute;
            top: 0.175em;
            left: 0.175em;
            font-size: 50px;
        }
    }
}

#{$base}--warning {
    background: $warning;
    p {
        color: $warningComplimentary;
    }
    p:before {
        content: "warning";
        color: $white;
    }
}

#{$base}--error {
    background: $error;
    p {
        color: $white;
    }
    p:before {
        content: "close";
    }
}
