// BODY HTML
body,
html {
    background-color: #e3e3e3;
    padding-bottom: 0;
}

// WRAPPER
.e-wrapper {
    margin-top: 2em;
}


/* BREADCRUMB */

.e-breadcrumb {
    @media (max-width: 767px) {
        padding-bottom: 10px !important;
    }
}


@import "modules/modules";