/* Espresso navigation > local > top */
$eNavLocalTopHeight: 38px;

.e-nav-local-top {
    background: $navbarBackground;
    border: 0;
    box-shadow: 0 -1px 0 0 $navbarBorder inset;
}

.e-nav-local-top.affix {
    top: 0;
    left: 0;
    right: 0;
    z-index: $zindexFixedNavbar;
    @include box-shadow(0 2px 2px rgba(0, 0, 0, 0.25));
}

.e-nav-local-top .navbar-inner {
    min-height: $eNavLocalTopHeight;
    background: none;
}

.e-nav-local-top .container > .navbar > .navbar-inner > .container > .nav > li > a {
    font-size: 14px;
    padding-top: ($eNavLocalTopHeight - $baseLineHeight) / 2;
    padding-bottom: ($eNavLocalTopHeight - $baseLineHeight) / 2;
}

.e-nav-local-top .container > .navbar > .navbar-inner > .container > .nav > li.inpath,
.e-nav-local-top .container > .navbar > .navbar-inner > .container > .nav > li.active {
    height: $eNavLocalTopHeight - 3;
    border-bottom: 3px solid $brandColor;
}

.e-nav-local-top .container > .navbar > .navbar-inner > .container > .nav > li.inpath > a,
.e-nav-local-top .container > .navbar > .navbar-inner > .container > .nav > li.active > a {
    color: $brandColor;
    font-weight: $eFontWeightBold;
    text-shadow: none;
    background: none;
    @include box-shadow(none);
}

@media (max-width: $navbarCollapseWidth) {
    .e-nav-local-top .nav > li:first-child {
        margin-left: 0;
    }
}
