$base: '.master-webshop-checkout-alternative';
#{$base} {
    div[class^="e-orderline-cell-"] {
        float: left;
        @include box-sizing(border-box);
    }
    .e-orderline-cell-productimage {
        width: 8%;
    }
    .e-orderline-cell-productdetails {
        width: 35%;
    }
    .e-orderline-cell-stockdetails {
        width: 12%;
    }
    .e-orderline-cell-unitprice {
        width: 10%;
    }
    .e-orderline-cell-quantity {
        width: 18%;
    }
    .e-orderline-cell-totalprice {
        width: 12%;
    }
    .e-orderline-cell-action {
        width: 5%;
    }
    @media (max-width: 767px) {
        .e-orderline-cell-stockdetails,
        .e-orderline-cell-unitprice,
        .e-orderline-cell-totalprice,
        .e-orderline-cell-quantity,
        .e-orderline-cell-action {
            display: none;
        }
        .e-orderline-cell-productimage {
            width: 20%;
        }
        .e-orderline-cell-productdetails {
            width: 80%;
        }
    }
    #{$base}-headers {
        font-size: $baseFontSize - 3;
        border-bottom: 1px solid $baseBorderColor;
    }
    #{$base}-webshop-orderline {
        font-size: $baseFontSize - 2;
        border-bottom: 1px solid lighten($baseBorderColor, 5%);
        padding: 5px 0;
        @media (max-width: 767px) {
            @include flexbox();
        }
        .e-orderline-cell-productimage {
            img,
            svg {
                max-height: 60px;
            }
        }
        .e-orderline-cell-productdetails {
            font-size: $baseFontSize - 1;
            padding: 0 5px 0 10px;
            .stockText {
                display: block;
                font-weight: normal;
                font-size: $baseFontSize - 4;
                line-height: $baseLineHeight - 7px;
                margin-top: 5px;
                width: 85%;
            }
        }
        .e-orderline-cell-unitprice,
        .e-orderline-cell-totalprice {
            font-weight: $eFontWeightBold;
        }
        .e-orderline-cell-stockdetails {
            font-size: $baseFontSize - 4;
            color: $textColorMuted;
        }
        .e-orderline-cell-quantity {
            input {
                float: right;
                width: 40px;
                height: 17px;
                line-height: 17px;
                @include border-radius(0);
                box-shadow: none;
                border: 2px solid $grayLighter;
                border-right-width: 0;
                @include box-sizing(content-box);
                padding: 4px 6px;
            }
            small {
                float: right;
                margin-top: 4px;
                margin-right: 8px;
            }
            button {
                float: right;
                background: #00ff00;
                border: none;
                padding: 0;
                height: 29px;
                padding: 0 10px;
                @include border-radius(0 3px 3px 0);
                background: $success;
                color: $white;
            }
        }
    }
    ul#{$base}-bomitems {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            display: block;
            margin-top: 5px;
            background: $grayLighter;
            @include border-radius(3px);
            @include box-sizing(border-box);
            font-size: 0.75em;
            font-weight: $eFontWeightBold;
            padding: 0 5px;
            &:before {
                @extend .material-icons;
                content: "add";
                color: $success;
            }
        }
    }
    // MOBILE ORDERLINE SWIPE
    .swipe-list {
        width: 100%;
        .swipe-list-item-content {
            @media (min-width: 768px) {
                min-height: 1px;
            }
        }
    }
    .text-micro {
        font-size: 0.75em;
        margin: 0 0 1em 0;
        padding: 0;
    }
}

// TOTAL ORDERLINE
.master-checkout-table-total {
    margin-bottom: $baseLineHeight * 0.5;
    tr.e-totalsline {
        background: lighten($brandColorSecondary, 40%);
        font-family: $sansFontFamily;
        font-size: $baseFontSize * 0.846;
        font-weight: $eFontWeightBold;
        color: $grayLight;
    }
    td {
        text-align: right;
        padding: .5em;
        line-height: $baseLineHeight * 1.3;
        border-top: none;
    }
    tr.e-price-total {
        background: $brandColorSecondary;
        font-weight: $eFontWeightBold;
        font-family: $headingsFontFamily;
        &.service-total {
            background: lighten($brandColor, 75%);
        }
    }
    td.total-title {
        width: 73%;
        @media (max-width: 767px) {
            width: 50%;
            text-align: left !important;
        }
    }
}

// STEP DELIVERY
#{$base} {
    #{$base}-delivery-input-name,
    #{$base}-delivery-input-email,
    #{$base}-delivery-input-phone,
    #{$base}-delivery-input-address,
    #{$base}-delivery-input-zip,
    #{$base}-delivery-input-city {
        position: relative;
        input[type="text"],
        input[type="email"] {
            position: relative;
            width: 100%;
            float: left;
            padding-left: 50px;
            &[readonly] {
                background: lighten($grayLight, 15%);
                border-color: lighten($grayLight, 15%);
            }
        }
        &:before {
            position: absolute;
            top: 0;
            left: 0;
            @extend .material-icons;
            @include box-sizing(border-box);
            width: 50px;
            height: 60px;
            font-size: 40px;
            line-height: 60px;
            text-align: center;
            color: $grayLighter;
            z-index: 1;
        }
    }
    #{$base}-delivery-input-name:before {
        content: "perm_identity";
    }
    #{$base}-delivery-input-email:before {
        content: "mail_outline";
    }
    #{$base}-delivery-input-phone:before {
        content: "phone";
    }
    #{$base}-delivery-input-address:before {
        content: "home";
    }
    #{$base}-delivery-input-zip:before {
        content: "room";
    }
    #{$base}-delivery-input-city:before {
        content: "location_city";
        top: 85px;
    }
    // SECTION
    #{$base}-section {
        background: lighten($grayLighter, 5%);
        border: 1px solid $grayLighter;
        @include box-sizing(border-box);
        margin-bottom: 20px;
        h2 {
            margin-top: 0;
            margin-bottom: 25px;
            font-weight: $eFontWeightBold;
            font-size: $baseFontSize + 4;
        }
        h5 {
            margin-bottom: 7px;
            font-size: $baseFontSize - 3;
        }
    }
    @media (min-width: 980px) {
        #{$base}-delivery-input-zip input[type="text"] {
            width: 30%;
            //border-right-width: 0;
        }
        #{$base}-delivery-input-city {
            &:before {
                left: 30%;
                //display: none;
            }
        }
        #{$base}-delivery-input-city:before {
            top: 0;
        }
        #{$base}-delivery-input-city input[type="text"] {
            width: 70%;
            border-left-width: 0;
        }
    }
    #{$base}-section dl {
        dt {
            text-align: left;
        }
        dt,
        dd {
            font-size: $baseFontSize - 2;
        }
    }
    // INPUT TEXT
    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="password"] {
        @include border-radius(0);
        @include box-shadow(none);
        @include box-sizing(border-box);
        width: 100%;
        height: 60px;
        line-height: 60px;
        padding: 0 10px;
        border: 1px solid $baseBorderColor;
        margin-bottom: 25px;
    }
    // LICENSEPLATE LOOKUP MODIFIER
    #{$base}-licenseplate {
        .module-licenseplate-lookup .module-licenseplate-lookup-input[type=text] {
            position: relative;
            height: 60px;
            line-height: 60px;
            min-height: 60px !important;
            width: calc(100% - 30px);
            margin-left: 30px;
            margin-bottom: 10px;
            border-width: 2px 2px 2px 0;
            font-size: 35px;
            @media (min-width: 768px) and (max-width: 979px) {
                font-size: 30px;
            }
        }
        .module-licenseplate-lookup .module-licenseplate-lookup-prepend:before {
            height: 60px;
            width: 30px;
            border-width: 2px 0 2px 2px;
        }
    }
    // LICENSEPLATE INFO MODIFIER
    #{$base}-carinfo {
        p {
            font-size: $baseFontSize - 2;
        }
        h5 {
            margin: 0;
        }
        .module-licenseplate-info {
            color: $textColor;
            background: none;
            padding: 0 0 0 3em;
            margin-bottom: 10px;
            &:before {
                top: 0.15em;
                background: url("/files/Templates/Designs/ftz-master/assets/img/svg-graphics/car-simple-icon.svg") top left no-repeat;
            }
            span.module-licenseplate-info-model {
                font-size: $baseFontSize - 2;
            }
            small {
                font-size: $baseFontSize - 3;
                color: $textColorMuted;
            }
        }
    }
    .section--large {
        padding: 20px;
        @media (max-width: 767px) {
            padding: 10px;
        }
    }
}

// STEP DELIVERY END
.section--delivery {
    position: relative;
    background: $white;
    border: 1px solid $baseBorderColor;
    padding: 10px 20px 20px 20px;
    @media (max-width: 767px) {
        padding: 10px;
    }
    fieldset#CustomerType {
        margin-bottom: 0;
        input[type="radio"] {
            margin-left: -9999em;
            opacity: 0;
        }
        .radio {
            position: relative;
            min-width: auto;
            padding: 1.5em 1em;
            margin-bottom: 7px;
            background: $white;
            line-height: 1.5em;
            @media (min-width: 768px) and (max-width: 979px) {
                font-size: $baseFontSize - 1;
                line-height: 1.3em;
                padding: 1em 0.75em;
            }
            font-weight: $eFontWeightBold;
            @include transition(all 0.25s);
            border: 1px solid $baseBorderColor;
            outline: 2px solid transparent;
            outline-offset: -4px;
            overflow: hidden;
            &:focus,
            &:active,
            &.active,
            &:hover {
                @include transition(all 0.25s);
                outline: 2px solid $success;
                outline-offset: 0px;
                border-color: transparent;
            }
            small {
                font-size: $baseFontSize - 3;
                font-weight: $eFontWeightNormal;
            }
        }
    }
}

// BOTTOM ACTIONBAR
.row-fluid.e-checkout-actions {
    margin-bottom: 0;
}

.e-checkout-actions {
    background: transparent;
    padding: 0;
    border: none;
    margin: 0;
}

#{$base} {
    .btn {
        background: $brandColorSecondary;
				color: $new-text-color;
				border-radius: 0;

        &:hover,
        &:active,
        &:focus {
            background: $button-hoover;
        }
        
    }
}

// STEP APPROVE AND RECEIPT
#{$base}-step-approve,
#{$base}-step-receipt {
    #{$base}-section {
        #{$base}-workshop-info {
            h2 {
                font-weight: $eFontWeightLight;
                font-size: $baseFontSize + 4;
                margin-bottom: 0;
            }
            h3 {
                font-weight: $eFontWeightBold;
                font-size: $baseFontSize - 4;
                color: $success;
                margin-top: 5px;
                margin-bottom: 0;
            }
            p {
                font-size: $baseFontSize - 4;
                line-height: $baseLineHeight - 6;
            }
        }
    }
    label#{$base}-section-terms-checkbox {
        font-size: $baseFontSize - 3 !important;
        line-height: $baseLineHeight - 6 !important;
        color: $grayLight;
        &:before,
        &:after {
            margin-top: 2px;
        }
    }
}

#{$base}-step-approve,
#{$base}-step-receipt {
    .e-orderline-cell-quantity {
        width: 23%;
        text-align: center;
    }
    .e-orderline-cell-totalprice {
        width: 12%;
    }
}


.master-service-bookservice-workshop-logo{
    max-height: 90px;
}
