.uspBar {
    background: lighten($brandColorLight, 5%);
    border-top: 1px solid lighten($gray, 10%);
    [class*="span"] {
        @media (min-width: 768px) and (max-width: 979px) {
            padding: 0 20px;
        }
    }
    .e-wrapper {
        margin: 0.1em auto;
        @media (max-width: 979px) {
            display: inline-block;
            width: 100%;
        }
    }
    img {
        float: left;
        width: 110px;
        margin-top: 8px;
    }
    // LIST ELEMENTS
    ul {
        float: right;
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            float: left;
            font-size: $baseFontSize - 4;
            line-height: $baseLineHeight + 4;
            color: $white;
            margin-left: 30px;
            margin-bottom: 0;
            &:before {
                @extend .material-icons;
                content: "done";
                margin-right: 0.5em;
                color: $success;
            }
            a {
                @include transition(all, 0.25s);
                font-family: $masterSecondaryFontFamily;
                color: lighten(desaturate($brandColorLighter, 30%), 15%);
                &:hover,
                &:active,
                &:focus {
                    text-decoration: none;
                    color: $white;
                }
            }
            // TABLET
            @media (max-width: 979px) {
                font-size: $baseFontSize - 6;
                line-height: $baseLineHeight - 6;
                margin-left: 10px;
                margin-top: 6px;
            }
        }
    }
}
