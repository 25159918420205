$base: '.master-spinner';
$baseSize: 50;
#{$base} {
    @include animate('rotate 1s infinite linear');
    width: $baseSize+px;
    height: $baseSize+px;
    &#{$base}-stock-check {
        width: $baseSize / 2+px;
        height: $baseSize / 2+px;
    }
    &#{$base}-load-license {
        display: inline-block;
        width: ($baseSize / 2) - 5+px;
        height: ( $baseSize / 2) - 5+px;
        line-height: 0;
        top: 7px;
        position: relative;
    }
}

.lazy-product-loader {
    float: left;
    width: 100%;
    display: block;
    text-align: center;
    padding: 1em 0;
    #{$base} {
        margin: 0 auto;
    }
    &:after {
        display: block;
        font-weight: $eFontWeightBold;
        color: $textColorMuted;
        margin-top: 1em;
        content: attr(data-load-text);
    }
}

.master-login-modal-screen-spinner {
    display: none;
    #{$base} {
        margin: 1em auto;
    }
}

.modules-servicebooking-loader {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1020;
    #{$base} {
        //margin: auto;
    }
    &.loading {
        @include flexbox();
        @include flex-direction(column);
        @include align-items(center);
        @include justify-content(center);
        background: rgba($white, .85);
        + div {
            @include filter(blur, 2px);
        }
    }
    .loader-text {
        font-size: .9em;
        font-weight: $eFontWeightBold;
    }
}

.page-preloader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($white, .8);
    z-index: $zindexModal;
    @include flexbox();
    @include justify-content(center);
    svg {
        width: 5em;
        @include animate('rotate 1s infinite linear');
    }
    &.loading {
        +div {
            @include filter(blur, 2px);
        }
    }
    &.hide{
        display: none;
    }
}
